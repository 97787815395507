import {
  FaBriefcase,
  FaCogs,
  FaListUl,
  FaSuitcase,
  FaTicketAlt,
  FaTools,
  FaUsers,
} from "react-icons/fa";
import { SiGoogleads, SiCivicrm } from "react-icons/si";
import { MdDashboard, MdOutlineReviews, MdOutlineSportsTennis } from "react-icons/md";
import { IoIosBusiness, IoIosNotifications } from "react-icons/io";

export const megaMenu = [
  {
    slug: "",
    link: "/",
    label: "Dashboard",
    icon: <MdDashboard />,
    parentPermissionNames: [
      "Dashboard.common",

    ],
    submenu: [
      {
        slug: "",
        label: "Dashboard",
        link: "/",
        permissionName: "Dashboard.common",
      },
      {
        slug: "",
        label: "Our Team",
        image: "",
        link: "/team",
        permissionName: "Dashboard.common",
      },
      {
        slug: "",
        label: "Upcoming Events all",
        image: "",
        link: "/upcoming-events",
        permissionName: "Dashboard.common",
      },
    ],
  },
  {
    slug: "",
    label: "Package Management",
    icon: <FaSuitcase />,
    parentPermissionNames: [
      "Package.read",
      "ThingsToPack.read",
      "ThingsToKnow.read",
      "Country.read",
      "State.read",
      "Location.read",
      "TravelMode.read",
      "Package.batches",
      "Package.assign"
    ],
    submenu: [
      {
        slug: "",
        label: "Packages",
        link: "/package",
        permissionName: "Package.read",
      },
      {
        slug: "",
        label: "Event Dates",
        image: "",
        link: "/event-dates",
        permissionName: "Package.batches",
      },
      {
        slug: "",
        label: "Assign Packages to Executive",
        image: "",
        link: "/assign-package-to-executive",
        permissionName: "Package.assign",
      },

      {
        icon: <FaCogs />,
        label: "Settings",
        image: "",
        parentPermissionNames: [
          "Package.read",
          "ThingsToPack.read",
          "ThingsToKnow.read",
          "Country.read",
          "State.read",
          "Location.read",
          "TravelMode.read",
        ],
        submenu: [
          {
            slug: "",
            label: "Country",
            link: "/country",
            permissionName: "Country.read",
          },
          {
            slug: "",
            label: "State",
            link: "/state",
            permissionName: "State.read",
          },
          {
            slug: "",
            label: "Location",
            link: "/location",
            permissionName: "Location.read",
          },
          {
            slug: "",
            label: "Category",
            image: "",
            link: "/category",
            permissionName: "Category.read",
          },

          {
            slug: "",
            label: "Travel Mode",
            image: "",
            link: "/travel-mode",
            permissionName: "TravelMode.read",
          },
          {
            slug: "",
            label: "Riders",
            image: "",
            link: "/rider",
            permissionName: "Rider.read",
          },
          {
            slug: "",
            label: "Occupancy",
            image: "",
            link: "/occupancy",
            permissionName: "Occupany.read",
          },
          {
            slug: "",
            label: "Difficulties",
            image: "",
            link: "/dificulties",
            permissionName: "Difficulty.read",
          },
          {
            slug: "",
            label: "Things to Pack",
            image: "",
            link: "/things-to-pack",
            permissionName: "ThingsToPack.read",
          },
          {
            slug: "",
            label: "Things to Know",
            image: "",
            link: "/things-to-know",
            permissionName: "ThingsToKnow.read",
          },
          {
            slug: "",
            label: "Special Dates",
            image: "",
            link: "/special-dates",
            permissionName: "Package.read",
          },
        ],
      },
    ],
  },
  {
    slug: "",
    label: "Itinerary Builder",
    icon: <FaListUl />,
    parentPermissionNames: [
      "ItineraryBuilder.read",
      "Stay.read",
      "Transfer.read",
    ],
    submenu: [
      {
        slug: "",
        label: "Itinerary",
        image: "",
        link: "/itinerary-builder",
        permissionName: "ItineraryBuilder.read",
      },
      {
        slug: "",
        label: "Stays",
        image: "",
        link: "/stays",
        permissionName: "Stay.read",
      },
      {
        slug: "",
        label: "Transfers",
        image: "",
        link: "/transfers",
        permissionName: "Transfer.read",
      },
      {
        slug: "",
        label: "Amenities",
        image: "",
        link: "/amenities",
        permissionName: "Amenities.read",
      },
      {
        slug: "",
        label: "Features",
        image: "",
        link: "/features",
        permissionName: "Features.read",
      },
      {
        slug: "",
        label: "Stay Categories",
        image: "",
        link: "/stay-categories",
        permissionName: "StayCategories.read",
      },
    ],
  },
  {
    slug: "",
    label: "Booking Management",
    icon: <FaTicketAlt />,
    parentPermissionNames: [
      "Booking.read",
      "Booking.create",
      "Package.status",
      "PaymentVerification.read",
      "PaymentVerification.create",
      "ProformaInvoice.read",
      "Booking.MonthlyReport",
      "Booking.trekcertificate",
      "PaymentVerification.remaingPayment.read"
    ],
    submenu: [
      {
        slug: "",
        label: "Bookings",
        image: "",
        link: "/bookings",
        permissionName: "Booking.read",
      },
      {
        slug: "",
        label: "Missed Bookings",
        image: "",
        link: "/bookings/missed",
        permissionName: "Booking.read",
      },
      {
        slug: "",
        label: "Trek Certificate",
        image: "",
        link: "/bookings/trekcertificate",
        permissionName: "Booking.trekcertificate",
      },
      {
        slug: "",
        label: "Due Payments",
        image: "",
        link: "/bookings/duePaymentBookings",
        permissionName: "Booking.read",
      },
      {
        slug: "",
        label: "Booking Status",
        image: "",
        link: "/bookings/status",
        permissionName: "Booking.status",
      },
      {
        slug: "",
        label: "Monthly Report",
        image: "",
        link: "/bookings/monthly-report",
        permissionName: "Booking.MonthlyReport",
      },
      {
        slug: "",
        label: "Verification Links",
        image: "",
        link: "/bookings/verification-links",
        permissionName: "PaymentVerification.read",
      },
      {
        slug: "",
        label: "Remaining Payment Verification",
        image: "",
        link: "/bookings/remaining-payment-verification",
        permissionName: "PaymentVerification.remaingPayment.read",
      },
      // {
      //   slug: "",
      //   label: "Add Verification Links",
      //   image: "",
      //   link: "/bookings/verification-links/add",
      //   permissionName: "PaymentVerification.create",
      // },
      {
        slug: "",
        label: "Proforma Invoice",
        image: "",
        link: "/bookings/proforma-invoice",
        permissionName: "ProformaInvoice.read",
      },

    ],
  },
  {
    slug: "",
    label: "Lead Management",
    icon: <SiGoogleads />,
    parentPermissionNames: [
      "Lead.read",
      "Lead.dashboard",
      "Lead.conditions",
      "Lead.settings",
      "Lead.assignemntHistory"
    ],
    submenu: [
      {
        slug: "",
        label: "Lead Dashboard",
        image: "",
        link: "/leads/dashboard",
        permissionName: "Lead.dashboard",
      },
      {
        slug: "",
        label: "Leads",
        image: "",
        link: "/leads",
        permissionName: "Lead.read",
      },
      {
        slug: "",
        label: "Lead conditions",
        image: "",
        link: "/lead-conditions",
        permissionName: "Lead.conditions",
      },
      {
        slug: "",
        label: "Lead Settings",
        image: "",
        link: "/leads/settings",
        permissionName: "Lead.settings",
      },
      {
        slug: "",
        label: "Generate Report",
        image: "",
        link: "/leads/report",
        permissionName: "Lead.report",
      },
      {
        slug: "",
        label: "Lead Assignment History",
        image: "",
        link: "/leads/assignment-history",
        permissionName: "Lead.assignemntHistory",
      },
    ],
  },
  {
    slug: "",
    label: "CRM",
    icon: <SiCivicrm />,
    parentPermissionNames: [
      "Coupon.read",
      "GiftCard.read",
      "GiftCardCategory.read",
      "CreditNotes.read",
      "PushNotification.read",
      "ListingSEO.read",
      "DataExtraction.read",
      "ActivityLog.read",
      "user.read",
      "documents.read"
    ],
    submenu: [
      {
        slug: "",
        label: "Coupons",
        image: "",
        link: "/coupons",
        permissionName: "Coupon.read",
      },
      {
        slug: "",
        label: "Gift Cards",
        image: "",
        link: "/gift-cards",
        permissionName: "GiftCard.read",
      },
      {
        slug: "",
        label: "Gift Cards Categories",
        image: "",
        link: "/gift-card-categories",
        permissionName: "GiftCardCategory.read",
      },
      {
        slug: "",
        label: "Credit Notes",
        image: "",
        link: "/credit-notes",
        permissionName: "CreditNotes.read",
      },
      {
        slug: "",
        label: "Push Notifications",
        image: "",
        link: "/push-notifications",
        permissionName: "PushNotification.read",
      },
      {
        slug: "",
        label: "Newsletter",
        image: "",
        link: "/newsletter",
        permissionName: "Newsletter.read",
      },
      {
        slug: "",
        label: "Subscribers",
        image: "",
        link: "/newsletter/subscribers",
        permissionName: "",
      },

      {
        slug: "",
        label: "Listing Seo",
        image: "",
        link: "/listing-seo",
        permissionName: "ListingSEO.read",
      },
      {
        slug: "",
        label: "Data Extraction",
        image: "",
        link: "/data-extraction",
        permissionName: "DataExtraction.read",
      },
      {
        slug: "",
        label: "Activity Log",
        image: "",
        link: "/activity-log",
        permissionName: "ActivityLog.read",
      },
      {
        slug: "",
        label: "Users",
        image: "",
        link: "/users",
        permissionName: "user.read",
      },
      {
        slug: "",
        label: "Important Documents",
        image: "",
        link: "/important-documnets",
        permissionName: "documents.read",
      },
    ],
  },
  {
    slug: "",
    label: "Career",
    icon: <FaBriefcase />,
    parentPermissionNames: [
      "Career.jobApplication.read",
      "Career.jobOpenings.read",
      "Career.jobOpenings.create",
      "Career.internshipApplication.read",
      "Career.internshipOpenings.read",

    ],
    submenu: [
      {
        slug: "",
        label: "Job Openings",
        image: "",
        link: "/jobs",
        permissionName: "Career.jobOpenings.read",
      },
      {
        slug: "",
        label: "Job Applications",
        image: "",
        link: "/jobs/applications",
        permissionName: "Career.jobApplication.read",
      },
      {
        slug: "",
        label: "Internship Openings",
        image: "",
        link: "/internships",
        permissionName: "Career.internshipOpenings.read",
      },
      {
        slug: "",
        label: "Intern Applications",
        image: "",
        link: "/internships/applications",
        permissionName: "Career.internshipApplication.read",
      },
    ],
  },

  {
    slug: "",
    label: "Review Management",
    icon: <MdOutlineReviews />,
    parentPermissionNames: ["Package.read"],
    submenu: [
      {
        slug: "",
        label: "Google Reviews",
        image: "",
        link: "/google-reviews",
        permissionName: "Package.read",
      },
    ],
  },
  {
    slug: "",
    label: "Employee",
    icon: <FaUsers />,
    parentPermissionNames: [
      "Employee.read",
      "Employee.create",
      "OfficialContact.read",
      "PageContact.read",
      "departments.read",
      "designations.read",
    ],
    submenu: [
      {
        slug: "",
        label: "View All",
        image: "",
        link: "/employees",
        permissionName: "Employee.read",
      },
      {
        slug: "",
        label: "Add New",
        image: "",
        link: "/employees/add",
        permissionName: "Employee.create",
      },
      {
        slug: "",
        label: "Official Contacts",
        image: "",
        link: "/official-contacts",
        permissionName: "OfficialContact.read",
      },
      {
        slug: "",
        label: "Page Contacts",
        image: "",
        link: "/page-contacts",
        permissionName: "PageContact.read",
      },
      {
        slug: "",
        label: "Department Management",
        image: "",
        link: "/employees/departments",
        permissionName: "departments.read",
      },
      {
        slug: "",
        label: "Designation Management",
        image: "",
        link: "/employees/designations",
        permissionName: "designations.read",
      }
    ],
  },
  {
    slug: "",
    label: "Internal Notifications",
    icon: <IoIosNotifications />,
    parentPermissionNames: [
      "InternalNotification.read",
      "InternalNotification.create",

    ],
    submenu: [
      {
        slug: "",
        label: "View All",
        image: "",
        link: "/internal-notification",
        permissionName: "InternalNotification.read",
      },
      {
        slug: "",
        label: "Add New",
        image: "",
        link: "/internal-notification/add",
        permissionName: "InternalNotification.create",
      },
    ],
  },

  {
    slug: "",
    label: "R&R",
    icon: <MdOutlineSportsTennis />,
    parentPermissionNames: [
      "RewardsAndRecognitions.read",
      "RewardsAndRecognitions.update",
      "RewardsAndRecognitions.create",
      "RewardsAndRecognitions.delete",
      "RewardsAndRecognitions.category.read",
      "RewardsAndRecognitions.category.update",
      "RewardsAndRecognitions.category.create",
      "RewardsAndRecognitions.category.delete",


    ],
    submenu: [
      {
        slug: "",
        label: "View All RnR",
        image: "",
        link: "/rnr",
        permissionName: "RewardsAndRecognitions.read",
      },
      {
        slug: "",
        label: "Add RnR",
        image: "",
        link: "/rnr/add",
        permissionName: "RewardsAndRecognitions.create",
      },
      {
        slug: "",
        label: "All Reward Categories",
        image: "",
        link: "/rnr/reward",
        permissionName: "RewardsAndRecognitions.category.read",
      },
      {
        slug: "",
        label: "Add Reward Category",
        image: "",
        link: "/rnr/reward/add",
        permissionName: "RewardsAndRecognitions.category.create",
      },
    ],
  },
  {
    slug: "",
    label: "B2B Management",
    icon: <IoIosBusiness />,
    parentPermissionNames: [
      "B2B.read",
      "B2B.create",
    ],
    submenu: [
      {
        slug: "",
        label: "View All",
        image: "",
        link: "/b2b",
        permissionName: "B2B.read",
      },
      {
        slug: "",
        label: "Add New",
        image: "",
        link: "/b2b/add",
        permissionName: "B2B.create",
      },
    ],
  },
  {
    slug: "",
    label: "Roles & Permissions",
    icon: <FaCogs />,
    parentPermissionNames: ["Role.read", "Permission.read"],
    submenu: [
      {
        slug: "",
        label: "Roles",
        image: "",
        link: "/roles",
        permissionName: "Role.read",
      },
      {
        slug: "",
        label: "Permissions",
        image: "",
        link: "/permissions",
        permissionName: "Permission.read",
      },
      {
        slug: "",
        label: "Module",
        image: "",
        link: "/module",
        permissionName: "Module.read",
      },
    ],
  },
  {
    slug: "",
    label: "Settings",
    icon: <FaCogs />,
    parentPermissionNames: ["AdminStatWidget.read", "WebsiteSettings.read"],
    submenu: [
      {
        slug: "",
        label: "Admin Stat Widget",
        image: "",
        link: "/admin-stat-widget",
        permissionName: "AdminStatWidget.read",
      },
      {
        slug: "",
        label: "Website Settings",
        image: "",
        link: "/settings",
        permissionName: "WebsiteSettings.read",
      },
      {
        slug: "",
        label: "Homepage Category Settings",
        image: "",
        link: "/homepage-category",
        permissionName: "WebsiteSettings.homepage-category",
      },

    ],
  },
  {
    slug: "",
    label: "Tools",
    icon: <FaTools />,
    parentPermissionNames: ["Tools.altTagChecker", "Tools.websiteCacheRevalidator"],
    submenu: [
      {
        slug: "",
        label: "Alt Tag Checker",
        image: "",
        link: "tools/alt-tag-checker",
        permissionName: "Tools.altTagChecker",
      },
      {
        slug: "",
        label: "Website Cache Revalidator",
        image: "",
        link: "tools/website-cache-revalidator",
        permissionName: "Tools.websiteCacheRevalidator",
      },


    ],
  },
];
