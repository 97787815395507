import { admin } from "../../ServiceConfig";

export function submitQueryForBooking(data) {
  return admin.post(`/getUserByBooking`, data);
}

export function submitQueryForLeads(data) {
  return admin.post(`/getUserByLead`, data);
}

