import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";

import FileInput from "../../../components/inputFields/FileInput";
import { getUploadsUrl } from "../../../ServiceConfig";
import InputLabel from "../../components/inputs/InputLabel";
import {
  getBookingById,
  payBalanceAmountVerification,
  uploadPaymentVerificationScreenshot,
} from "../../services/BookingServices";
import useLoader from "../../../hooks/useLoader";
import { toast } from "react-toastify";
import DateInput from "../../components/inputs/DateInput";
import moment from "moment/moment";


const AddPaymentsVerification = () => {
  const params = useParams();
  const bookingId = params.bookingId;
  const { setLoading } = useLoader();
  const navigate = useNavigate();

  const [bookingData, setBookingData] = useState();

  const paymentFormSchema = Yup.object().shape({
    total_amount_to_pay: Yup.string().required("Required"),
    total_amount_paid: Yup.string().required("Required"),
    amount: Yup.number().required("Required").max(bookingData?.remaining_amount, "Paying amount cannot exceed the remaining booking amount"),
    remaining_amount: Yup.number().required('Required').min(0, "Remaining amount cannot be negative"),
    payment_method: Yup.string().required("Required"),
    transaction_id: Yup.string().required("Required"),
    screenshot: Yup.string().required("Required"),
    comments: Yup.string().max(255, 'Comments cannot exceed 255 characters'),
    date_of_payment: Yup.date().required("Required"),
    bank_paid_to: Yup.string(),
  });

  useEffect(() => {
    getBookingById(bookingId).then((res) => {
      setBookingData(res.data.data);
    });
  }, []);

  const AddPaymentsVerification = (values) => {
    setLoading(true);
    payBalanceAmountVerification(values)
      .then((res) => {
        if (res.data.status === "success") {
          navigate(`/bookings/remaining-payment-verification`);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="p-4">
      <h2 className="border-b-2 pb-4 text-xl font-bold">
        Add balance Payment Verification for Booking #{bookingId}
      </h2>

      <div className="my-4 rounded-lg border-2 p-4">
        <h2 className="border-b pb-4 font-bold">Overall Cost Calculation</h2>

        <Formik
          enableReinitialize
          initialValues={{
            total_amount_to_pay: bookingData?.total_amount_to_pay,
            total_amount_paid: bookingData?.total_amount_paid,
            amount_deducted: bookingData?.amount_deducted,
            credit_note: bookingData?.credit_note,
            refund_amount: bookingData?.refund_amount,
            amount: "",
            remaining_amount: bookingData?.remaining_amount,
            payment_method: "",
            transaction_id: "",
            payment_type: "partial amount",
            payment_source: "admin",
            screenshot: "",
            comments: "",
            date_of_payment: new Date(),
            bank_paid_to: ""
          }}
          validationSchema={paymentFormSchema}
          onSubmit={(values) => {
            const submitData = {};
            submitData.screenshot = values.screenshot;
            submitData.amount = values.amount;
            submitData.convenience_fee = 0;
            submitData.payment_method = values.payment_method;
            submitData.payment_source = values.payment_source;
            submitData.transaction_id = values.transaction_id;
            submitData.payment_type = values.payment_type;
            submitData.comments = values.comments;
            submitData.bank_paid_to = values.bank_paid_to;
            submitData.date_of_payment = values.date_of_payment;
            submitData.booking_id = bookingId;
            AddPaymentsVerification(submitData);
          }}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              {/* //? Cost Details Form */}
              <div className="my-4 grid grid-cols-1 gap-x-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
                <div className="">
                  <InputLabel label="Total Amount" required={true} />
                  <Field
                    name="total_amount_to_pay"
                    className="field"
                    disabled
                  />
                  <ErrorMessage name={`total_amount_to_pay`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="">
                  <InputLabel label="Amount Already Paid" required={true} />
                  <Field name="total_amount_paid" className="field" disabled />
                  <ErrorMessage name={`total_amount_paid`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="">
                  <InputLabel label="Deducted Amount" />
                  <Field name="amount_deducted" className="field" disabled />
                  <ErrorMessage name={`amount_deducted`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="">
                  <InputLabel label="Credit Note Provided" />
                  <Field name="credit_note" className="field" disabled />
                  <ErrorMessage name={`credit_note`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="">
                  <InputLabel label="Refunded to Source" />
                  <Field name="refund_amount" className="field" disabled />
                  <ErrorMessage name={`refund_amount`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="">
                  <InputLabel label="Amount Paying" required={true} />
                  <Field
                    name="amount"
                    className="field"
                    onChange={(e) => {
                      setFieldValue(`amount`, e.target.value);
                      setFieldValue(
                        "remaining_amount",
                        parseInt(values.total_amount_to_pay) -
                        (parseInt(values.total_amount_paid) +
                          parseInt(e.target.value)) +
                        parseInt(
                          values.credit_note ? values.credit_note : 0
                        ) +
                        parseInt(
                          values.amount_deducted ? values.amount_deducted : 0
                        ) +
                        parseInt(
                          values.refund_amount ? values.refund_amount : 0
                        )
                      );
                    }}
                  />
                  <ErrorMessage name={`amount`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="">
                  <InputLabel label="Reamining Amount" required={true} />
                  <Field name="remaining_amount" className="field" disabled />
                  <ErrorMessage name={`remaining_amount`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>

                <div className="">
                  <InputLabel label="Payment Method" required={true} />
                  <Field as="select" name={`payment_method`} className="field">
                    <option>Select a Payment method</option>
                    <option value="neft/imps">NEFT/IMPS</option>
                    <option value="google_pay">Google Pay</option>
                    <option value="phonepe">PhonePe</option>
                    <option value="paytm">Paytm</option>
                    <option value="payu_money">PayU Money</option>
                    <option value="bhim_upi">BHIM UPI</option>
                    <option value="EMI-sankash">EMI Sankash</option>
                    <option value="razorpay">Razorpay</option>
                    <option value="Others">Others</option>
                    <option value="cash">Cash</option>
                  </Field>
                  <ErrorMessage name={`payment_method`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="">
                  <InputLabel label="Transaction Id" required={true} />
                  <Field name={`transaction_id`} className="field" />
                  <ErrorMessage name={`transaction_id`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>

                <div className="">
                  <InputLabel label="Date of Payment" required={true} />
                  <DateInput
                    className="!rounded-full border-[1px] py-[0.625rem]"
                    onDateChange={(value) => {
                      setFieldValue("date_of_payment", moment(value).format("YYYY-MM-DD"))
                    }}
                    value={values.date_of_payment}
                  />
                  <ErrorMessage name={`date_of_payment`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>

                {values?.payment_method === "neft/imps" &&
                  <div className="">
                    <InputLabel label="Bank Paid To" />
                    <Field as="select" name={`bank_paid_to`} className="field">
                      <option>Select a Bank</option>
                      <option value="HDFC">HDFC</option>
                      <option value="Indusind">Indusind</option>
                      <option value="Kotak">Kotak</option>
                      <option value="ICIC">ICIC</option>
                    </Field>
                    <ErrorMessage name={`bank_paid_to`}>
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>}


                <div className="xl:col-span-1 2xl:col-span-2">
                  <InputLabel label="Comment" />
                  <Field
                    name={`comments`}
                    className="field"
                    as="textarea"
                    rows={6}
                  />
                  <ErrorMessage name={`comments`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="col-span-1 lg:col-span-2 xl:col-span-2 2xl:col-span-2">
                  <InputLabel label="Transaction Screenshot" required={true} />
                  <FileInput
                    noOfFilesAllowed={1}
                    value={`${getUploadsUrl()}/paymentVerificationScreenshot/${values.screenshot
                      }`}
                    onChange={(file) => {
                      uploadPaymentVerificationScreenshot({ image: file }).then(
                        (res) => {
                          setFieldValue(
                            "screenshot",
                            res.data.data[0].uploaded_image
                          );
                        }
                      );
                    }}
                  />
                  <ErrorMessage name={`screenshot`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>

              <div className="mt-16 text-center">
                <button type="submit" className="btn-primary">
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddPaymentsVerification;
