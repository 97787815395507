import React, { useEffect, useState } from "react";
import { getUploadsUrl } from "../../../ServiceConfig";
import { getAllActiveDocument } from "../../services/DashboardServices";

const ImportantLinks = () => {
  const [data, setData] = useState([])
  const getAllImportantDocs = async () => {
    const res = await getAllActiveDocument()
    setData(res.data.data.filter((onedoc) => onedoc.status === 1))
  }

  useEffect(() => {
    getAllImportantDocs()
  }, [])
  // const arrayOfLinks = [
  //   {
  //     name: "Cancellation & Batch Shifting Request Process",
  //     link: "https://storage.justwravel.com/important-links/Cancellation%20%26%20Batch%20Shifting%20Guidelines.docx",
  //   },
  //   {
  //     name: "Key Responsiblity Areas OPS.",
  //     link: "https://storage.justwravel.com/important-links/KRA_OPS_JW_6.24.pdf",
  //   },
  //   {
  //     name: "Key Responsiblity Areas Sales",
  //     link: "https://storage.justwravel.com/important-links/KRA_SALES_JW_6.24.pdf",
  //   },
  //   {
  //     name: "Minutes of Meeting Guidelines",
  //     link: "https://storage.justwravel.com/important-links/Minutes%20of%20Meeting%20Guidelines.pdf",
  //   },
  //   {
  //     name: "Quality Check GuideLines",
  //     link: "https://storage.justwravel.com/important-links/Quality%20Check%20Guidelines.pdf",
  //   },
  //   // {
  //   //   name: "Sample Drug Policy",
  //   //   link: "https://storage.justwravel.com/important-links/sample%20drug%20policy.docx",
  //   // },
  //   {
  //     name: "Holiday Calendar",
  //     link: "https://storage.googleapis.com/storage.justwravel.com/important-links/Holiday%20calender%202024.pdf",
  //   },
  //   {
  //     name: "EHB & POSH Policy",
  //     link: "https://storage.googleapis.com/storage.justwravel.com/important-links/EHB%20%26%20POSH%20(1)%20(1).pdf",
  //   },
  //   {
  //     name: "Employee Referral Program",
  //     link: "https://storage.googleapis.com/storage.justwravel.com/important-links/Employee%20Referral%20Program-JW.pdf",
  //   },
  //   {
  //     name: "Drug & Alcohol Policy",
  //     link: "https://storage.googleapis.com/storage.justwravel.com/important-links/Drug%20%26%20Alcohol%20Policy_Justwravel%20(1).pdf",
  //   },
  // ];

  return (
    <div>
      {data.length > 0 && <div className="flex flex-col rounded-lg border bg-white dark:border-slate-700">
        <div className="card-header-background relative flex items-center border-b bg-gray-100 p-4 dark:border-b dark:border-slate-700 dark:bg-slate-900 dark:text-white">
          <img
            src={`${getUploadsUrl()}/dashboard-icons/documents.png`}
            className="absolute bottom-2 left-0 h-16 w-16"
          />
          <h3 className="ml-12 font-bold">Important Documents</h3>
        </div>
        <div className="max-h-[50vh] overflow-auto px-2 dark:bg-gray-600">
          <div className="flex flex-col items-center justify-center gap-3 py-4">
            {data.map((one, index) => (
              <div
                className={`flex w-full items-center justify-between rounded-md bg-white p-2 shadow-[1px_1px_4px_rgba(0,0,0,0.15)] transition-all duration-300 ease-out hover:shadow-md dark:bg-slate-800 dark:text-white`}
              >
                <div className="font-bold">
                  {index + 1}. {one.title}
                </div>
                <div className="font-medium">
                  <button
                    onClick={() => {
                      window.location.href = `${getUploadsUrl()}/important_document/${one.file_name}`;
                    }}
                    className="rounded bg-[#0183c0] py-1 px-4 text-sm font-bold text-white hover:bg-[#02618d]"
                  >
                    View
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>}
    </div>
  );
};

export default ImportantLinks;
