import React, { useEffect, useRef } from 'react'
import DataTable from '../components/common/DataTable'
import { getAdminUrl } from '../../ServiceConfig';
import ComponentHeader from '../components/common/ComponentHeader';
import H2 from '../../components/headings/H2';
import { Link, useNavigate } from 'react-router-dom';
import { FaPlusSquare } from 'react-icons/fa';
import { deleteDepartmentById } from '../services/DepartmentServices';
import { toast } from 'react-toastify';
import $ from "jquery";

const DepartmentList = () => {
    const departmentTable = useRef();
    const navigate = useNavigate()

    const deleteSelectedDepartment = (id) => {
        if (!window.confirm("Do You really want to delete this Department?")) return;
        deleteDepartmentById(id)
            .then((res) => {
                toast.success(res.data.data);
                departmentTable.current.deleteRow(id);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };


    const actionIcons = function (cell, formatterParams, onRendered) {
        return `
        <div class="flex items-center gap-3">
          <div class="department-edit-btn cursor-pointer" data-id=${cell.getRow().getData().id
            }>
              <i class="fas fa-edit"></i>
          </div>
          <div class="department-delete-btn cursor-pointer text-red-500" data-id=${cell.getRow().getData().id
            }>
            <i class="fas fa-trash-alt"></i>
          </div>
      </div>`;
    };
    const columns = [
        {
            title: "Id",
            field: "id",
            responsive: 0,
            width: "50",
            resizable: true,
            vertAlign: "middle",
        },
        {
            title: "Name",
            field: "name",
            responsive: 0,
            minWidth: "200",
            resizable: true,
            vertAlign: "middle",
        },
        {
            title: "Career Page Name",
            field: "career_page_name",
            responsive: 0,
            minWidth: "100",
            resizable: true,
            vertAlign: "middle",
        },
        {
            formatter: actionIcons,
            headerSort: false,
            width: 100,
            responsive: 0,
            hozAlign: "center",
            vertAlign: "middle",
        },
    ]

    useEffect(() => {
        $(document).on("click", ".department-edit-btn", function (e) {
            e.stopPropagation();
            e.preventDefault();
            let cell = $(this).attr("data-id");
            navigate(`/employees/departments/${cell}`);
        });

        $(document).on("click", ".department-delete-btn", function (e) {
            e.stopPropagation();
            e.preventDefault();
            let dataId = $(this).attr("data-id");
            deleteSelectedDepartment(dataId);
        });

        return () => {
            $(document).off("click", ".department-edit-btn");
            $(document).off("click", ".department-delete-btn");
        };
    }, []);


    return (
        <div className="flex h-full flex-col p-2">
            <ComponentHeader className="border-b">
                <H2>Departments</H2>
                <Link
                    to="/employees/departments/add"
                    className="btn-primary flex items-center gap-1 whitespace-nowrap"
                >
                    <FaPlusSquare /> Add New
                </Link>
            </ComponentHeader>
            <div className="h-full">
                <DataTable
                    ref={departmentTable}
                    ajaxUrl={`${getAdminUrl()}/departments`}
                    columns={columns}
                    search={true}
                    // rowClick={rowClick}
                    responsiveLayout="collapse"
                />
            </div>
        </div>
    )
}

export default DepartmentList