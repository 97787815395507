import { admin } from "../../ServiceConfig";

export function initiateBooking(data) {
  return admin.post("/booking", data);
}

export function getAllBookings() {
  return admin.get("/bookings");
}

export function getBookingById(id) {
  return admin.get(`/booking/${id}`);
}
export function checkUserIdMismatchBookingById(id) {
  return admin.get(`/booking/${id}/checkifuseridmismatch`);
}
export function matchUserByBookingId(id) {
  return admin.get(`/booking/${id}/matchUserDetailBasedOnBooking`);
}

export function updateBookingById(data) {
  return admin.put(`/booking/${data.booking.id}`, data);
}

export function getUpcomingBookingsPackages() {
  return admin.get(`/upcomingBooking/Package`);
}

export function getPackageBookingDates(packageId) {
  return admin.get(`/eventDateByPackage/${packageId}`);
}

export function payBalanceAmount(bookingId, data) {
  return admin.post(`/pay/remainingAmount/${bookingId}`, data);
}

export function updatePaymentReceipt(paymentId, data) {
  return admin.post(`/update/payment/${paymentId}`, data);
}

export function StoreVerificationData(data) {
  return admin.post(`/booking/verification/link`, data);
}
export function deleteVerificationData(id) {
  return admin.delete(`/booking/verification/link/${id}`);
}

export function getVerificationLinkById(id) {
  return admin.get(`/booking/verification/link/${id}`);
}

export function verifyVerificationLink(id) {
  return admin.get(`approve/verification/link/${id}`);
}

export function rejectVerificationLink(id) {
  return admin.get(`reject/verification/link/${id}`);
}

export function mailRemainingPaymentNotification(id) {
  return admin.get(`/due/payment/${id}`);
}

export function resendPaymentReceipt(id) {
  return admin.get(`/resend/payment/receipt/${id}`);
}
export function checkRazorPayStatusByOrderIdAndUpdate(data) {
  return admin.post(`/paymentstatus/byorderid`, data);
}
export function resendInvoiceByBookingId(id) {
  return admin.get(`/send-tax-invoice-by-booking-id/${id}`);
}
export function resendInvoiceByInvoiceId(id) {
  return admin.get(`/send-tax-invoice-by-invoice-id/${id}`);
}
export function generateInvoiceByBookingIdService(id) {
  return admin.get(`/booking-invoice/generatebybookingid/${id}`);
}
export function regenerateInvoiceByInvoiceId(id) {
  return admin.get(`/regenerate-invoice-by-invoice-id/${id}`);
}

export function sendUpdateParticipantNotification(id) {
  return admin.get(`/update/participant/${id}`);
}

export function CancelBooking(data) {
  return admin.post(`/cancel-booking`, data);
}

export function checkUserMembership(userData) {
  return admin.post(`/check-membership`, userData);
}

export function checkUserCreditNote(userData) {
  return admin.post(`/check-creditNote`, userData);
}

export function validateCoupon(couponData) {
  return admin.post(`/check-coupon`, couponData);
}

export function validateGiftCard(giftCardData) {
  return admin.post(`/check-giftCard`, giftCardData);
}

export function createCustomBooking(data) {
  return admin.post(`/customized-bookings`, data);
}

export function updateCustomBookingById(data) {
  return admin.put(`/customized-bookings/${data.booking.id}`, data);
}

export function createCustomVerificationLink(data) {
  return admin.post(`/booking/customized-verification/link`, data);
}

export function getMonthlyReportData(month, year) {
  return admin.get(`monthly-report/${month}/${year}`);
}

export function uploadCustomItineraryPDF(file){
  return admin.post(`/uploads/pdf?upload_path=custom-itinerary-pdf`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}


// ---------------Payment Screenshot Verification API's
export function payBalanceAmountVerification(data) {
  return admin.post(`/store-payment-screenshot`, data);
}

export function getPaymentVerificationById(id){
  return admin.get(`/get-payment-screenshot/${id}`)
}

export function uploadPaymentVerificationScreenshot(file){
  return admin.post(`/uploads/image?upload_path=paymentVerificationScreenshot`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function approvePaymentScreenshot(id) {
  return admin.get(`/approve/payment-screenshot/${id}`)
}

export function rejectPaymentScreenshot(id) {
  return admin.get(`/reject/payment-screenshot/${id}`)
}
