import React, { useRef } from 'react'
import DataTable from '../common/DataTable'
import { getAdminUrl } from '../../../ServiceConfig'
import ComponentHeader from '../common/ComponentHeader';
import H2 from '../../../components/headings/H2';
import { Link } from 'react-router-dom';
import moment from 'moment';

const DataExtractionLogsList = () => {
    const datalogsTable = useRef();
    const columns = [
        {
            title: "Id",
            field: "id",
            responsive: 0,
            maxWidth: "50",
            resizable: true,
            vertAlign: "middle",
            hozAlign: "center"
        },
        {
            title: "Queried By",
            field: "employee_id",
            responsive: 0,
            maxWidth: "200",
            resizable: true,
            vertAlign: "middle",
        },
        {
            title: "Query parameters",
            field: "query_parameters",
            responsive: 0,
            minWidth: "50",
            resizable: true,
            vertAlign: "middle",
            formatter: function (cell, formatterParams, onRendered) {
                const parsedValue = JSON.parse(cell.getValue());
                const formattedValues = parsedValue.map(oneVal => {
                    return `<li><strong>${oneVal.field}: </strong> ${oneVal.value}</li>`;
                }).join('');
                return `<ul>${formattedValues}</ul>`;
            },
        },
        {
            title: "Created",
            field: "created_at",
            responsive: 0,
            maxWidth: "220",
            resizable: true,
            vertAlign: "middle",
            formatter: function (cell, formatterParams, onRendered) {
                return moment(cell.getValue()).format('Do MMMM YYYY, h:mm:ss A');
            },
        },
        {
            title: "Updated",
            field: "updated_at",
            responsive: 0,
            maxWidth: "220",
            resizable: true,
            vertAlign: "middle",
            formatter: function (cell, formatterParams, onRendered) {
                return moment(cell.getValue()).format('Do MMMM YYYY, h:mm:ss A');
            },
        },
        {
            title: "Count",
            field: "result_count",
            responsive: 0,
            maxWidth: "80",
            resizable: true,
            vertAlign: "middle",
            hozAlign: "center"
        },
        {
            title: "Data Source",
            field: "data_source",
            responsive: 0,
            maxWidth: "160",
            resizable: true,
            vertAlign: "middle",
            hozAlign: "center"
        },
        {
            title: "Mail Status",
            field: "is_mail_sent",
            responsive: 0,
            maxWidth: "150",
            resizable: true,
            vertAlign: "middle",
            hozAlign: "center",
            formatter: "tickCross",
        },
    ]
    return (

        <div className="flex h-full flex-col">
            <ComponentHeader className="border-b">
                <H2>Data Extraction Logs</H2>
                <Link
                    to="/data-extraction"
                    className="btn-primary flex items-center dark:bg-green-800 gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
                >
                    Data Extraction
                </Link>
            </ComponentHeader>
            <div className="h-full"></div>
            <DataTable
                ref={datalogsTable}
                ajaxUrl={`${getAdminUrl()}/data-Extraction-Records`}
                columns={columns}
                // search={true}
                // rowClick={rowClick}
                responsiveLayout="collapse"
                initialSort={[{ column: "id", dir: "desc" }]}
                perPage={25}
            />
        </div>
    )
}

export default DataExtractionLogsList