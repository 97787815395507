import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAdminAuth from "../../hooks/useAdminAuth";
import H2 from "../../../components/headings/H2";
import DataTable from "../../components/common/DataTable";
import { getAdminUrl } from "../../../ServiceConfig";
import NotAuthorized403 from "../../components/NotAuthorized403";
import ComponentHeader from "../../components/common/ComponentHeader";
import SelectSearch from "../../components/inputs/SelectSearch";
import InputLabel from "../../components/inputs/InputLabel";
import DateRangeInput from "../../components/inputs/DateRangeInput";

const statusArray = [
  {
    label: "Pending",
    value: "0"
  },
  {
    label: "Approved",
    value: "1"
  },
  {
    label: "Rejected",
    value: "2"
  },
]
const PaymentVerificationList = () => {
  const paymentVerificationTable = useRef();
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const navigate = useNavigate();
  const { auth } = useAdminAuth();
  const rowClick = (data) => {
    navigate(`/bookings/remaining-payment-verification/${data.id}`);
  };

  useEffect(() => {
    paymentVerificationTable.current.setFilter(selectedFilter);
  }, [selectedFilter]);

  const columns = [
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Booking Id",
      field: "booking_id",
      responsive: 0,
      minWidth: "130",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Payment Type",
      field: "payment_type",
      responsive: 0,
      minWidth: "160",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Amount",
      field: "amount",
      responsive: 0,
      minWidth: "100",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Payment method",
      field: "payment_method",
      responsive: 0,
      minWidth: "160",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Comment",
      field: "comments",
      responsive: 0,
      minWidth: "200",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Created By",
      field: "created_by",
      responsive: 0,
      minWidth: "150",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Verified",
      field: "is_verified",
      responsive: 0,
      minWidth: "200",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: function (cell, formatterParams, onRendered) {
        if (cell.getRow().getData().is_verified === 0) {
          cell.formatter = "textarea";
          return `<p class="border-2 rounded-lg border-orange-600 p-2 text-orange-600">Verification Awaited</p>`;
        }
        if (cell.getRow().getData().is_verified === 2)
          return `<p class="border-2 rounded-lg border-red-600 p-2 text-red-600">Rejected</p>`;
        if (cell.getRow().getData().is_verified === 1)
          return `<p class="border-2 rounded-lg border-green-600 p-2 text-green-600">Verified</p>`;
      },
    },
  ];





  const updateFilter = (filter) => {
    let index = selectedFilter.findIndex((sf) => sf.field === filter.field);

    if (index >= 0) {
      if (filter.value && !Array.isArray(filter.value)) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else if (Array.isArray(filter.value) && filter.value[0]) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else {
        setSelectedFilter(
          selectedFilter.filter((af) => af.field !== filter.field)
        );
      }
    } else {
      setSelectedFilter([...selectedFilter, filter]);
    }
  };

  return !auth?.permissions?.includes(
    "PaymentVerification.remaingPayment.read"
  ) && !auth?.roles?.includes("Superadmin") ? (
    <NotAuthorized403 />
  ) : (
    <div className="flex flex-col">
      <ComponentHeader className="border-b">
        <div className="flex flex-wrap items-center gap-4">
          <H2>Remaining Payment Verification</H2>
        </div>
      </ComponentHeader>
      <div className="relative h-full">
        <div className="my-4 rounded-md">
          <button
            onClick={() => setFilterOpen(!filterOpen)}
            className={` inline-block whitespace-nowrap rounded-md border bg-white p-8 py-2 text-lg font-semibold tracking-wide dark:bg-slate-700 ${filterOpen
              ? "border-b-1 -mb-[1px] rounded-b-none px-8 pb-1 pt-2"
              : ""
              }`}
          >
            Filters
          </button>
          <div
            className={`flex grow flex-wrap items-center gap-4  rounded-md rounded-tl-none bg-white dark:bg-slate-700 dark:text-white ${filterOpen ? "mb-4 border p-4" : "h-0 overflow-hidden p-0"
              }`}
          >
            <div className="min-w-[250px]">
              <InputLabel label="Verification Status" />
              <SelectSearch
                value={
                  selectedFilter.find(
                    (filter) => filter.field === "is_verified"
                  )?.value
                }
                options={statusArray}
                onChange={(value) =>
                  updateFilter({
                    field: "is_verified",
                    type: "=",
                    value: value,
                  })
                }
              />
            </div>

            <div className="min-w-[250px]">
              <InputLabel label="Booking Id" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "booking_id",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>

            <div className="min-w-[250px]">
              <InputLabel label="Date of Payment" />
              <DateRangeInput
                controlBtns={true}
                horizontalPosition="left"
                onRangeChange={(startDate, endDate) =>
                  updateFilter({
                    field: "date_of_payment",
                    type: "in",
                    value: [
                      startDate
                        ? `${new Date(startDate).getFullYear()}-${new Date(startDate).getMonth() + 1
                        }-${new Date(startDate).getDate()}`
                        : "",
                      endDate
                        ? `${new Date(endDate).getFullYear()}-${new Date(endDate).getMonth() + 1
                        }-${new Date(endDate).getDate()}`
                        : "",
                    ],
                  })
                }
              />
            </div>
          </div>
        </div>

        <DataTable
          ref={paymentVerificationTable}
          ajaxUrl={`${getAdminUrl()}/payment-screenshot`}
          columns={columns}
          rowClick={rowClick}
          layout="fitColumns"
          initialSort={[
            { column: "is_verified", dir: "asc" },
            { column: "id", dir: "asc" },
          ]}
          />
      </div>
    </div>
  );
}

export default PaymentVerificationList;
