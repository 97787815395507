import React, { useEffect, useState } from 'react'
import { getLeadAssignemntHistoryById } from '../services/LeadService'
import { FaLongArrowAltDown } from "react-icons/fa";
import moment from 'moment';

const LeadAssignemntCardHistoryDetail = ({ id }) => {
  const [data, setData] = useState()
  const getDetailById = async (dataId) => {
    try {
      const res = await getLeadAssignemntHistoryById(dataId)
      setData(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (id) {
      getDetailById(id)
    }
  }, [id])

  return (
    <>
      {
        data && <div className="flex flex-col items-center">
          {data.map((assignment, index) => (
            <div key={assignment.id} className="relative mb-8">
              <div className="flex items-center">
                <div className="bg-white p-4 rounded shadow-md">
                  <h3 className="font-bold">{assignment.new_assigned_to}</h3>
                  <p>Assigned by: {assignment.assigned_by}</p>
                  <p>Previous: {assignment.previous_assigned_to}</p>
                  <p className="text-gray-500">{moment(assignment.created_at).format('Do MMMM YYYY, h:mm:ss A')}</p>
                </div>
              </div>
              {index !== data.length - 1 && <FaLongArrowAltDown className='mt-6 mx-auto' size={30} />}
            </div>
          ))}
        </div>
      }
    </>
  )
}

export default LeadAssignemntCardHistoryDetail