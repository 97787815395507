import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import InputLabel from "../../admin/components/inputs/InputLabel";

function PhoneNumberInput({ phone, onChange, onBlur, label, onFocus }) {
  return (
    <div>
      <InputLabel label={label ? label : "Phone"} required={true} />
      <PhoneInput
        countryCodeEditable={true}
        enableSearch={true}
        inputClass="!h-10 text-black !w-full !border-gray-200"
        country={"in"}
        value={phone}
        onChange={onChange}
        onBlur={onBlur || ""}
        onFocus={onFocus || ""}
      />
    </div>
  );
}

export default PhoneNumberInput;
