import { useEffect, useRef, useState } from "react";
import DateRangeInput from "../../components/inputs/DateRangeInput";
import moment from "moment";
import { getSalesLeaderboardByDate } from "../../services/DashboardServices";
import { getUploadsUrl } from "../../../ServiceConfig";
import { Link } from "react-router-dom";
import useAdminAuth from "../../hooks/useAdminAuth";

function EmployeeSalesLeaderboard() {
  const dateInputRef = useRef();
  const { auth, setAuth } = useAdminAuth();

  const todaydate = new Date();
  const [dateRange, setDateRange] = useState({
    start_date: moment(
      new Date(todaydate.getFullYear(), todaydate.getMonth(), 1)
    )
      .utcOffset("+05:30")
      .format(),
    end_date: moment(
      new Date(todaydate.getFullYear(), todaydate.getMonth() + 1, 0)
    )
      .utcOffset("+05:30")
      .format(),
  });

  const [sales, setSales] = useState();

  useEffect(() => {
    getSalesLeaderboardByDate(
      moment(dateRange.start_date).format("YYYY-MM-DD"),
      moment(dateRange.end_date).format("YYYY-MM-DD")
    )
      .then((res) => {
        console.log(res.data.data);
        setSales(res.data.data);
      })
      .catch((err) => console.error(err));
  }, [dateRange]);

  const backgroundColors = [
    "bg-red-100/25",
    "bg-orange-100/25",
    "bg-amber-100/25",
    "bg-yellow-100/25",
    "bg-lime-100/25",
    "bg-green-100/25",
    "bg-blue-100/25",
    "bg-purple-100/25",
    "bg-pink-100/25",
  ];

  const borderColors = [
    "border-red-100",
    "border-orange-100",
    "border-amber-100",
    "border-yellow-100",
    "border-lime-100",
    "border-green-100",
    "border-blue-100",
    "border-purple-100",
    "border-pink-100",
  ];

  return (
    <div>
      <div className="flex flex-col rounded-lg border bg-white dark:border-slate-700">
        <div className="card-header-background relative flex items-center border-b bg-gray-100 p-4 dark:border-b dark:border-slate-700 dark:bg-slate-900 dark:text-white">
          <img
            src={`${getUploadsUrl()}/dashboard-icons/sales-leaderboard.png`}
            className="absolute bottom-2 left-0 h-16 w-16"
          />
          <div className="ml-12 flex flex-col justify-between lg:w-full lg:flex-row">
            <h3 className=" font-bold">Sales Leaderboard</h3>
            <div className="">
              <DateRangeInput
                ref={dateInputRef}
                dateClassName="text-xs font-bold"
                startDate={dateRange.start_date}
                endDate={dateRange.end_date}
                onRangeChange={(startDate, endDate) => {
                  // if (endDate) {
                  //   alert(" end date is clicked");
                  // }
                  setDateRange((dateRange) => ({
                    start_date: startDate,
                    end_date: endDate,
                  }));
                }}
                controlBtns={true}
              />
            </div>
          </div>
        </div>
        <div className="max-h-[50vh] overflow-auto px-2 dark:bg-gray-600">
          <div className="flex flex-col items-center justify-center gap-3 py-4">
            {sales?.bookings?.map((sale, index) => (
              <Link
                className={`w-full  ${
                  auth?.permissions?.includes("Booking.view_all") ||
                  auth?.roles?.includes("Superadmin") ||
                  sale.converted_by_id == auth.id ||
                  sale.converted_by_id == null
                    ? ""
                    : "cursor-not-allowed"
                }`}
                target="_blank"
                to={`/bookings?converted_by=${
                  sale?.converted_by_id || "website"
                }&booking_start_date=${moment(dateRange.start_date).format(
                  "YYYY-MM-DD"
                )}&booking_end_date=${moment(dateRange.end_date).format(
                  "YYYY-MM-DD"
                )}`}
                onClick={(event) =>
                  auth?.permissions?.includes("Booking.view_all") ||
                  auth?.roles?.includes("Superadmin") ||
                  sale.converted_by_id == auth.id ||
                  sale.converted_by_id == null
                    ? ""
                    : event.preventDefault()
                }
              >
                <div
                  key={sale.converted_by}
                  className={`flex w-full items-center justify-between gap-4 rounded-md bg-white p-2 shadow-[1px_1px_4px_rgba(0,0,0,0.15)] transition-all duration-300 ease-out hover:shadow-md dark:bg-slate-800 dark:text-white`}
                >
                  <div className="h-12 w-12 shrink-0 rounded-full bg-gray-300">
                    <img
                      src={`${getUploadsUrl()}/employees/${
                        sale?.profile_pic || "png-transparent-user.png"
                      }`}
                      alt="profile pic"
                      className="h-12 w-12 rounded-full"
                    />
                  </div>
                  <div className="line-clamp-1 grow font-medium">
                    {sale.converted_by || "Website"}
                    <p className="text-sm text-gray-400">
                      #:{index + 1} | pax:{sale.total_pax}/
                      {sales?.total_pax_sum}
                    </p>
                  </div>
                  <div className="flex flex-col whitespace-nowrap text-sm font-medium text-gray-400">
                    <p>
                      {sale.total_amount} / {sales.total_amount_sum}
                    </p>
                    <div className="relative h-2 rounded-full bg-gray-200">
                      <div
                        className={`absolute left-0 top-0 h-full rounded-full bg-[#89C445]`}
                        style={{
                          width: `${Math.ceil(
                            (sale.total_amount / sales.total_amount_sum) * 100
                          )}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeSalesLeaderboard;
