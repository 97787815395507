import { admin } from "../../ServiceConfig";

export function createPackage(packageData) {
  return admin.post(`/packages`, packageData);
}

export function getUpcomingPackages(){
  return admin.get(`/eventDate-participants`);
}

export function addPackageExtraContent(data) {
  return admin.post(`/package/extra-content`, data);
}

export function createItinerary(data) {
  return admin.post(`/itineraries`, data);
}

export function createItineraryDays(data) {
  return admin.post(`/itinerary/days`, data);
}

export function createItineraryPrices(data) {
  return admin.post(`/itinerary/prices`, data);
}

export function createPackageFaqs(data) {
  return admin.post(`/faqs`, data);
}

export function addPackageImages(data) {
  return admin.post(`/packageImages`, data);
}

//? --------------------------- UPDATE ------------------------------

export function updatePackageStatus(packageId, data) {
  return admin.post(`packages/${packageId}/status`, data);
}

export function updatePackage(packageId, packageData) {
  return admin.put(`/packages/${packageId}`, packageData);
}

export function updateFaqById(faqId, data) {
  return admin.put(`/faqs/${faqId}`, data);
}

export function updateExtraContent(ecId, data) {
  return admin.put(`/package/extra-content/${ecId}`, data);
}

export function updateItinerary(itineraryId, data) {
  return admin.put(`/itineraries/${itineraryId}`, data);
}

export function updateItineraryDays(itineraryId, data) {
  return admin.put(`/itinerary/days/${itineraryId}`, data);
}

export function reorderImagesSteptwo(data){
  return admin.post(`/order-packageImages`, data)
}

// ? ---------------- GET ---------------------------------

export function getPackage(packageId) {
  return admin.get(`/packages/${packageId}`);
}

export function getPackagesByCountry(countryId) {
  return admin.get(`packages/country/${countryId}`);
}

export function getPackagesByState(stateId) {
  return admin.get(`packages/state/${stateId}`);
}

export function getAllTravelModes() {
  return admin.get(`/allTravelModesOption`);
}

export function getAllRiders() {
  return admin.get(`/allRidersOption`);
}

export function getAllItinerariesByPackageId(packageId) {
  return admin.get(`/package/${packageId}/itineraries`);
}

export function getAllOccupancies() {
  return admin.get(`/allOccupanciesOption`);
}

export function getDifficulties() {
  return admin.get(`/levels`);
}

export function getPackageImages(packageId) {
  return admin.get(`/package/${packageId}/images`);
}

export function getPackageExtraContent(packageId) {
  return admin.get(`/package/${packageId}/extra-content`);
}

export function getPackageFaqs(packageId) {
  return admin.get(`/package/${packageId}/faqs`);
}

export function reorderFaqList(orders) {
  console.log(orders);
  return admin.post(`/reorder-faqs`, orders);
}
export function bulkPackageDataUpdate(data) {

  return admin.post(`/package-bulk-update`, data);
}

export function getPriceByItinerary(itineraryId) {
  return admin.get(`/itinerary/${itineraryId}/prices`);
}

export function getPriceByItineraryandBatch(itineraryId, batchId) {
  return admin.get(`/prices-by-itinerary-startdate/${itineraryId}/${batchId}`);
}

export function getDaysByItineraryId(itineraryId) {
  return admin.get(`/itinerary/${itineraryId}/days`);
}
// ?----------------------- DELETE --------------------

export function deletePackage(packageId) {
  return admin.delete(`/packages/${packageId}`);
}

export function deleteItineraryDay(dayId) {
  return admin.delete(`/itinerary/days/${dayId}`);
}

export function DeleteFaq(faqId) {
  return admin.delete(`/faqs/${faqId}`);
}

export function deleteExtraContent(ecId) {
  return admin.delete(`/package/extra-content/${ecId}`);
}

export function deleteItineraryDays(dayId) {
  return admin.delete(`/itineraries/days/${dayId}`);
}

export function deletePackageImages(packageId) {
  return admin.delete(`/packageImages/${packageId}`);
}
export function deleteItinDayWiseImages(packageId) {
  return admin.delete(`/itinerarydaywiseimages/${packageId}`);
}

export function getUnassignedPackages() {
  return admin.get(`/unassigned-package`);
}

export function AddThingsToKnowToPackage(data) {
  return admin.post(`/package-things-to-know`, data);
}

export function AddThingsToPackToPackage(data) {
  return admin.post(`/package-things-to-pack`, data);
}

export function getAllBlogs() {
  return admin.get(`/all-blogs`);
}

export function getAllPackages() {
  return admin.get(`/all-packages`);
}

export function getCategoryPackagesByCountryOrState(filter) {
  return admin.post(`/getPackageByCountryStateCategory`, filter);
}

export function getPackagesByCategoryId(id){
  return admin.get(`/categories/${id}/packages`)
}

export function getBatchesByPackageIdandMonth(data){
  return admin.post(`/get-uniqueEventDate`, data)
}

export function getFDTrekparticipants(packageId, event_date){
  return admin.get(`/viewallfdtrekParticipants?package_id=${packageId}&event_date=${event_date}`)
}

export function generateTrekCertificates(data){
  return admin.post(`/generate-trek-certificate`, data)
}

export function getMonthsWithBatchesOfPackage(data){
  return admin.post(`/get-uniqueMonth`, data)
}

export function updateParticipantByParticipantId(bookingId, participantId, data){
  return admin.post(`/update-participant/${bookingId}/${participantId}`, data)
}

export function getAllNonselectedChristmasPackages(data) {
  return admin.post(`/xmas-new-year-packages`, data)
}