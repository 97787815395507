import React, { useEffect, useState } from 'react'
import InputLabel from '../inputs/InputLabel'
import ComponentHeader from '../common/ComponentHeader'
import H2 from '../../../components/headings/H2'
import { getAllLeadCategory } from '../../services/LeadService'
import BookingFiltersDataExt from './BookingFiltersDataExt'
import LeadFiltersDataExt from './LeadFiltersDataExt'
import { submitQueryForBooking, submitQueryForLeads } from '../../services/DataExtractionServices'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { FaHistory } from 'react-icons/fa'


const arrayOfOptionsToSendingInEmail = [
  {
    value: 1,
    label: 'First Name',
    sendingValue: 'first_name'
  },
  {
    value: 2,
    label: 'Last Name',
    sendingValue: 'last_name'
  },
  {
    value: 3,
    label: 'Email',
    sendingValue: 'email'
  },
  {
    value: 4,
    label: 'Country Code',
    sendingValue: 'country_code'
  },
  {
    value: 5,
    label: 'Phone',
    sendingValue: 'phone'
  },
  {
    value: 6,
    label: 'Age',
    sendingValue: 'age'
  },
  {
    value: 7,
    label: 'Date of Birth',
    sendingValue: 'dob'
  },
  {
    value: 8,
    label: 'Gender',
    sendingValue: 'gender'
  },
  {
    value: 9,
    label: 'Coming From',
    sendingValue: 'coming_from'
  },
]

const Home = () => {
  const [selectedFilter, setSelectedFilter] = useState([]);

  const [typeOfExt, setTypeOfExt] = useState()
  const [valuesinSendingEmail, setValuesInSendingEmail] = useState([1, 2, 3, 4, 5])

  const [showingCount, setShowingCount] = useState()

  const updateFilter = (filter) => {
    let index = selectedFilter.findIndex((sf) => sf.field === filter.field);
    if (index >= 0) {
      if (filter.value && !Array.isArray(filter.value)) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else if (Array.isArray(filter.value) && filter.value[0]) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else {
        setSelectedFilter(
          selectedFilter.filter((af) => af.field !== filter.field)
        );
      }
    } else {
      setSelectedFilter([...selectedFilter, filter]);
    }
  };

  const handleSendEmail = async () => {
    const submitData = selectedFilter

    // values that user will get in csv file
    const filteredSendingValues = arrayOfOptionsToSendingInEmail
      .filter(option => valuesinSendingEmail.includes(option.value))
      .map(option => option.sendingValue);


    // if element with value of field = fields is already exist then just replace
    const fieldsIndex = submitData.findIndex(item => item.field === "fields");
    if (fieldsIndex !== -1) {
      submitData[fieldsIndex].value = filteredSendingValues;
    } else {
      submitData.push({
        field: "fields",
        type: "=",
        value: filteredSendingValues,
      });
    }


    // sepration of start and end date from event dates array
    const eventDateIndex = submitData.findIndex(item => item.field === "event_date");
    if (eventDateIndex !== -1) {
      const param = submitData[eventDateIndex];
      submitData.splice(eventDateIndex, 1);
      submitData.push(
        {
          field: "event_date_from",
          type: "=",
          value: param.value[0],
        },
        {
          field: "event_date_to",
          type: "=",
          value: param.value[1],
        }
      );
    }

    // sepration of start date and end date from booking date array
    const bookingDateIndex = submitData.findIndex(item => item.field === "booking_date");
    if (bookingDateIndex !== -1) {
      const param = submitData[bookingDateIndex];
      submitData.splice(bookingDateIndex, 1);
      submitData.push(
        {
          field: "start_date",
          type: "=",
          value: param.value[0],
        },
        {
          field: "end_date",
          type: "=",
          value: param.value[1],
        }
      );
    }

    // if getting booking data
    if (typeOfExt === 'booking') {
      try {
        const res = await submitQueryForBooking(submitData)
        setShowingCount(res.data.data.count)
        toast.success(res.data.message)
      } catch (error) {
        toast.error(error.response.data.message)
      }
    }

    // if getting leads data
    if (typeOfExt === 'leads') {
      try {
        const res = await submitQueryForLeads(submitData)
        setShowingCount(res.data.data.count)
        toast.success(res.data.message)
      } catch (error) {
        toast.error(error.response.data.message)
      }
    }
  }

  useEffect(() => {
setSelectedFilter([])
setShowingCount()
  }, [typeOfExt])
  return (
    <div className='h-screen'>
      <ComponentHeader className="border-b">
        <H2>Data Extraction</H2>
        <Link
          to="/data-extraction/logs"
          className="btn-primary flex items-center dark:bg-green-800 gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
        >
          <FaHistory /> See History Logs
        </Link>
      </ComponentHeader>
      <div className="flex flex-col items-center w-full font-bold text-xl mb-6">
        <InputLabel className='text-2xl' label="Select Type of Data Extraction" />
        <div className="flex items-center gap-4 mt-2">
          <label htmlFor="booking" className="flex items-center gap-1 cursor-pointer">
            <input type="radio" name="" id="booking" checked={typeOfExt === 'booking'} onChange={(e) => {
              if (e.target.checked) setTypeOfExt('booking')
            }} />
            By Booking
          </label>
          <label htmlFor="leads" className="flex items-center gap-1 cursor-pointer">
            <input type="radio" name="" id="leads" checked={typeOfExt === 'leads'} onChange={(e) => {
              if (e.target.checked) setTypeOfExt('leads')
            }} />
            By Leads
          </label>
        </div>
      </div>
      <div className='grid 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 grid-cols-1 gap-3'>
        {
          typeOfExt === 'booking' && <BookingFiltersDataExt
            updateFilter={updateFilter}
            valuesinSendingEmail={valuesinSendingEmail}
            setValuesInSendingEmail={setValuesInSendingEmail}
            arrayOfOptionsToSendingInEmail={arrayOfOptionsToSendingInEmail}
          />
        }

        {
          typeOfExt === 'leads' && <LeadFiltersDataExt
            updateFilter={updateFilter}
            valuesinSendingEmail={valuesinSendingEmail}
            setValuesInSendingEmail={setValuesInSendingEmail}
            arrayOfOptionsToSendingInEmail={arrayOfOptionsToSendingInEmail}
          />
        }
      </div>

      {
        showingCount || showingCount === 0 ? <p className='font-bold text-2xl mt-4'>
          The Count is: {showingCount}
        </p> : <></>
      }
      {typeOfExt && <div className='flex justify-center gap-10'>
        <button
          onClick={() => {
            handleSendEmail()
            toast.info("You will recieve an Email in Your Official email ID")
          }}
          className="btn-primary mt-10 flex items-center dark:bg-green-800 gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
        >
          Extract and Export Data via Email
        </button>
      </div>}
    </div>
  )
}

export default Home