import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { getAllStateByCountry } from "../../../services/StateServices";
import { getAllCountriesOption } from "../../../services/CountryServices";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { getAllCategories } from "../../../services/CategoryServices";
import {
  getAllLocation,
  getAllLocationByCountryId,
  getLocationsByStateId,
} from "../../../services/LocationServices";
import { FaPlusSquare } from "react-icons/fa";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import LocationForm from "../../locations/LocationForm";
import InputLabel from "../../inputs/InputLabel";
import EditorInput from "../../inputs/EditorInput";
import usePackageData from "../../../hooks/usePackageData";
import { createPackage, updatePackage, getAllNonselectedChristmasPackages } from "../../../services/PackageService";
import SelectSearch from "../../inputs/SelectSearch";
import { getFrontUrl } from "../../../../ServiceConfig";
import MultiSelect from "../../inputs/MultiSelect";

Modal.setAppElement("#modal");

function Step1({ edit, onSuccess }) {
  const [countries, setCountries] = useState();
  const [selectedCountryStates, setSelectedCountryStates] = useState();
  const [categories, setCategories] = useState();
  const [locations, setLocations] = useState();
  const [addLocationModalOpen, setAddLocationModalOpen] = useState(false);
  const [slugEditable, setSlugEditable] = useState(false);
  const [showingURL, setShowingURL] = useState("")
  const [allChristmasPackage, setAllChristmasPackage] = useState([])

  const { packageData, setPackageData } = usePackageData();

  // ? Form Validation Schema
  const packageInfoSchema = Yup.object().shape({
    country_id: Yup.string().required("Required"),
    state_id: Yup.string().when("country_id", {
      is: (country_id) => country_id === "101",
      then: Yup.string().required("Required"),
      otherwise: Yup.string().nullable(),
    }),
    location_id: Yup.string().required("Required"),
    // category_id: Yup.string().required("Required"),
    title: Yup.string().max(70, "Too Long!").required("Required"),
    slug: Yup.string().max(70, "Too Long!").required("Required"),
    description: Yup.string().required("Required"),
    is_winter_trip: Yup.number().required("Required"),
    is_summer_trip: Yup.number().required("Required"),
  });

  // ?--------------- Fetch State By CountryId ---------------
  const getStatesByCountry = (countryId) => {
    getAllStateByCountry(countryId)
      .then((res) => {
        setSelectedCountryStates(res.data.data);
      })
      .catch((err) => { });
  };

  // ?--------------- Fetch Location By CountryId ---------------
  const getLocationByCountry = (countryId) => {
    if (countryId == 101) return;
    getAllLocationByCountryId(countryId)
      .then((res) => {
        setLocations(res.data.data);
      })
      .catch((err) => { });
  };

  // ?--------------- Fetch Location By StateId ---------------
  const getLocationByState = (stateId) => {
    getLocationsByStateId(stateId)
      .then((res) => {
        setLocations(res.data.data);
      })
      .catch((err) => { });
  };

  // ? ----------------- Handle Country Change ---------------

  const handleCountryChange = (value) => {
    // console.log(value);
    getLocationByCountry(value);
    if (value === 101) {
      getStatesByCountry(value);
    }
  };

  // ? ----------------- Handle State Change ---------------
  const handleStateChange = (value) => {
    getLocationByState(value);
  };

  const onAddLocation = (value) => {
    setAddLocationModalOpen(false);
    setLocations((locations) => [value, ...locations]);
  };

  const addPackage = (data) => {
    createPackage(data)
      .then((res) => {
        setPackageData((packageData) => ({
          ...packageData,
          package: res.data.data,
        }));
        onSuccess();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editPackage = (data) => {
    updatePackage(packageData.package.id, data)
      .then((res) => {
        setPackageData((packageData) => ({
          ...packageData,
          package: res.data.data,
        }));
        onSuccess();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllChristamsPackages = async (id, catId, contId, stateId) => {
    const submitData = {
      package_id: id,
      category_id: catId,
      country_id: contId,
      state_id: stateId ?? ''
    }
    try {
      const res = await getAllNonselectedChristmasPackages(submitData)
      setAllChristmasPackage(res.data.data.map((onepkg) => ({
        label: onepkg.title,
        value: onepkg.id
      })))
    } catch (error) {
      console.log(error)
    }
  }
  // ? Fetch Initial Data on load
  useEffect(() => {
    getAllCountriesOption()
      .then((res) => {
        setCountries(res.data.data);
      })
      .catch((err) => {
        console.log(err, "Error---------------");
      });

    getAllCategories()
      .then((res) => {
        setCategories(res.data.data.data);
      })
      .catch((err) => {
        console.log(err, "Error---------------");
      });

    // getAllLocation()
    //   .then((res) => {
    //     setLocations(res.data.data.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);

  useEffect(() => {
    if (packageData?.package) {
      getAllChristamsPackages(packageData?.package?.id, packageData?.package?.category_id, packageData?.package?.country_id, packageData?.package?.state_id)
    }
  }, [packageData])

  useEffect(() => {
    packageData.package?.country_id &&
      packageData.package?.country_id == 101 &&
      getStatesByCountry(packageData.package.country_id);

    packageData.package?.country_id &&
      packageData.package?.country_id != 101 &&
      getLocationByCountry(packageData.package.country_id);

    packageData.package?.state_id &&
      getLocationByState(packageData.package.state_id);
  }, [packageData]);

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          package_type: packageData.package?.package_type
            ? packageData.package?.package_type
            : "0",
          country_id: packageData.package?.country_id
            ? packageData.package?.country_id
            : "",
          state_id: packageData.package?.state_id
            ? packageData.package?.state_id
            : "",
          location_id: packageData.package?.location_id
            ? packageData.package?.location_id
            : "",
          category_id: packageData.package?.category_id
            ? packageData.package?.category_id
            : "",
          title: packageData.package?.title ? packageData.package?.title : "",
          slug: packageData.package?.slug ? packageData.package?.slug : "",
          short_name_code: packageData.package?.short_name_code
            ? packageData.package?.short_name_code
            : "",
          description: packageData.package?.description
            ? packageData.package?.description
            : "",
          is_winter_trip: packageData.package?.is_winter_trip
            ? `${packageData.package?.is_winter_trip}`
            : "0",
          is_summer_trip: packageData.package?.is_summer_trip
            ? `${packageData.package?.is_summer_trip}`
            : "0",
          is_monsoon_trip: packageData.package?.is_monsoon_trip
            ? `${packageData.package?.is_monsoon_trip}`
            : "0",
          is_bestseller: packageData.package?.is_bestseller
            ? `${packageData.package?.is_bestseller}`
            : "0",
          is_recommended: packageData.package?.is_recommended
            ? `${packageData.package?.is_recommended}`
            : "0",
          is_trending: packageData.package?.is_trending
            ? `${packageData.package?.is_trending}`
            : "0",
          is_xmas_newyear: packageData.package?.is_xmas_newyear
            ? `${packageData.package?.is_xmas_newyear}`
            : "0",
          xmas_package_ids: packageData?.package?.xmas_package_ids
            ? packageData?.package?.xmas_package_ids
            : []
        }}
        validationSchema={packageInfoSchema}
        onSubmit={(values) => {
          packageData?.package?.id ? editPackage(values) : addPackage(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            {/* // ? Package Type */}
            <div className="flex lg:flex-row flex-col lg:gap-[10rem]">
            <div className="my-4">
              <InputLabel label="Package Type" />
              <div className="flex items-center gap-12">
                <label className="flex items-baseline gap-1">
                  <Field type="radio" name="package_type" value="0" />
                  Fixed Departure
                </label>
                <label className="flex items-baseline gap-1">
                  <Field type="radio" name="package_type" value="1" />
                  Customize
                </label>
              </div>
            </div>
             <div className="my-4">
              <InputLabel label="Is This a Christmas and New Year?" />
              <div className="flex items-center gap-12">
                <label className="flex items-baseline gap-1">
                  <Field type="radio" name="is_xmas_newyear" value="1" />
                  Yes
                </label>
                <label className="flex items-baseline gap-1">
                  <Field type="radio" name="is_xmas_newyear" value="0" />
                  No
                </label>
              </div>
            </div>
            </div>

            <div className="my-4 flex flex-wrap gap-4">
              {/* //? Select Country */}
              <div className="grow basis-full lg:basis-[45%]">
                <InputLabel label="Select Country" labelFor="country" />
                <SelectSearch
                  value={parseInt(values.country_id)}
                  onChange={(value) => {
                    handleCountryChange(value);
                    setFieldValue("country_id", value);
                    value !== 101 && setFieldValue("state_id", null);
                  }}
                  options={countries?.map((country) => ({
                    label: country.name,
                    value: country.id,
                  }))}
                />
                <ErrorMessage name="country_id">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              {/* //? Select State */}
              <div className="grow basis-full lg:basis-[45%]">
                <InputLabel label="Select State" labelFor="state" />
                <SelectSearch
                  value={parseInt(values.state_id)}
                  disabled={values.country_id !== 101}
                  onChange={(value) => {
                    handleStateChange(value);
                    setFieldValue("state_id", value);
                  }}
                  options={selectedCountryStates?.map((state) => ({
                    label: state.name,
                    value: state.id,
                  }))}
                />

                <ErrorMessage name="state">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
            </div>

            <div className="my-4 flex flex-wrap gap-4">
              {/* //? Select Location */}
              <div className="grow basis-full lg:basis-[45%]">
                <InputLabel label="Select Location" labelFor="location" />
                <div className="flex items-center gap-2">
                  <div className="grow">
                    <SelectSearch
                      value={parseInt(values.location_id)}
                      disabled={
                        (values.country_id === 101 && values.state_id === "") ||
                        values.country_id === ""
                      }
                      onChange={(value) => {
                        setFieldValue("location_id", value);
                      }}
                      options={locations?.map((location) => ({
                        label: location.name,
                        value: location.id,
                      }))}
                    />

                    <ErrorMessage name="location_id">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <button
                    type="button"
                    onClick={() =>
                      setAddLocationModalOpen(!addLocationModalOpen)
                    }
                  >
                    <FaPlusSquare />
                  </button>
                </div>
              </div>

              {/* //? Select Category */}
              <div className="grow basis-full lg:basis-[45%]">
                <InputLabel label="Select Category" />
                <SelectSearch
                  value={parseInt(values.category_id)}
                  onChange={(value) => {
                    setFieldValue("category_id", value);
                  }}
                  options={categories?.map((category) => ({
                    label: category.name,
                    value: category.id,
                  }))}
                />
                <ErrorMessage name="category_id">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
            </div>

            <div className="my-4 flex flex-wrap gap-4">
              {/* //? Package Name */}
              <div className="grow basis-full lg:basis-[45%]">
                <InputLabel label="Title" />
                <Field
                  className="field"
                  id="title"
                  name="title"
                  onChange={(e) => {
                    setShowingURL(e.target.value)
                    setFieldValue(`title`, e.target.value);
                    !packageData.package?.id &&
                      setFieldValue(
                        "slug",
                        e.target.value.toLowerCase().replace(/ /g, "-")
                      );
                  }}
                />
                <ErrorMessage name="title">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
                {/* <a className="text-blue-800" target="_blank" href={`${getFrontUrl()}${showingURL}`}>{`${getFrontUrl()}${showingURL}`}</a> */}
              </div>
  
              {/* //? Package Slug */}
              <div className="grow basis-full lg:basis-[45%]">
                <div className="flex items-center justify-between">
                  <InputLabel label="Slug" />
                  <button
                    onClick={() => setSlugEditable(true)}
                    className="text-xs font-medium text-blue-500"
                    type="button"
                  >
                    Edit
                  </button>
                </div>
                <Field
                  className="field"
                  id="slug"
                  name="slug"
                  disabled={!slugEditable}
                />
                <ErrorMessage name="slug">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
              {/* //? Package Short Name Code */}
              <div className="grow basis-full lg:basis-[45%]">
                <div className="flex items-center justify-between">
                  <InputLabel label="Short Name Code for Account Tally" />
                </div>
                <Field
                  className="field"
                  id="short_name_code"
                  name="short_name_code"
                />
                <ErrorMessage name="short_name_code">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>

              {values?.is_xmas_newyear === "0" && <div className="grow basis-full lg:basis-[45%]">
                <InputLabel label="Select Related Christmas New Year Package" />
                <MultiSelect
                    onChange={(value) => setFieldValue('xmas_package_ids', value)}
                    value={values?.xmas_package_ids}
                    options={allChristmasPackage}
                />
              </div>}
            </div>

            <div className="my-4 flex flex-wrap gap-4 lg:flex-nowrap">
              {/* //? Is Winter Trip */}
              <div className="grow basis-full lg:basis-[30%]">
                <div role="group" aria-labelledby="my-radio-group">
                  <InputLabel label="Is this a winter Trip / Trek?" />
                  <div className="flex items-center gap-12">
                    <label className="flex items-baseline gap-1">
                      <Field type="radio" name="is_winter_trip" value="1" />
                      Yes
                    </label>
                    <label className="flex items-baseline gap-1">
                      <Field type="radio" name="is_winter_trip" value="0" />
                      No
                    </label>
                  </div>
                </div>
              </div>

              {/* //? Is Summer Trip */}
              <div className="grow basis-full lg:basis-[30%]">
                <div role="group" aria-labelledby="my-radio-group">
                  <InputLabel label="Is this a Summer Trip / Trek?" />
                  <div className="flex items-center gap-12">
                    <label
                      className="flex items-baseline gap-1"
                      htmlFor="sumerTrip"
                    >
                      <Field
                        type="radio"
                        name="is_summer_trip"
                        id="sumerTrip"
                        value="1"
                      />
                      Yes
                    </label>
                    <label className="flex items-baseline gap-1">
                      <Field type="radio" name="is_summer_trip" value="0" />
                      No
                    </label>
                  </div>
                </div>
              </div>

              {/* //? Is Monsoon Trip */}
              <div className="grow basis-full lg:basis-[30%]">
                <div role="group" aria-labelledby="my-radio-group">
                  <InputLabel label="Is this a Monsoon Trip / Trek?" />
                  <div className="flex items-center gap-12">
                    <label
                      className="flex items-baseline gap-1"
                      htmlFor="monsoonTrip"
                    >
                      <Field
                        type="radio"
                        name="is_monsoon_trip"
                        id="monsoonTrip"
                        value="1"
                      />
                      Yes
                    </label>
                    <label className="flex items-baseline gap-1">
                      <Field type="radio" name="is_monsoon_trip" value="0" />
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="my-4 flex flex-wrap gap-4 lg:flex-nowrap">
              {/* //? Is Bestseller */}
              <div className="grow basis-full lg:basis-[30%]">
                <div role="group" aria-labelledby="my-radio-group">
                  <InputLabel label="Is Bestseller?" />
                  <div className="flex items-center gap-12">
                    <label className="flex items-baseline gap-1">
                      <Field type="radio" name="is_bestseller" value="1" />
                      Yes
                    </label>
                    <label className="flex items-baseline gap-1">
                      <Field type="radio" name="is_bestseller" value="0" />
                      No
                    </label>
                  </div>
                </div>
              </div>

              {/* //? Is Recommended Trip */}
              <div className="grow basis-full lg:basis-[30%]">
                <div role="group" aria-labelledby="my-radio-group">
                  <InputLabel label="Is Recommended?" />
                  <div className="flex items-center gap-12">
                    <label
                      className="flex items-baseline gap-1"
                      htmlFor="recommended"
                    >
                      <Field
                        type="radio"
                        name="is_recommended"
                        id="recommended"
                        value="1"
                      />
                      Yes
                    </label>
                    <label className="flex items-baseline gap-1">
                      <Field type="radio" name="is_recommended" value="0" />
                      No
                    </label>
                  </div>
                </div>
              </div>

              {/* //? Is Trending */}
              <div className="grow basis-full lg:basis-[30%]">
                <div role="group" aria-labelledby="my-radio-group">
                  <InputLabel label="Is Trending?" />
                  <div className="flex items-center gap-12">
                    <label
                      className="flex items-baseline gap-1"
                      htmlFor="trendingTrip"
                    >
                      <Field
                        type="radio"
                        name="is_trending"
                        id="trendingTrip"
                        value="1"
                      />
                      Yes
                    </label>
                    <label className="flex items-baseline gap-1">
                      <Field type="radio" name="is_trending" value="0" />
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {/* //? Package Description */}
            <div className="mb-4">
              <InputLabel label="Description" />
              <EditorInput fieldKey="description" data={values.description} />
            </div>

            <div className="my-4 text-center">
              <button type="submit" className="btn-primary">
                submit
              </button>
            </div>
          </Form>
        )}
      </Formik>

      <Modal
        isOpen={addLocationModalOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setAddLocationModalOpen(false)}
        // style={customStyles}
        contentLabel="Add Country Modal"
      >
        <div className="sticky top-0 z-20 flex items-center justify-between border-b bg-white pb-2">
          <h2 className=" text-xl font-bold">Add Location</h2>
          <div
            onClick={() => setAddLocationModalOpen(false)}
            className="cursor-pointer rounded-full border bg-white p-2"
          >
            <MdClose />
          </div>
        </div>
        <div className="mt-4 h-full overflow-auto bg-white">
          <LocationForm onSuccess={onAddLocation} />
        </div>
      </Modal>
    </div>
  );
}

export default Step1;
