import React, { useEffect, useState } from "react";
import { read, utils } from "xlsx";
import { uploadCSV } from "../admin/services/CommonServices";
import { toast } from "react-toastify";
import SelectSearch from "../admin/components/inputs/SelectSearch";
import { saveAs } from "file-saver";

const FileImporter = ({
  fileData,
  fileUploadPath,
  setFileUploadedPath,
  columns,
  setFileData,
  columnMapping,
  setColumnMapping,
  onImportData,
  skippedColumns,
  setSkippedColumns,
  sampleFile,
  sampleFileName,
}) => {
  const [fileName, setFileName] = useState();
  const [mappingColumnView, setMappingColumnView] = useState("all");

  const handleColumnChange = (value, columnName) => {
    setColumnMapping((prevMapping) => ({
      ...prevMapping,
      [columnName]: value,
    }));

    setSkippedColumns(skippedColumns?.filter((col) => col !== columnName));

    return false;
  };

  useEffect(() => {
    console.log(columnMapping);
  }, []);

  const handleImport = async (e) => {
    const files = e.target.files;
    if (files.length) {
      const file = files[0];
      const res = await uploadCSV({ file: file }, fileUploadPath);
      if (res.data.status !== "success")
        return toast.error("something went wrong. Try Again.");
      setFileUploadedPath(res.data.data);
      setFileName(file.name);
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          setFileData(rows);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const skipColumn = (column, value) => {
    if (value) {
      setSkippedColumns([...skippedColumns, column]);
      let mappedColumns = columnMapping;
      mappedColumns[column] = "";

      let columnMap = columnMapping;

      Object.keys(columnMap).forEach((col) => {
        if (columnMap[col] === "" || !columnMap[col]) {
          delete columnMap[col];
        }
      });
      setColumnMapping(columnMap);
    } else {
      setSkippedColumns(skippedColumns.filter((col) => col !== column));
    }
  };

  const downloadSample = () => {
    saveAs(`${sampleFile}`, `${sampleFileName}`);
  };

  return (
    <div>
      <div className="mt-4 rounded-lg border-2 border-dashed p-4">
        <label className="mx-auto flex h-[100px] w-[400px] items-center justify-center rounded-lg bg-gray-100 text-center text-lg font-bold">
          <span>{fileName ? fileName : "Upload Excel OR CSV File"}</span>
          <span>
            <input
              type="file"
              name="file"
              hidden
              className="custom-file-input"
              id="inputGroupFile"
              required
              onChange={handleImport}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </span>
        </label>
      </div>

      <div className="mb-4 text-right">
        <button
          onClick={downloadSample}
          className="text-sm font-bold text-blue-500"
        >
          Download Sample Excel File
        </button>
      </div>

      {fileData.length > 0 && (
        <div className="flex justify-center">
          <div>
            <h3 className="text-center text-xl font-bold">
              Map File Columns to Database Columns:
            </h3>
            {/* Tab for Mammped and Unmapped List */}
            {/* <div className="mt-4 flex justify-center">
              <div className="inline-flex justify-center rounded-full border p-[2px]">
                <button
                  onClick={() => setMappingColumnView("all")}
                  className={`rounded-full px-4 py-1 text-sm ${
                    mappingColumnView === "all"
                      ? "border border-jw-green/50 font-bold text-jw-green"
                      : ""
                  }`}
                >
                  All
                </button>
                <button
                  onClick={() => setMappingColumnView("mapped")}
                  className={`rounded-full px-4 py-1 text-sm ${
                    mappingColumnView === "mapped"
                      ? "border border-jw-green/50 font-bold text-jw-green"
                      : ""
                  }`}
                >
                  Mapped
                </button>
                <button
                  onClick={() => setMappingColumnView("unmapped")}
                  className={`rounded-full px-4 py-1 text-sm ${
                    mappingColumnView === "unmapped"
                      ? "border border-jw-green/50 font-bold text-jw-green"
                      : ""
                  }`}
                >
                  Unmapped
                </button>
              </div>
            </div> */}
            <table className="mt-4">
              <thead>
                <tr>
                  <th className="w-[300px] text-left">File Columns</th>
                  <th>Database Columns</th>
                </tr>
              </thead>
              {mappingColumnView === "all" && (
                <tbody>
                  {Object.keys(fileData[0] || {}).map((columnName, index) => (
                    <tr key={index} className="group">
                      <td>{columnName}:</td>
                      {/* Index : {index}
                      columnMapping : {Object.keys(columnMapping).length} */}
                      {columnMapping &&
                        (Object.keys(columnMapping).length == index ||
                          Object.keys(columnMapping).length == 0) &&
                        !columnMapping[columnName] &&
                        handleColumnChange(
                          columns.find((column) => column.value === columnName)
                            ?.value,
                          columnName
                        )}
                      <td className="flex items-center gap-2">
                        <label
                          htmlFor=""
                          className={`inline-flex gap-[2px] text-xs font-semibold ${
                            skippedColumns.includes(columnName)
                              ? ""
                              : "invisible group-hover:visible"
                          }`}
                        >
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            checked={skippedColumns.includes(columnName)}
                            onChange={(e) =>
                              skipColumn(columnName, e.target.checked)
                            }
                          />{" "}
                          Skip
                        </label>
                        <div className="grow">
                          <SelectSearch
                            options={[
                              ...columns?.map((column) => ({
                                label: column.label,
                                value: column.value,
                                disabled: Object.values(columnMapping).includes(
                                  column.value
                                ),
                              })),
                            ]}
                            value={columnMapping[columnName]}
                            onChange={(value) =>
                              handleColumnChange(value, columnName)
                            } // Call the function here on change
                            className="w-full"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
              {/* Commented Mapped and Unmapped Column Data Starts*/}
              {/* {mappingColumnView === "mapped" && (
                <tbody>
                  {Object.keys(columnMapping).map((column, index) => (
                    <tr key={index} className="group">
                      <td>{column}</td>
                      <td className="flex items-center gap-2">
                        <label
                          htmlFor=""
                          className={`inline-flex gap-[2px] text-xs font-semibold ${
                            skippedColumns.includes(column)
                              ? ""
                              : "invisible group-hover:visible"
                          }`}
                        >
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            checked={skippedColumns.includes(column)}
                            onChange={(e) =>
                              skipColumn(column, e.target.checked)
                            }
                          />{" "}
                          Skip
                        </label>
                        <SelectSearch
                          options={[
                            { label: "", value: "" },
                            ...columns?.map((column) => ({
                              label: column.replace(/_/g, " "),
                              value: column,
                              disabled:
                                Object.values(columnMapping).includes(column),
                            })),
                            {
                              label: "category name",
                              value: "category_name",
                              disabled:
                                Object.values(columnMapping).includes(
                                  "category_name"
                                ),
                            },
                          ]}
                          value={columnMapping[column]}
                          onChange={(value) =>
                            handleColumnChange(value, column)
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}

              {mappingColumnView === "unmapped" && (
                <tbody>
                  {Object.keys(fileData[0] || {})
                    .filter(
                      (key) => Object.keys(columnMapping).indexOf(key) === -1
                    )
                    .map((column, index) => (
                      <tr key={index} className="group">
                        <td>{column}</td>
                        <td className="flex items-center gap-2">
                          <label
                            htmlFor=""
                            className={`inline-flex gap-[2px] text-xs font-semibold ${
                              skippedColumns.includes(column)
                                ? ""
                                : "invisible group-hover:visible"
                            }`}
                          >
                            <input
                              type="checkbox"
                              name=""
                              id=""
                              checked={skippedColumns.includes(column)}
                              onChange={(e) =>
                                skipColumn(column, e.target.checked)
                              }
                            />{" "}
                            Skip
                          </label>
                          <SelectSearch
                            options={[
                              { label: "", value: "" },
                              ...columns?.map((column) => ({
                                key: column,
                                label: column.replace(/_/g, " "),
                                value: column,
                                disabled:
                                  Object.values(columnMapping).includes(column),
                              })),
                              {
                                label: "Assigned To",
                                value: "assigned_to",
                                disabled:
                                  Object.values(columnMapping).includes(
                                    "assigned_to"
                                  ),
                              },
                            ]}
                            value={columnMapping[column] || ""}
                            onChange={(value) =>
                              handleColumnChange(value, column)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              )} */}
              {/* Commented Mapped and Unmapped Column Data Ends*/}
            </table>
            <div className="my-8 text-center">
              <button onClick={onImportData} className="btn-primary">
                Import Data
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileImporter;
