import React from 'react'

const CreditDebitSelector = ({ transactionType, handleOptionChange }) => {
    return (
        <div className='flex lg:gap-32 lg:flex-row flex-col'>
            <p className='lg:text-md text-sm font-bold'>Type of Transaction</p>
            <div className='flex flex-row gap-6'>
                <div class="flex items-center flex-wrap">
                    <input onChange={handleOptionChange} checked={transactionType === 'credit'} type="radio" id="credit" name="radio-group" />
                    <label htmlFor="credit" class="cursor-pointer  px-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
                        Credit
                    </label>
                </div>
                <div class="flex items-center">
                    <input onChange={handleOptionChange} checked={transactionType === 'debit'} type="radio" id="debit" name="radio-group" />
                    <label htmlFor="debit" className="cursor-pointer  px-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
                        Debit
                    </label>
                </div>
                <div class="flex items-center">
                    <input onChange={handleOptionChange} checked={transactionType === 'transfer'} type="radio" id="transfer" name="radio-group" />
                    <label htmlFor="transfer" className="cursor-pointer  px-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
                        Transfer
                    </label>
                </div>
            </div>
        </div>
    )
}

export default CreditDebitSelector