import { ErrorMessage, Field, useFormikContext } from "formik";
import { memo } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import InputLabel from "../../components/inputs/InputLabel";
import { useState } from "react";
import { validateGiftCard } from "../../services/BookingServices";
import { toast } from "react-toastify";
import Collapsible from "../../../hooks/Collapsible";
import { FaCheck } from "react-icons/fa";

function CostingTotalForm({
  availableCreditNote,
  edit,
  proformaInvoice,
  selectedIndex,
  setSelectedIndex,
}) {
  const { values, setFieldValue } = useFormikContext();

  const [creditNoteApplied, setCreditNoteApplied] = useState(false);
  const [giftCardApplied, setGiftCardApplied] = useState(false);
  const [giftCard, setGiftCard] = useState();

  let timeoutId = useRef();

  useEffect(() => {
    clearTimeout(timeoutId.current);

    timeoutId.current = setTimeout(() => {
      let totalPax = 0;
      let total_cost = 0;
      let total_discount = 0;
      let total_cost_after_discount = 0;
      let total_gst = 0;
      let total_with_gst = 0;
      let round_off_amount = 0;

      values.cost.forEach((cst) => {
        totalPax += parseFloat(cst.pax);
        total_cost += parseFloat(cst.package_price) * parseFloat(cst.pax);
        total_discount += parseFloat(cst.package_discount * cst.pax);
        total_cost_after_discount += parseFloat(
          cst.package_price_after_discount * cst.pax
        );
        total_gst += parseFloat(cst.package_gst) * cst.pax;
        total_with_gst += cst.package_with_gst * parseFloat(cst.pax);
      });

      let total_addon_unit_price = 0;
      let total_addon_unit_gst = 0;
      let total_addon_amount = 0;

      values?.addons?.forEach((addon) => {
        total_addon_unit_price += addon.unit_price
          ? parseFloat(addon.unit_price) * addon.unit
          : 0;
        total_addon_unit_gst += addon.unit_gst
          ? parseFloat(addon.unit_gst) * addon.unit
          : 0;
        total_addon_amount += addon.amount ? parseFloat(addon.amount) : 0;
      });

      let total_other_service_unit_price = 0;
      let total_other_service_unit_gst = 0;
      let total_other_service_amount = 0;

      values?.other_services?.forEach((other_service) => {
        total_other_service_unit_price += other_service.margin
          ? (parseFloat(other_service.price) +
              parseFloat(other_service.margin)) *
            other_service.unit
          : 0;
        total_other_service_unit_gst += other_service.unit_gst
          ? parseFloat(other_service.unit_gst) * other_service.unit
          : 0;
        total_other_service_amount += other_service.amount
          ? parseFloat(other_service.amount)
          : 0;
      });

      setFieldValue("total_pax", totalPax);
      setFieldValue(
        "total_cost",
        parseFloat(total_cost) +
          parseFloat(total_addon_unit_price) +
          parseFloat(total_other_service_unit_price)
      );
      setFieldValue("total_discount", parseFloat(total_discount).toFixed(2));
      setFieldValue(
        "total_cost_after_discount",
        parseFloat(total_cost_after_discount) +
          parseFloat(total_addon_unit_price) +
          parseFloat(total_other_service_unit_price)
      );
      setFieldValue(
        "total_gst",
        (
          parseFloat(total_gst) +
          parseFloat(total_addon_unit_gst) +
          parseFloat(total_other_service_unit_gst)
        ).toFixed(2)
      );
      setFieldValue(
        "total_with_gst",
        total_with_gst + total_addon_amount + total_other_service_amount
      );

      let total_with_tcs =
        total_with_gst +
        total_addon_amount +
        total_other_service_amount +
        parseFloat(values.total_tcs);

      round_off_amount = Math.round(total_with_tcs) - total_with_tcs;

      setFieldValue("round_off_amount", round_off_amount.toFixed(2));
      setFieldValue(
        "total_amount_to_pay",
        Math.round(
          total_with_tcs -
            values.amount_used_in_credit_note -
            values.amount_used_in_gift_card
        )
      );
    }, 500);
  }, [values.cost, values.addons, values.other_services, values.total_tcs]);

  let timeoutId2 = useRef();
  useEffect(() => {
    clearTimeout(timeoutId2.current);
    timeoutId2.current = setTimeout(() => {
      if (values.total_amount_to_pay && values.total_amount_paid >= 0) {
        setFieldValue(
          `remaining_amount`,
          parseFloat(values.total_amount_to_pay) -
            parseFloat(values.total_amount_paid)
        );
      } else {
        setFieldValue(`remaining_amount`, 0);
      }
    }, 500);
  }, [values.total_amount_paid, values.total_amount_to_pay]);

  useEffect(() => {
    if (values.country_id !== 101) {
      setFieldValue("total_tcs", values.total_with_gst * 0.05);
    }
  }, [values.total_with_gst, values.country_id]);

  const applyCreditNote = () => {
    setCreditNoteApplied(true);
    setFieldValue("is_credit_note_used", true);
    setFieldValue(
      "total_amount_to_pay",
      values.total_with_gst - values.amount_used_in_credit_note
    );
  };

  const removeCreditNote = () => {
    setCreditNoteApplied(false);
    setFieldValue("is_credit_note_used", false);
    setFieldValue("amount_used_in_credit_note", 0);
    setFieldValue(
      `total_amount_to_pay`,
      values.total_with_gst + values.total_tcs - values.amount_used_in_gift_card
    );
  };

  const applyGiftCard = () => {
    setGiftCardApplied(true);
    validateGiftCard({ secret_code: values.secret_code })
      .then((res) => {
        setGiftCard(res.data.data);
        setFieldValue("amount_used_in_gift_card", res.data.data.amount);
        setFieldValue(
          `total_amount_to_pay`,
          values.total_amount_to_pay -
            res.data.data.amount -
            values.total_amount_to_pay
        );
        setFieldValue(`is_gift_card_used`, true);
      })
      .catch((err) => {
        setGiftCardApplied(false);
        toast.error(err.response.data.message);
      });
  };

  const removeGiftCard = () => {
    setGiftCardApplied(false);
    setFieldValue("amount_used_in_gift_card", 0);
    setFieldValue(`is_gift_card_used`, false);
    setFieldValue("secret_code", "");
    setFieldValue(
      `total_amount_to_pay`,
      values.total_with_gst +
        values.total_tcs -
        values.amount_used_in_credit_note
    );
  };

  return (
    <>
      <div className="my-2  bg-white  dark:bg-slate-800">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-12">
          <div className="col-span-1 rounded-lg border border-slate-200 p-4 dark:border-slate-700">
            <h3 className="mb-2 font-bold">Gift Card</h3>
            <div className="flex items-end gap-2">
              <div className="shrink grow basis-1/2">
                <InputLabel
                  label="Enter Code"
                  instruction={
                    values.amount_used_in_gift_card
                      ? `(Gift Card worth ${values.amount_used_in_gift_card} available)`
                      : ""
                  }
                />
                <Field className="field" name={`secret_code`} />
                <ErrorMessage name={`secret_code`}>
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
              {!giftCardApplied && (
                <button
                  onClick={applyGiftCard}
                  className="rounded-lg border border-jw-green py-1 px-4 text-jw-green"
                >
                  Apply
                </button>
              )}
              {giftCardApplied && (
                <button
                  onClick={removeGiftCard}
                  className="rounded-lg border border-red-500 py-1 px-4 text-red-500"
                >
                  Remove
                </button>
              )}
            </div>
          </div>
          {!proformaInvoice ? (
            <div className="col-span-1 rounded-lg border border-slate-200 p-4 dark:border-slate-700">
              <h3 className="mb-2 font-bold">Credit Note</h3>
              <div className="flex items-end gap-2">
                <div className="shrink grow basis-1/2">
                  <InputLabel
                    label="Available Credit Note"
                    instruction={
                      availableCreditNote.credit_note ? (
                        <span className="font-bold text-green-700">
                          Total Available - {availableCreditNote.credit_note}{" "}
                          {values.amount_used_in_credit_note
                            ? `| Remaining - ${
                                availableCreditNote.credit_note -
                                values.amount_used_in_credit_note
                              }`
                            : ""}
                        </span>
                      ) : (
                        <></>
                      )
                    }
                  />
                  <Field
                    className="field"
                    name={`amount_used_in_credit_note`}
                    value={values.amount_used_in_credit_note}
                    onChange={(e) => {
                      if (
                        Number(e.target.value) >= 0 &&
                        Number(e.target.value) <=
                          availableCreditNote.credit_note
                      ) {
                        setFieldValue(
                          "amount_used_in_credit_note",
                          e.target.value
                        );
                      }
                    }}
                  />
                  <ErrorMessage name={`amount_used_in_credit_note`}>
                    {(msg) => <div className="error-msg">{msg}</div>}
                  </ErrorMessage>
                </div>
                {!creditNoteApplied && (
                  <button
                    onClick={applyCreditNote}
                    className="rounded-lg border border-jw-green py-1 px-4 text-jw-green"
                  >
                    Apply
                  </button>
                )}
                {creditNoteApplied && (
                  <button
                    onClick={removeCreditNote}
                    className="rounded-lg border border-red-500 py-1 px-4 text-red-500"
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <Collapsible
        heading="Costing Total"
        selectedIndex={selectedIndex}
        index={5}
        setSelectedIndex={setSelectedIndex}
      >
        <div className="rounded-lg bg-white p-4 shadow-sm">
          <div className="col-span-12 col-start-1 row-span-1 row-start-1 my-4 grid grid-cols-12 items-end gap-4">
            {/* //? Travel Mode Input */}
            <div className="col-span-12 md:col-span-2">
              <InputLabel label="Total Pax" />
              <Field
                className="field"
                type="number"
                name={`total_pax`}
                disabled
              />

              <ErrorMessage name={`total_pax`}>
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="col-span-12 md:col-span-2">
              <InputLabel label="Total Cost" />
              <Field
                className="field"
                type="number"
                step="0.01"
                name={`total_cost`}
                disabled
              />

              <ErrorMessage name={`total_cost`}>
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="col-span-12 md:col-span-2">
              <InputLabel label="Total Discount" />
              <Field
                className="field"
                type="number"
                step="0.01"
                name={`total_discount`}
                disabled
              />

              <ErrorMessage name={`total_discount`}>
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="col-span-12 md:col-span-2">
              <InputLabel label="Total Cost after Discount" />
              <Field
                type="number"
                step="0.01"
                className="field"
                name={`total_cost_after_discount`}
                disabled
              />
              <ErrorMessage name={`total_cost_after_discount`}>
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="col-span-12 md:col-span-2">
              <InputLabel label="Total GST" />
              <Field
                className="field"
                type="number"
                step="0.01"
                name={`total_gst`}
                disabled
              />
              <ErrorMessage name={`total_gst`}>
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>

            <div className="col-span-12 md:col-span-2">
              <InputLabel label="Total Cost with GST" />
              <Field
                className="field"
                type="number"
                step="0.01"
                name={`total_with_gst`}
                disabled
              ></Field>
              <ErrorMessage name={`total_with_gst`}>
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="col-span-12 md:col-span-2">
              <InputLabel
                label="Total TCS"
                instruction="In case of International"
              />
              <Field
                type="number"
                step="0.01"
                name="total_tcs"
                className="field"
              />
              <ErrorMessage name={`total_tcs`}>
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="col-span-12 md:col-span-2">
              <InputLabel label="Round Off" />

              <Field
                type="number"
                step="0.01"
                name="round_off_amount"
                className="field"
                disabled
              />

              <ErrorMessage name={`round_off_amount`}>
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>

            {giftCardApplied && (
              <div className="col-span-12 md:col-span-2">
                <InputLabel label="Gift Card" />
                <Field
                  type="number"
                  step="0.01"
                  className="field"
                  value={`-${values.amount_used_in_gift_card}`}
                  name={`amount_used_in_gift_card`}
                  disabled
                />
                <ErrorMessage name={`amount_used_in_gift_card`}>
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
            )}

            {creditNoteApplied && (
              <div className="col-span-12 md:col-span-2">
                <InputLabel label="Credit Note" />
                <Field
                  type="number"
                  step="0.01"
                  className="field"
                  value={`-${values.amount_used_in_credit_note}`}
                  name={`amount_used_in_credit_note`}
                  disabled
                />
                <ErrorMessage name={`amount_used_in_credit_note`}>
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
            )}

            <div className="col-span-12 md:col-span-2">
              <InputLabel label="Amount to Pay" />
              <Field
                type="number"
                step="0.01"
                className="field"
                name={`total_amount_to_pay`}
                disabled
              />
              <ErrorMessage name={`total_amount_to_pay`}>
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="col-span-12 md:col-span-2">
              <InputLabel label="Amount Paid" required={true} />
              <Field
                type="number"
                step="0.01"
                name={`total_amount_paid`}
                className="field"
                disabled={edit}
              />
              <ErrorMessage name={`total_amount_paid`}>
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="col-span-12 md:col-span-2">
              <InputLabel label="Remaining Amount" />
              <Field
                type="number"
                step="0.01"
                name={`remaining_amount`}
                className="field"
                disabled
              />
              <ErrorMessage name={`remaining_amount`}>
                {(msg) => <div className="error-msg">{msg}</div>}
              </ErrorMessage>
            </div>
            {!proformaInvoice && (
              <div className="col-span-12 md:col-span-3">
                <InputLabel label="Payment Method" />
                <Field
                  as="select"
                  name={`payment.payment_method`}
                  className="field"
                >
                  <option value="">Select a Payment method</option>
                  <option value="neft/imps">NEFT/IMPS</option>
                  <option value="google_pay">Google Pay</option>
                  <option value="phonepe">PhonePe</option>
                  <option value="paytm">Paytm</option>
                  <option value="payu_money">PayU Money</option>
                  <option value="bhim_upi">BHIM UPI</option>
                  <option value="EMI-sankash">EMI Sankash</option>
                  <option value="razorpay">Razorpay</option>
                  <option value="Others">Others</option>
                  <option value="cash">Cash</option>
                </Field>
                <ErrorMessage name={`payment.payment_method`}>
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
            )}
            {!proformaInvoice && (
              <div className="col-span-12 md:col-span-3">
                <InputLabel label="Transaction Id" />
                <Field name={`payment.transaction_id`} className="field" />
                <ErrorMessage name={`payment.transaction_id`}>
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
            )}
          </div>
        </div>
      </Collapsible>
    </>
  );
}

export default memo(CostingTotalForm);
