import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
// import * as Yup from "yup";
import InputLabel from "../inputs/InputLabel";
import { useEffect, useState } from "react";
import $ from "jquery";

import {
  getAllCategories,
  getCategoryPackages,
} from "../../services/CategoryServices";
import {
  getAllItinerariesByPackageId,
  getCategoryPackagesByCountryOrState,
  getPackagesByCountry,
  getPackagesByState,
} from "../../services/PackageService";
import { MdClose } from "react-icons/md";
import { FaPlusSquare } from "react-icons/fa";
import DateRangeInput from "../inputs/DateRangeInput";
import DataTable from "../common/DataTable";
import { getAdminUrl } from "../../../ServiceConfig";
import { useRef } from "react";
import { createBatches, deleteBatch } from "../../services/EventDatesServices";
import { monthShortNames } from "../../../helpers/Helper";
import { toast } from "react-toastify";
import Modal from "react-modal";
import UpdateEventDate from "./UpdateEventDate";
import SelectSearch from "../inputs/SelectSearch";
import { getAllCountriesOption } from "../../services/CountryServices";
import { getAllStateByCountry } from "../../services/StateServices";
import BatchPriceEdit from "./BatchPriceEdit";

function EventDatesForm() {
  // const batchSchema = Yup.object().shape({
  //   batches: Yup.array()
  //     .of(
  //       Yup.object().shape({
  //         startdate: Yup.date().required("Start date is required"),
  //         endDate: Yup.date().required("End date is required"),
  //         availability: Yup.string().required("Availability is required"),
  //         total_slots: Yup.number().required("slots is required"),
  //       })
  //     )
  //     .required("required"),
  // });

  const batchTable = useRef();
  const dateInputRef = useRef();

  const [categories, setCategories] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [categoryPackages, setCategoryPackages] = useState();
  const [selectedPackage, setSelectedPackage] = useState();
  const [selectedItinerary, setSelectedItinerary] = useState();
  const [itineraries, setItineraries] = useState();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedBatchForPriceUpdate, setSelectedBatchForPriceUpdate] = useState()
  const [selectedbatchPriceExist, setSelectedBatchPriceExist] = useState(false)

  const [isPriceEditorOpen, setIsPriceEditorOpen] = useState(false)
  const [isBatchEditorOpen, setIsBatchEditorOpen] = useState(false);

  const [batchToEdit, setBatchToEdit] = useState();

  const getAllCat = () => {
    getAllCategories()
      .then((res) => {
        setCategories(res.data.data.data);
      })
      .catch((er) => {
        console.log(er);
      });
  };

  const getAllCountries = () => {
    getAllCountriesOption()
      .then((res) => {
        setCountries(res.data.data);
        // this is for default India
        setSelectedCountry(101);
      })
      .catch((er) => {
        console.log(er);
      });
  };

  const InitialRunningFunction = () => {
    getAllCat();
    getAllCountries();
  };

  useEffect(() => {
    InitialRunningFunction();
  }, []);

  const getStatesByCountry = (id) => {
    getAllStateByCountry(id)
      .then((res) => {
        setStates(res.data.data);
      })
      .catch((er) => {
        console.log(er);
      });
  };

  useEffect(() => {
    if (selectedCountry) {
      setSelectedState();
      if (selectedCountry === 101) {
        getStatesByCountry(101);
      }
      else {
        getPackagesByState(selectedCategory, selectedCountry)
      }
    }
  }, [selectedCountry]);

  const getPackagesByState = (catId, cntId, stId) => {
    getCategoryPackagesByCountryOrState({
      category_id: catId,
      country_id: cntId,
      state_id: stId,
    })
      .then((res) => {
        setCategoryPackages(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setSelectedPackage();
    setSelectedItinerary();
    if (selectedCategory && selectedCountry && selectedState) {
      getPackagesByState(selectedCategory, selectedCountry, selectedState);
    }
  }, [selectedCategory, selectedCountry, selectedState]);

  const getPackageItineraries = (packageId) => {
    getAllItinerariesByPackageId(packageId)
      .then((res) => {
        if (res.data.data[0]?.id) {
          setSelectedItinerary(res.data.data[0]?.id);
        }
        setItineraries(res.data.data);
      })
      .catch((er) => {
        console.log(er);
      });
  };

  useEffect(() => {
    setSelectedItinerary();
    if (selectedPackage) {
      getPackageItineraries(selectedPackage);
    }
  }, [selectedPackage]);

  const reloadData = () => {
    batchTable?.current?.reloadTable(
      `${getAdminUrl()}/itinerary/${selectedItinerary}/batches`
    );
  };

  const addBatches = (values) => {
    values.batches.forEach((batch) => {
      batch.package_id = selectedPackage;
      batch.package_itinerary_id = selectedItinerary;
    });

    createBatches(values)
      .then((res) => {
        reloadData();
        dateInputRef.current.resetDates();
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleBatchUpdate = () => {
    batchTable.current.updateTable();
    setBatchToEdit(null);
    setIsBatchEditorOpen(false);
  };

  const handleBatchUpdatePricesModal = () => {
    batchTable.current.updateTable();
    setBatchToEdit(null);
    setIsPriceEditorOpen(false);
  };

  const deleteSelectedBatch = (id) => {
    deleteBatch(id).then((res) => {
      batchTable.current.deleteRow(id);
    });
  };

  const editSelectedBatch = (data) => {
    setBatchToEdit(data);
    setIsBatchEditorOpen(true);
  };

  // Function used for opening Modal
  function cellClick(cell) {
    if (cell.getColumn().getDefinition().title === "Batch prices") {
      setSelectedBatchForPriceUpdate(cell.getRow().getData().id)
      setIsPriceEditorOpen(true)
      setSelectedBatchPriceExist(cell.getRow().getData().has_batch_price)
    }
  }

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
  <div class="flex items-center gap-3">
      <div class="batch-edit-btn cursor-pointer" data-batch=${JSON.stringify(
      cell.getRow().getData()
    )}>
          <i class="fas fa-edit"></i>
      </div>
      <div class="batch-delete-btn cursor-pointer text-red-500" data-id=${cell.getRow().getData().id
      }>
        <i class="fas fa-trash-alt"></i>
      </div>
  </div>
  `;
  };

  const columns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Start Date",
      field: "startdate",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
      formatter: function (cell, formatterParams, onRendered) {
        return `${new Date(cell.getValue()).getDate()} ${monthShortNames[new Date(cell.getValue()).getMonth()]
          }, ${new Date(cell.getValue()).getFullYear()}`;
      },
    },
    {
      title: "End Date",
      minWidth: "100",
      field: "lastdate",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return `${new Date(cell.getValue()).getDate()} ${monthShortNames[new Date(cell.getValue()).getMonth()]
          }, ${new Date(cell.getValue()).getFullYear()}`;
      },
    },
    {
      title: "Availability",
      field: "availability",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        if (cell.getValue() === 0) {
          return "Available";
        } else if (cell.getValue() === 2) {
          return "Filling Fast";
        } else if (cell.getValue() === 1) {
          return "Full";
        }
      },
    },
    {
      title: "Total Slots",
      field: "total_slots",
      resizable: true,
      vertAlign: "middle",
    },

    {
      title: "Booked Slots",
      field: "booked_slots",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Batch prices",
      resizable: true,
      vertAlign: "middle",
      responsive: 0,
      hozAlign: "center",
      formatter: function (cell, formatterParams, onRendered) {
        return ` <button style="
       
        
        padding: 7px 15px; 
        
        border-radius: 5px; 
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
        cursor: pointer; 
        font-size: 15px;
        ${cell.getData().has_batch_price ? " background-color: #0183c0; color: #fff;  border: 1px solid #0183c0; " : " background-color: #fff; color: #077822;   border: 1px solid #077822;"}
    "
    >
      ${cell.getData().has_batch_price ? "View" : "Add"}
    </button>`
      },
    },
    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  useEffect(() => {
    $(document).on("click", ".batch-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let dataId = $(this).attr("data-id");
      if (!window.confirm("Do You really want to delete this batch?")) return;
      deleteSelectedBatch(dataId);
    });

    $(document).on("click", ".batch-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let data = JSON.parse($(this).attr("data-batch"));
      editSelectedBatch(data);
    });
    return () => {
      $(document).off("click", ".batch-delete-btn");
      $(document).off("click", ".batch-edit-btn");
    };
  }, []);

  return (
    <div className="my-4">
      <h2 className="text-lg font-bold">Package Event Dates</h2>
      <div>
        <div className="">
          <InputLabel label="Select Category" />
          <SelectSearch
            onChange={(e) => {
              setSelectedCategory(e);
            }}
            value={selectedCategory}
            options={categories?.map((category) => ({
              label: category.name,
              value: category.id,
            }))}
          />
        </div>
        <div className="my-4 flex flex-wrap gap-4 lg:flex-nowrap">
          <div className="min-w-[25%] grow">
            <InputLabel label="Select Country" required={true} />
            <SelectSearch
              options={countries.map((country) => ({
                value: country.id,
                label: country.name,
              }))}
              value={selectedCountry}
              onChange={(value) => {
                setSelectedCountry(value);
              }}
            />
          </div>
          {selectedCountry === 101 && (
            <div className="min-w-[25%] grow">
              <InputLabel
                label="Select State"
                required={selectedCountry === 101}
              />
              <SelectSearch
                options={states.map((state) => ({
                  value: state.id,
                  label: state.name,
                }))}
                value={selectedState}
                onChange={(value) => {
                  setSelectedState(value);
                }}
              />
            </div>
          )}
        </div>
        <div className=" mt-4 flex flex-wrap gap-4">
          <div className="grow lg:basis-[45%]">
            <InputLabel label="Select Package" />
            <SelectSearch
              onChange={(e) => {
                setSelectedPackage(e);
              }}
              disabled={!selectedCategory}
              value={selectedPackage}
              options={categoryPackages?.map((categoryPackage) => ({
                label: categoryPackage.title,
                value: categoryPackage.id,
              }))}
            />
          </div>
          <div className="grow lg:basis-[45%]">
            <InputLabel label="Select Itinerary" />
            <SelectSearch
              onChange={(e) => {
                setSelectedItinerary(e);
              }}
              disabled={!selectedPackage}
              value={selectedItinerary}
              options={itineraries?.map((itinerary) => ({
                label: `${itinerary.starting_location_name} - ${itinerary.ending_location_name}`,
                value: itinerary.id,
              }))}
            />
          </div>
        </div>
      </div>
      {/* {selectedCategory} */}
      {selectedItinerary && selectedPackage && (
        <div>
          <Formik
            enableReinitialize
            initialValues={{
              batches: [
                {
                  startdate: "",
                  lastdate: "",
                  availability: "0",
                  total_slots: 12,
                },
              ],
            }}
            // validationSchema={batchSchema}
            onSubmit={(values, { resetForm }) => {
              // alert(JSON.stringify(values, null, 2));
              addBatches(values);
              resetForm({
                values: {
                  batches: [
                    {
                      startdate: "",
                      lastdate: "",
                      availability: "0",
                      total_slots: 12,
                    },
                  ],
                },
              });
            }}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <Form>
                <h4 className="mt-8 text-lg font-semibold">Add Batches</h4>
                <div className="mb-2 rounded-lg border-2 p-4">
                  <FieldArray name={`batches`}>
                    {({ insert, remove, push }) => (
                      <div className="relative">
                        {values.batches.length > 0 &&
                          values.batches.map((rider, index) => (
                            <div
                              className="relative mb-2 rounded-lg"
                              key={index}
                            >
                              {itineraries && (
                                <div className="flex flex-wrap gap-4">
                                  <div className="grow basis-full lg:basis-[30%]">
                                    <InputLabel label="Select Batch" />
                                    <DateRangeInput
                                      ref={dateInputRef}
                                      onRangeChange={(startDate, endDate) => {
                                        setFieldValue(
                                          `batches.${index}.startdate`,
                                          `${new Date(
                                            startDate
                                          ).getFullYear()}-${new Date(startDate).getMonth() + 1
                                          }-${new Date(startDate).getDate()}`
                                        );
                                        setFieldValue(
                                          `batches.${index}.lastdate`,
                                          `${new Date(endDate).getFullYear()}-${new Date(endDate).getMonth() + 1
                                          }-${new Date(endDate).getDate()}`
                                        );
                                      }}
                                      dayToAdd={
                                        itineraries[0]?.no_of_days -
                                        1 -
                                        (selectedCategory == 3 ? 1 : 0) //for weekend getaways only
                                      }
                                    />
                                    <ErrorMessage
                                      name={`batches.${index}.startdate`}
                                    >
                                      {(msg) => (
                                        <div className="error-msg">{msg}</div>
                                      )}
                                    </ErrorMessage>
                                    <ErrorMessage
                                      name={`batches.${index}.lastdate`}
                                    >
                                      {(msg) => (
                                        <div className="error-msg">{msg}</div>
                                      )}
                                    </ErrorMessage>
                                  </div>

                                  <div className="grow basis-full lg:basis-[30%]">
                                    <InputLabel label="Select Availability" />

                                    <Field
                                      as="select"
                                      name={`batches.${index}.availability`}
                                      className="block w-full rounded-md border-2 py-1 px-2"
                                    >
                                      <option>Select Availability</option>
                                      <option value="0">Available</option>
                                      <option value="2">Fast Filling</option>
                                      <option value="1">Full</option>
                                    </Field>

                                    <ErrorMessage
                                      name={`batches.${index}.availability`}
                                    >
                                      {(msg) => (
                                        <div className="error-msg">{msg}</div>
                                      )}
                                    </ErrorMessage>
                                  </div>

                                  <div className="grow basis-full lg:basis-[30%]">
                                    <InputLabel label="Enter Available Slots" />
                                    <Field
                                      name={`batches.${index}.total_slots`}
                                      className="block w-full rounded-md border-2 py-1 px-2"
                                    />

                                    <ErrorMessage
                                      name={`batches.${index}.total_slots`}
                                    >
                                      {(msg) => (
                                        <div className="error-msg">{msg}</div>
                                      )}
                                    </ErrorMessage>
                                  </div>

                                  <div
                                    onClick={() => remove(index)}
                                    className="self-end rounded-full border-2 p-1"
                                  >
                                    <MdClose />
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        <button
                          type="button"
                          className="my-2 flex items-center gap-1 text-jw-green"
                          onClick={() =>
                            push({
                              startdate: "",
                              lastdate: "",
                              availability: "2",
                              total_slots: "12",
                            })
                          }
                        >
                          <FaPlusSquare /> Batch
                        </button>
                      </div>
                    )}
                  </FieldArray>

                  {/* //? Status */}
                </div>

                <div className="text-right">
                  <button
                    type="submit"
                    className="btn-primary inline-flex items-center"
                  >
                    <FaPlusSquare /> Add
                  </button>
                </div>
              </Form>
            )}
          </Formik>

          <div className="mt-4">
            <h4 className="text-lg font-semibold">Available Batches</h4>
            <div>
              <DataTable
                ref={batchTable}
                ajaxUrl={`${getAdminUrl()}/itinerary/${selectedItinerary}/batches`}
                columns={columns}
                cellClick={cellClick}
                search={false}
                responsiveLayout="collapse"
              />
            </div>
          </div>
        </div>
      )}

      {/* Modal for batch */}
      <Modal
        isOpen={isBatchEditorOpen}
        onRequestClose={() => setIsBatchEditorOpen(false)}
        contentLabel="batch editor modal"
        className="absolute left-1/2 top-16 z-10 w-fit min-w-[350px] max-w-[1200px] -translate-x-1/2 rounded-lg bg-white lg:min-w-[500px]"
      >
        <div
          className="absolute right-4 top-4 z-10 rounded-full border bg-white p-2"
          onClick={() => setIsBatchEditorOpen(false)}
        >
          <MdClose />
        </div>
        <div className="p-4">
          <UpdateEventDate batch={batchToEdit} onUpdate={handleBatchUpdate} />
        </div>
      </Modal>

      {/* Modal for Price */}
      <Modal
        isOpen={isPriceEditorOpen}
        onRequestClose={() => setIsPriceEditorOpen(false)}
        contentLabel="price editor modal"
        className="absolute left-1/2 top-16 z-10 w-fit min-w-[350px] lg:max-w-[1200px] max-w-full -translate-x-1/2 rounded-lg bg-white lg:min-w-[1000px]"
      >
        <div
          className="absolute right-4 top-4 z-10 rounded-full border bg-white p-2 cursor-pointer mb-6"
          onClick={() => setIsPriceEditorOpen(false)}
        >
          <MdClose />
        </div>
        <div className="p-4">
          <BatchPriceEdit selectedItinerary={selectedItinerary} setIsPriceEditorOpen={setIsPriceEditorOpen} batchId={selectedBatchForPriceUpdate} onUpdate={handleBatchUpdatePricesModal} priceExist={selectedbatchPriceExist} />
        </div>
      </Modal>
    </div>
  );
}

export default EventDatesForm;
