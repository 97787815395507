import React from "react";
import { toast } from "react-toastify";
import InputLabel from "../../components/inputs/InputLabel";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { getUploadsUrl } from "../../../ServiceConfig";
import { addImportantDocument, editImportantDocument, uploadImportantDocumentFile } from "../../services/ImportantDocumentService";
import ToggleInput from "../inputs/ToggleInput";

const importantDocumentSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  file_name: Yup.string().required("Required"),
});

function ImportantDocumentForm({ formData, edit }) {
  const naviagte = useNavigate()

  const addNewImportantDocument = (values) => {
    addImportantDocument(values)
      .then((res) => {
        toast.success(res.data.message);
        naviagte('/important-documnets')
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err, "error in adding")
      });
  };

  const updateImportantDocument = (values) => {
    let data = Object.assign({}, values);
    editImportantDocument(formData.id, data)
      .then((res) => {
        toast.success(res.data.message);
        naviagte('/important-documnets')
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          title: formData?.title ? formData.title : "",
          file_name: formData?.file_name ? formData.file_name : "",
          status: formData?.status ? formData.status : false,
        }}
        validationSchema={importantDocumentSchema}
        onSubmit={(values) => {
          console.log(values, "real")
          edit ? updateImportantDocument(values) : addNewImportantDocument(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="flex flex-col">

              <div className="my-4 grow">
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-6 gap-y-4">
                  <div className="">
                    <InputLabel label="Title" labelFor="title" />
                    <Field
                      className="field"
                      id="title"
                      name="title"
                    />
                    <ErrorMessage name="title">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="">
                    <InputLabel label="Status" labelFor="status" />
                    <ToggleInput
                      checked={values?.status}
                      onToggle={(e) => setFieldValue('status', e.target.checked ? 1 : 0)}
                    />
                    <ErrorMessage name="title">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="">
                    <InputLabel label="Document File" labelFor="file_name" />

                    {values?.file_name && (
                      <a
                        target="_blank"
                        href={`${getUploadsUrl()}/important_document/${values.file_name
                          }`}
                      >
                        <p className="font-semibold text-blue-500">
                          {" "}
                          {values.file_name}
                        </p>
                      </a>
                    )}
                    <input
                      type="file"
                      className="block text-sm text-gray-500
                     file:mr-4 file:rounded-full file:border-0
                     file:bg-violet-50 file:py-2
                     file:px-4 file:text-sm
                     file:font-semibold file:text-violet-700
                     hover:file:bg-violet-100"
                      accept="application/pdf"
                      onChange={(event) => {
                        uploadImportantDocumentFile({
                          pdf: event.target.files[0],
                        })
                          .then((res) => {
                            setFieldValue(
                              "file_name",
                              res.data.data
                            );
                          })
                          .catch((er) => {
                            console.log(er);
                          });
                      }}
                    />

                    <ErrorMessage name="file_name">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-10 text-center">
              <button type="submit" className="btn-primary dark:bg-green-700">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ImportantDocumentForm;
