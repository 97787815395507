
import { FaListAlt } from "react-icons/fa";
import ImportantDocumentForm from "./ImportantDocumentForm";
import ComponentHeader from "../common/ComponentHeader";
import H2 from "../../../components/headings/H2";
import { Link } from "react-router-dom";
import NotAuthorized403 from "../NotAuthorized403";
import useAdminAuth from "../../hooks/useAdminAuth";

function AddImportantDocuments() {
  const { auth } = useAdminAuth();
  return <> {(auth?.permissions?.includes("documents.add") ||
    auth?.roles?.includes("Superadmin")) ? <div className="">
    <ComponentHeader className="mb-4 border-b">
      <H2>Add Important Document</H2>
      <Link
        to="/important-documnets"
        className="btn-primary flex dark:bg-green-800 items-center gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
      >
        <FaListAlt /> View All
      </Link>
    </ComponentHeader>
    <ImportantDocumentForm />
  </div> : <NotAuthorized403 />}
  </>
}

export default AddImportantDocuments;
