import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import $ from "jquery";
import DataTable from "../components/common/DataTable";
import { getAdminUrl } from "../../ServiceConfig";
import ComponentHeader from "../components/common/ComponentHeader";
import H2 from "../../components/headings/H2";
import {
  FaCheckSquare,
  FaCog,
  FaDownload,
  FaFileCsv,
  FaFileExcel,
  FaFilePdf,
  FaFilter,
  FaPlusSquare,
  FaTimes,
} from "react-icons/fa";
import { getReadableDate, getReadableDateAndTime } from "../../helpers/Helper";
import ToggleInput from "../components/inputs/ToggleInput";
import { toast } from "react-toastify";
import { checkPermission } from "../services/AdminServices";
import NotAuthorized403 from "../components/NotAuthorized403";
import InputLabel from "../components/inputs/InputLabel";
import DateRangeInput from "../components/inputs/DateRangeInput";
import SelectSearch from "../components/inputs/SelectSearch";
import { getAllEmployeesOption } from "../services/EmployeeServices";
import OutsideClick from "../../hooks/OutsideClick";
import moment from "moment";
import { getAllCategories } from "../services/CategoryServices";
import useAdminAuth from "../hooks/useAdminAuth";

function BookingList() {
  const navigate = useNavigate();
  const { auth } = useAdminAuth();
  const bookingDateRef = useRef(null);
  const eventDateRef = useRef(null);
  const bookingTable = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const convertedBy = searchParams.get("converted_by");
  const bookingStartDate = searchParams.get("booking_start_date");
  const bookingEndDate = searchParams.get("booking_end_date");
  const [seeAllFilters, setSeeAllFilters] = useState(false);

  const [onlyCustomized, setOnlyCustomized] = useState(false);
  const [authorized, setAuthorized] = useState(true);

  // State to hold the selected option
  const [selectedOption, setSelectedOption] = useState();

  const [selectedFilter, setSelectedFilter] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);

  const [columnVisibilityOpen, setColumnVisibilityOpen] = useState(false);
  const [downloadOptionOpen, setDownloadOptionOpen] = useState(false);
  const [renderedColumns, setRenderedColumns] = useState();
  const filterRef1 = useRef(null);

  const [employees, setEmployees] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getAllEmployeesOption()
      .then((res) => {
        setEmployees(res.data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getAllCategories()
      .then((res) => {
        setCategories(res.data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    bookingTable.current.setFilter(selectedFilter);
  }, [selectedFilter]);

  // Function to handle option selection

  useEffect(() => {
    if (selectedOption === "1") {
      updateFilter({
        field: "is_customized",
        type: "=",
        value: null,
      });
    }
    if (selectedOption === "2") {
      updateFilter({
        field: "is_customized",
        type: "=",
        value: "1",
      });
    }
    if (selectedOption === "3") {
      updateFilter({
        field: "is_customized",
        type: "=",
        value: "0",
      });
    }
  }, [selectedOption]);

  useEffect(() => {
    checkPermission("Booking.read")
      .then((res) => {
        setAuthorized(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }, []);

  // useEffect(() => {
  //   onlyCustomized &&
  //     bookingTable.current.setFilter([
  //       {
  //         field: "is_customized",
  //         type: "=",
  //         value: 1,
  //       },
  //     ]);
  // }, [onlyCustomized]);

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `<div className="booking-view-btn" data-id=${
      cell.getRow().getData().id
    } style="display: flex; align-items: center; gap: 12px;">
    <div class="">
    <i class="fa fa-eye"></i>
    </div>
   `;
  };

  const isFrozen = window.innerWidth <= 768 ? false : true;
  const onRowClick = (data) => {
    // navigate(`/bookings/${data.id}`);
    window.open(`/bookings/${data.id}`, "_blank");
  };

  // Custom Formatter Function
  function inrFormatter(cell, formatterParams, onRendered) {
    // Get the value from the cell
    var value = cell.getValue();

    // Format the value with INR sign and two decimal places
    var formattedValue = `₹${parseFloat(value).toFixed(2)}`;

    return formattedValue;
  }

  const columns = [
    // {
    //   formatter: "",
    //   width: 30,
    //   minWidth: 30,
    //   responsive: 0,
    //   hozAlign: "center",
    //   resizable: false,
    //   headerSort: false,
    // },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      minWidth: "80px",
      frozen: isFrozen,
    },
    {
      title: "Booking Date Time",
      minWidth: "170",
      field: "created_at",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: function (cell, formatterParams, onRendered) {
        return `<div class="whitespace-normal overflow-visible">${moment(
          cell.getValue()
        ).format("lll")}</div>`;
      },
      visible: true,
      download: true,
    },
    {
      title: "Payment Ids",
      field: "payment_ids",
      responsive: 0,
      width: "120",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      minWidth: "100px",
      visible: true,
      download: false,
      formatter: function (cell, formatterParams, onRendered) {
        const users = cell.getValue();

        // Create an array of chips for each user
        const chips = users.map((user) => {
          return `
        
        <div
    class="relative grid select-none items-center rounded-full border  py-1.5 px-3 font-sans text-xs font-bold">
    <span class=""> ${user}</span>
  </div>
      `;
        });

        // Join the chips and return as HTML
        return `<div class="flex flex-wrap gap-2">${chips.join("")}</div>`;
      },
    },

    {
      title: "User Detail",
      minWidth: "170",
      field: "first_name",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return `<div class="whitespace-normal overflow-visible">
        ${cell.getRow().getData().first_name} ${
          cell.getRow().getData().last_name
        } <br/> ${cell.getRow().getData().email} <br/> +${
          cell.getRow().getData().country_code
        } - ${cell.getRow().getData().phone}
        </div>`;
      },
      visible: true,
      download: false,
    },
    {
      title: "First Name",
      minWidth: "250",
      field: "first_name",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      visible: false,
      download: true,
    },
    {
      title: "Last Name",
      minWidth: "250",
      field: "last_name",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      visible: false,
      download: true,
    },
    {
      title: "email",
      minWidth: "250",
      field: "email",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      visible: false,
      download: true,
    },

    {
      title: "Country Code",
      minWidth: "250",
      field: "country_code",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      visible: false,
      download: true,
    },
    {
      title: "Phone",
      minWidth: "250",
      field: "phone",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      visible: false,
      download: true,
    },

    {
      title: "Package Detail",
      field: "package_name",
      minWidth: "140",
      vertAlign: "middle",
      hozAlign: "justify",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
      formatter: function (cell, formatterParams, onRendered) {
        return `<div class="whitespace-normal overflow-visible">
        ${cell.getRow().getData().package_name}</br>
      ${getReadableDate(cell.getRow().getData().event_date)}</br>${
          cell.getRow().getData().total_pax
        } ${
          cell.getRow().getData().total_pax > 1 ? "Members" : "Member"
        }</div>`;
      },
      visible: true,
      download: false,
    },
    {
      title: "Package Name",
      field: "package_name",
      minWidth: "250",
      vertAlign: "middle",
      hozAlign: "center",
      responsive: 0,
      visible: false,
      download: true,
    },
    {
      title: "Event Date",
      minWidth: "100",
      field: "event_date",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: function (cell, formatterParams, onRendered) {
        return getReadableDate(cell.getValue());
      },
      visible: false,
      download: true,
    },
    {
      title: "Total Pax",
      minWidth: "100",
      field: "total_pax",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      visible: false,
      download: true,
    },

    {
      title: "Total Cost",
      field: "total_cost",
      resizable: true,
      vertAlign: "middle",
      minWidth: "130",
      hozAlign: "center",
      formatter: inrFormatter,
    },
    {
      title: "Total Discount",
      field: "total_discount",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      minWidth: "120px",
      formatter: inrFormatter,
    },
    {
      title: "After Discount",
      field: "total_cost_after_discount",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      minWidth: "120px",
      formatter: inrFormatter,
    },
    {
      title: "Total GST",
      field: "total_gst",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      minWidth: "120px",
      formatter: inrFormatter,
    },
    {
      title: "TCS",
      field: "total_tcs",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      minWidth: "80px",
      formatter: inrFormatter,
    },
    {
      title: "Total Amount to Pay",
      field: "total_amount_to_pay",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      minWidth: "180px",
      formatter: inrFormatter,
    },
    {
      title: "Amount Paid",
      field: "total_amount_paid",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      hozAlign: "center",
      minWidth: "120px",
      formatter: inrFormatter,
    },
    {
      title: "Cancelled",
      field: "cancellation_type",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      hozAlign: "center",
      minWidth: "120px",
      formatter: function (cell, formatterParams, onRendered) {
        if (cell.getValue() == 1) {
          return `Fully Cancelled <br/> ${
            cell.getRow().getData().cancelled_at
          }`;
        }
      },
    },
    {
      title: "Amount Remaining",
      field: "remaining_amount",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      hozAlign: "center",
      minWidth: "120px",
      frozen: isFrozen,
      formatter: inrFormatter,
    },
    {
      title: "Converted By",
      field: "converted_by_employee_name",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      minWidth: "120px",

      frozen: isFrozen,
    },
    {
      title: "Invoices",
      field: "invoices",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      minWidth: "120px",
      // frozen: isFrozen,
    },
    // {
    //   formatter: actionIcons,
    //   headerSort: false,
    //   width: 80,
    //   responsive: 0,
    //   hozAlign: "center",
    //   vertAlign: "middle",
    //   frozen: isFrozen,
    // },
  ];

  const rowFormatter = (row) => {
    if (row.getData().is_customized === 1) {
      // const children = row.getElement().childNodes;
      // children.forEach((child) => {
      // child.style.color = "#3876BF";
      // });
      row.getElement().classList.add("customized-booking-rows");
    }
    if (row.getData().booking_status === 0) {
      const children = row.getElement().childNodes;
      children.forEach((child) => {
        child.style.color = "#9E6F21";
      });
    }
    if (row.getData().cancellation_type == 1) {
      const children = row.getElement().childNodes;
      children.forEach((child) => {
        child.style.color = "#D83F31";
      });
    }
  };

  const updateFilter = (filter) => {
    let index = selectedFilter.findIndex((sf) => sf.field === filter.field);

    if (index >= 0) {
      if (filter.value && !Array.isArray(filter.value)) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else if (Array.isArray(filter.value) && filter.value[0]) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else {
        setSelectedFilter(
          selectedFilter.filter((af) => af.field !== filter.field)
        );
      }
    } else {
      setSelectedFilter([...selectedFilter, filter]);
    }
  };

  useEffect(() => {
    $(document).on("click", ".booking-view-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/bookings/${cell}`);
    });

    return () => {
      $(document).off("click", ".booking-view-btn");
    };
  }, []);

  return !authorized ? (
    <NotAuthorized403 />
  ) : (
    <div className="flex flex-col">
      <ComponentHeader className="border-b">
        <div className="flex flex-wrap items-center gap-20">
          <H2>Bookings</H2>

          <div className="hidden items-center lg:flex">
            {/* <label className="inline-flex items-center">
<input
type="radio"
className="form-radio"
name="booking_type"
value=""
checked={selectedOption === ""}
onChange={handleBookingTypeChange}
/>
<span className="ml-2">All</span>
</label>
<label className="ml-6 inline-flex items-center">
<input
type="radio"
className="form-radio"
name="booking_type"
value="1"
checked={selectedOption === "1"}
onChange={handleBookingTypeChange}
/>
<span className="ml-2">Customized Only</span>
</label>
<label className="ml-6 inline-flex items-center">
<input
type="radio"
className="form-radio"
name="booking_type"
value="0"
checked={selectedOption === "0"}
onChange={handleBookingTypeChange}
/>
<span className="ml-2">FD only</span>
</label> */}

            <button
              className={`w-auto rounded-l-lg px-4 py-2 text-sm font-semibold transition duration-300 ${
                !selectedOption || selectedOption === "1"
                  ? "bg-[#0183c0] text-white shadow"
                  : "bg-[#D7F2FD] text-black"
              }`}
              onClick={() => setSelectedOption("1")}
            >
              All
            </button>
            <button
              className={`w-auto border-x-2 border-[#0183c0] px-4 py-2 text-sm font-semibold transition duration-300 ${
                selectedOption === "2"
                  ? "bg-[#0183c0] text-white shadow"
                  : "bg-[#D7F2FD] text-black"
              }`}
              onClick={() => setSelectedOption("2")}
            >
              Customized Only
            </button>
            <button
              className={`w-auto rounded-r-lg px-4 py-2 text-sm font-semibold transition duration-300 ${
                selectedOption === "3"
                  ? "bg-[#0183c0] text-white shadow"
                  : "bg-[#D7F2FD] text-black"
              }`}
              onClick={() => setSelectedOption("3")}
            >
              FD Only
            </button>
          </div>
        </div>
        <Link
          to="/bookings/add"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaPlusSquare /> Add New
        </Link>
      </ComponentHeader>

      <div className="my-2 flex items-center lg:hidden">
        <button
          className={`w-auto  rounded-l-lg py-2 px-4 text-[0.8rem] font-semibold transition duration-300 ${
            !selectedOption || selectedOption === "1"
              ? "bg-[#0183c0] text-white shadow"
              : "bg-[#D7F2FD] text-black"
          }`}
          onClick={() => setSelectedOption("1")}
        >
          All
        </button>
        <button
          className={`w-auto  border-x-2 border-[#0183c0] py-2 px-4 text-[0.8rem] font-semibold transition duration-300 ${
            selectedOption === "2"
              ? "bg-[#0183c0] text-white shadow"
              : "bg-[#D7F2FD] text-black"
          }`}
          onClick={() => setSelectedOption("2")}
        >
          Customized Only
        </button>
        <button
          className={`w-auto  rounded-r-lg py-2 px-4 text-[0.8rem] font-semibold transition duration-300 ${
            selectedOption === "3"
              ? "bg-[#0183c0] text-white shadow"
              : "bg-[#D7F2FD] text-black"
          }`}
          onClick={() => setSelectedOption("3")}
        >
          FD Only
        </button>
      </div>

      <div className="flex gap-10">
        <div className="mt-2 flex items-center gap-4">
          <div className="flex items-center">
            <div className="h-2 w-2 rounded-full bg-[#D83F31]"></div>
            <p className="rounded-full px-1 text-[0.625rem] font-medium leading-3 text-[#D83F31]">
              Cancelled
            </p>
          </div>
          <div className="flex items-center">
            <div className="h-2 w-2 rounded-full bg-blue-600 dark:bg-green-600"></div>
            <p className="rounded-full px-1 text-[0.625rem] font-medium leading-3  !text-blue-600 dark:!text-green-600">
              Customized Booking
            </p>
          </div>
          <div className="flex items-center">
            <div className="h-2 w-2 rounded-full bg-[#9E6F21]"></div>
            <p className="rounded-full px-1 text-[0.625rem] font-medium leading-3 text-[#9E6F21]">
              Pending
            </p>
          </div>
        </div>
      </div>
      <div className=" h-full">
        <div
          ref={filterRef1}
          // onClick={() => setFilterOpen(!filterOpen)}
          className=" mr-1 inline-block pt-4 lg:mr-2 "
        >
          <button
            onClick={() => setFilterOpen(!filterOpen)}
            className={`flex items-center gap-1 whitespace-nowrap rounded-md border bg-white p-2 py-2  text-xs font-semibold tracking-wide dark:bg-slate-800 lg:p-2 lg:text-lg ${
              filterOpen
                ? "-mb-[1px] rounded-b-none border-b-0 px-2 pb-4 pt-2 lg:px-8"
                : ""
            }`}
          >
            <FaFilter />
            Filters
          </button>
        </div>
        <div className="relative inline-block">
          <OutsideClick onClick={() => setColumnVisibilityOpen(false)}>
            <button
              onClick={() => {
                setColumnVisibilityOpen(!columnVisibilityOpen);
                setRenderedColumns(bookingTable.current.getColumns());
              }}
              className={`mr-1 flex items-center gap-1 rounded-md  border bg-white p-2 py-2 text-xs  font-semibold tracking-wide dark:bg-slate-800 lg:mr-2 lg:p-2 lg:text-lg `}
            >
              <FaCog /> Column Visibility
            </button>

            <div
              className={`${
                columnVisibilityOpen ? "" : "hidden"
              } absolute left-0 top-full  z-40 mt-2 w-full rounded-md border bg-white  py-0 dark:bg-slate-800`}
            >
              <ul className="lg:text-md flex max-h-[300px] flex-col overflow-auto text-xs">
                {renderedColumns &&
                  renderedColumns.map((column, key) => (
                    <div key={key}>
                      {column._column.definition.title && (
                        <label htmlFor={`id${key}`} className={`m-0 !h-0 p-0`}>
                          <li
                            className={`${
                              column.isVisible()
                                ? "bg-white text-gray-900 dark:bg-slate-800 dark:text-white"
                                : "bg-gray-200 text-gray-900 dark:bg-slate-700 dark:text-white"
                            } text-md cursor-pointer items-center border-y border-slate-100 py-2 px-4 text-justify  align-middle font-bold`}
                          >
                            <div className="flex items-center justify-between">
                              <p className="inline-block">
                                {column._column.definition.title}
                              </p>
                              <input
                                id={`id${key}`}
                                type="checkbox"
                                value="1"
                                checked={column.isVisible()}
                                onChange={(e) => {
                                  column.toggle();
                                  setRenderedColumns(
                                    bookingTable.current.getColumns()
                                  );
                                }}
                                className="hidden"
                              />
                              <div className="right-1 inline-block">
                                {column.isVisible() ? (
                                  <div className="text-green-700">
                                    <FaCheckSquare />
                                  </div>
                                ) : (
                                  <div className="text-red-700">
                                    <FaTimes />
                                  </div>
                                )}
                              </div>
                            </div>
                          </li>
                        </label>
                      )}
                    </div>
                  ))}
              </ul>
            </div>
          </OutsideClick>
        </div>
        {(auth?.permissions?.includes("Booking.can_bulk_download") ||
          auth?.roles?.includes("Superadmin")) && (
          <div className="relative inline-block">
            <OutsideClick onClick={() => setDownloadOptionOpen(false)}>
              <button
                onClick={() => {
                  setDownloadOptionOpen(!downloadOptionOpen);
                }}
                className={`flex items-center gap-1 rounded-md  border bg-white p-2 py-2 text-xs  font-semibold tracking-wide dark:bg-slate-800 lg:mr-2 lg:p-2 lg:text-lg `}
              >
                <FaDownload />
                Download
              </button>

              <div
                className={`${
                  downloadOptionOpen ? "" : "hidden"
                } absolute left-0 top-full  z-40 mt-2 w-full rounded-md border bg-white py-0 dark:bg-slate-800`}
              >
                <ul className="lg:text-md flex max-h-[300px] flex-col overflow-auto">
                  <div key="csv">
                    <li
                      onClick={() =>
                        bookingTable.current.download("csv", `Data.csv`, {
                          delimiter: ",",
                        })
                      }
                      className={`text-gray-900text-md items-center border-y border-slate-100 bg-white py-2 px-4 text-justify align-middle  font-bold dark:bg-slate-800`}
                    >
                      <div className="flex items-center justify-between">
                        <p className="inline-block">CSV</p>

                        <div className="right-1 inline-block">
                          <div className="text-green-700">
                            <FaFileCsv />
                          </div>
                        </div>
                      </div>
                    </li>
                  </div>
                  <div key="xlsx">
                    <li
                      onClick={() =>
                        bookingTable.current.download("xlsx", `Data.xlsx`, {
                          sheetName: "sheet1",
                        })
                      }
                      className={`text-gray-900text-md items-center border-y border-slate-100 bg-white py-2 px-4 text-justify align-middle  font-bold dark:bg-slate-800`}
                    >
                      <div className="flex items-center justify-between">
                        <p className="inline-block">XLSX</p>

                        <div className="right-1 inline-block">
                          <div className="text-green-700">
                            <FaFileExcel />
                          </div>
                        </div>
                      </div>
                    </li>
                  </div>
                  <div key="pdf">
                    <li
                      onClick={() =>
                        bookingTable.current.download("pdf", `Data.pdf`, {
                          orientation: "portrait",
                          title: "Package List",
                        })
                      }
                      className={`text-gray-900text-md items-center border-y border-slate-100 bg-white py-2 px-4 text-justify align-middle  font-bold dark:bg-slate-800`}
                    >
                      <div className="flex items-center justify-between">
                        <p className="inline-block">PDF</p>

                        <div className="right-1 inline-block">
                          <div className="text-red-500">
                            <FaFilePdf />
                          </div>
                        </div>
                      </div>
                    </li>
                  </div>
                </ul>
              </div>
            </OutsideClick>
          </div>
        )}
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className={`flex grow flex-wrap  items-center gap-4 rounded-md rounded-tl-none bg-white dark:bg-slate-800 ${
            filterOpen ? "mb-4 border p-4" : "h-0 overflow-hidden p-0"
          }`}
        >
          <div>
            <InputLabel label="Booking Id" />
            <input
              type="text"
              className="field"
              onChange={(e) =>
                updateFilter({
                  field: "id",
                  type: "like",
                  value: e.target.value,
                })
              }
            />
          </div>
          <div>
            <InputLabel label="Payment Receipt Id" />
            <input
              type="text"
              className="field"
              onChange={(e) =>
                updateFilter({
                  field: "payment_receipt_id",
                  type: "like",
                  value: e.target.value,
                })
              }
            />
          </div>

          <div>
            <InputLabel label="Phone" />
            <input
              type="text"
              className="field"
              onChange={(e) =>
                updateFilter({
                  field: "phone",
                  type: "like",
                  value: e.target.value,
                })
              }
            />
          </div>
          <div>
            <InputLabel label="Email" />
            <input
              type="text"
              className="field"
              onChange={(e) =>
                updateFilter({
                  field: "email",
                  type: "like",
                  value: e.target.value,
                })
              }
            />
          </div>

          {seeAllFilters && (
            <>
              <div>
                <InputLabel label="First Name" />
                <input
                  type="text"
                  className="field"
                  onChange={(e) =>
                    updateFilter({
                      field: "first_name",
                      type: "like",
                      value: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <InputLabel label="Last Name" />
                <input
                  type="text"
                  className="field"
                  onChange={(e) =>
                    updateFilter({
                      field: "last_name",
                      type: "like",
                      value: e.target.value,
                    })
                  }
                />
              </div>

              <div className="min-w-[250px]">
                <InputLabel label="By Package Category" />
                <SelectSearch
                  value={
                    selectedFilter.find(
                      (filter) => filter.field === "category_id"
                    )?.value
                  }
                  options={categories?.map((category) => ({
                    label: `${category?.name}`,
                    value: category?.id,
                  }))}
                  onChange={(value) =>
                    updateFilter({
                      field: "category_id",
                      type: "=",
                      value: value,
                    })
                  }
                />
              </div>

              <div>
                <InputLabel label="Package" />
                <input
                  type="text"
                  className="field"
                  onChange={(e) =>
                    updateFilter({
                      field: "package_name",
                      type: "like",
                      value: e.target.value,
                    })
                  }
                />
              </div>
              <div className="min-w-[250px]">
                <InputLabel label="Booking Date" />
                <DateRangeInput
                  ref={bookingDateRef}
                  controlBtns={true}
                  horizontalPosition="left"
                  onRangeChange={(startDate, endDate) =>
                    updateFilter({
                      field: "created_at",
                      type: "in",
                      value: [
                        startDate
                          ? `${new Date(startDate).getFullYear()}-${
                              new Date(startDate).getMonth() + 1
                            }-${new Date(startDate).getDate()}`
                          : "",
                        endDate
                          ? `${new Date(endDate).getFullYear()}-${
                              new Date(endDate).getMonth() + 1
                            }-${new Date(endDate).getDate()}`
                          : "",
                      ],
                    })
                  }
                />
              </div>
              <div className="min-w-[250px]">
                <InputLabel label="Event Date" />
                <DateRangeInput
                  ref={eventDateRef}
                  controlBtns={true}
                  horizontalPosition="left"
                  onRangeChange={(startDate, endDate) =>
                    updateFilter({
                      field: "event_date",
                      type: "in",
                      value: [
                        startDate
                          ? `${new Date(startDate).getFullYear()}-${
                              new Date(startDate).getMonth() + 1
                            }-${new Date(startDate).getDate()}`
                          : "",
                        endDate
                          ? `${new Date(endDate).getFullYear()}-${
                              new Date(endDate).getMonth() + 1
                            }-${new Date(endDate).getDate()}`
                          : "",
                      ],
                    })
                  }
                />
              </div>

              <div className="min-w-[250px]">
                <InputLabel label="Converted By" />
                <SelectSearch
                  value={
                    selectedFilter.find(
                      (filter) => filter.field === "converted_by"
                    )?.value
                  }
                  options={employees?.map((emp) => ({
                    label: `${emp?.first_name} ${emp?.last_name}`,
                    value: emp?.id,
                  }))}
                  onChange={(value) =>
                    updateFilter({
                      field: "converted_by",
                      type: "=",
                      value: value,
                    })
                  }
                />
              </div>
            </>
          )}

          {seeAllFilters ? (
            <div
              className="cursor-pointer self-end text-sm text-blue-700"
              onClick={() => setSeeAllFilters(false)}
            >
              see less filters .....
            </div>
          ) : (
            <div
              className="cursor-pointer self-end text-sm text-blue-500"
              onClick={() => setSeeAllFilters(true)}
            >
              see all filters .....
            </div>
          )}
          <button
            type="reset"
            className="fgb mt-4 rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700"
            onClick={() => {
              setSelectedFilter([]);
              bookingDateRef.current.resetDates();
              eventDateRef.current.resetDates();
            }}
          >
            Reset All Filters
          </button>
        </form>
        <div className="my-2 rounded-md">
          <DataTable
            ref={bookingTable}
            ajaxUrl={`${getAdminUrl()}/booking?converted_by=${
              convertedBy || ""
            }&booking_start_date=${bookingStartDate || ""}&booking_end_date=${
              bookingEndDate || ""
            }`}
            columns={columns}
            // search={true}
            perPage={25}
            downloadAble={false}
            downloadAbleFormats={["csv", "xlsx"]}
            // responsiveLayout=""
            // search={false}
            rowClick={onRowClick}
            rowFormatter={rowFormatter}
            initialSort={[{ column: "id", dir: "desc" }]}
          />
        </div>
      </div>
    </div>
  );
}

export default BookingList;
