import { ErrorMessage, Field, FieldArray, useFormikContext } from "formik";
import { memo, useState } from "react";
import { useEffect } from "react";
import { MdClose } from "react-icons/md";
import InputLabel from "../../components/inputs/InputLabel";

import {
  getAllOccupancies,
  getAllRiders,
  getAllTravelModes,
  getPriceByItinerary,
  getPriceByItineraryandBatch,
} from "../../services/PackageService";
import { FaCalculator, FaCheck, FaInfo, FaPlus, FaPlusSquare } from "react-icons/fa";
import { useRef } from "react";
import SelectSearch from "../../components/inputs/SelectSearch";
import Modal from "react-modal";
import { validateCoupon } from "../../services/BookingServices";
import { toast } from "react-toastify";
import OccupancyForm from "../../components/occupancies/OccupancyForm";
import TravelModeForm from "../../components/travelModes/TravelModeForm";
import DataTable from "../../components/common/DataTable";
import { getUploadsUrl } from "../../../ServiceConfig";
import Collapsible from "../../../hooks/Collapsible";
import { swalReact } from "../../../helpers/Helper";

function PackagePriceForm({ edit, userMembership, isCustomized, bookingData, selectedIndex, setSelectedIndex, categoryId }) {
  const removeParticipantTable = useRef();
  const addParticipantTable = useRef();

  const { values, setFieldValue } = useFormikContext();

  const [travelModes, setTravelModes] = useState([]);
  const [travelModeOptions, setTravelModeOptions] = useState([]);
  const [riders, setRiders] = useState([]);
  const [riderOptions, setRiderOptions] = useState([]);
  const [occupancies, setOccupancies] = useState([]);
  const [customOccupancies, setCustomOccupancies] = useState([]);
  const [occupancyOptions, setOccupancyOptions] = useState([]);
  const [itineraryPrices, setItineraryPrices] = useState([]);
  const [isHovered, setIsHovered] = useState(Array(values.cost.length).fill(false))

  const [gstWaiveOffModal, setGstWaiveOffModal] = useState(false);
  const [finalPriceForGstWaiveOff, setFinalPriceForGstWaiveOff] = useState(0);

  const [activeCostIndexForGstWaiveOff, setActiveCostIndexForGstWaiveOff] =
    useState();

  const [availableCoupon, setAvailableCoupon] = useState();

  const [couponApplied, setCouponApplied] = useState(false);
  const [membershipApplied, setMembershipApplied] = useState(false);

  const [addTmModal, setAddTmModal] = useState(false);
  const [addOccModal, setAddOccModal] = useState(false);

  const [addTravelerModal, setAddTravelerModal] = useState(false);
  const [removeTravelerModal, setRemoveTravelerModal] = useState(false);

  const [updatedpax, setUpdatedpax] = useState([]);

  const [
    currentIndexToUpdateParticipants,
    setCurrentIndexToUpdateParticipants,
  ] = useState(null);

  // const [currentPax, setCurrentPax] = useState();
  // const [participantsToRemove, setParticipantsToRemove] = useState([]);

  const participantsToAdd = useRef([]);
  const participantsToRemove = useRef([]);

  const removeParticipants = () => {
    setFieldValue(
      "participants",
      values.participants.filter(
        (item1) =>
          !participantsToRemove.current.some((item2) => item2.id === item1.id)
      )
    );

    const prevCost = values.cost[currentIndexToUpdateParticipants].rider_id
      ? bookingData?.cost.find(
        (cst) =>
          cst.travel_mode_id ===
          values.cost[currentIndexToUpdateParticipants].travel_mode_id &&
          cst.rider_id ===
          values.cost[currentIndexToUpdateParticipants].rider_id &&
          cst.occupancy_id ===
          values.cost[currentIndexToUpdateParticipants].occupancy_id
      )?.pax
      : bookingData?.cost.find(
        (cst) =>
          cst.travel_mode_id ===
          values.cost[currentIndexToUpdateParticipants].travel_mode_id &&
          cst.occupancy_id ===
          values.cost[currentIndexToUpdateParticipants].occupancy_id
      )?.pax;

    setFieldValue(
      `cost.${currentIndexToUpdateParticipants}.pax`,
      (prevCost ? prevCost : values.cost[currentIndexToUpdateParticipants].pax) - participantsToRemove.current.length
    );

    setCurrentIndexToUpdateParticipants(null);
    setRemoveTravelerModal(false);
  };

  const addParticipants = () => {
    setFieldValue("participants", [
      ...values.participants,
      ...participantsToAdd.current,
    ]);

    const prevCost = values.cost[currentIndexToUpdateParticipants].rider_id
      ? bookingData?.cost.find(
        (cst) =>
          cst.travel_mode_id ===
          values.cost[currentIndexToUpdateParticipants].travel_mode_id &&
          cst.rider_id ===
          values.cost[currentIndexToUpdateParticipants].rider_id &&
          cst.occupancy_id ===
          values.cost[currentIndexToUpdateParticipants].occupancy_id
      )?.pax
      : bookingData?.cost.find(
        (cst) =>
          cst.travel_mode_id ===
          values.cost[currentIndexToUpdateParticipants].travel_mode_id &&
          cst.occupancy_id ===
          values.cost[currentIndexToUpdateParticipants].occupancy_id
      )?.pax;

    // setFieldValue(
    //   `cost.${currentIndexToUpdateParticipants}.pax`,
    //   Number(values.cost[currentIndexToUpdateParticipants].pax) +
    //     participantsToAdd.current.length
    // );

    setFieldValue(
      `cost.${currentIndexToUpdateParticipants}.participants`,
      participantsToAdd.current.map((participant) => participant.id)
    );

    participantsToRemove.current = participantsToRemove.current.filter(
      (pt) => !participantsToAdd.current.some((prt) => prt.id === pt.id)
    );

    setCurrentIndexToUpdateParticipants(null);
    setAddTravelerModal(false);
  };

  const columns = [
    {
      formatter: "rowSelection",
      titleFormatter: "rowSelection",
      hozAlign: "center",
      vertAlign: "middle",
      headerSort: false,
      // cellClick: function (e, cell) {
      //   cell.getRow().toggleSelect();
      // },
    },
    {
      title: "Id",
      formatter: function (cell, formatterParams, onRendered) {
        return cell.getRow().getIndex();
      },
      minWidth: 80,
    },
    {
      title: "Name",
      field: "first_name",
      frozen: true,
      minWidth: 150,
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      formatter: function (cell, formatterParams, onRendered) {
        return `${cell.getRow().getData().first_name} ${cell.getRow().getData().last_name
          ? cell.getRow().getData().last_name
          : ""
          }`;
      },
      resizable: true,
    },
    {
      title: "Email",
      minWidth: 200,
      field: "email",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Phone",
      minWidth: 150,
      field: "phone",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Whatsapp",
      field: "whatsapp",
      minWidth: 150,
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
    },
    {
      title: "Gender",
      field: "gender",
      minWidth: 100,
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Age",
      field: "total_amount_to_pay",
      resizable: true,
      minWidth: 100,
      vertAlign: "middle",
    },
    {
      title: "Nationality",
      field: "nationality",
      minWidth: 150,
      resizable: true,
      vertAlign: "middle",
    },

    {
      title: "Em. Contact Name",
      field: "emergency_contact_name",
      resizable: true,
      minWidth: 150,
      headerSort: false,
      vertAlign: "middle",
    },
    {
      title: "Em. Contact Relation",
      field: "emergency_contact_relation",
      resizable: true,
      minWidth: 150,
      headerSort: false,
      vertAlign: "middle",
    },
    {
      title: "Em. Contact Number",
      field: "emergency_contact",
      resizable: true,
      minWidth: 150,
      headerSort: false,
      vertAlign: "middle",
    },
    {
      title: "Blood Group",
      field: "blood_group",
      minWidth: 100,
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Health Condition",
      field: "health_condition",
      resizable: true,
      minWidth: 100,
      vertAlign: "middle",
    },
    {
      title: "Allergy",
      field: "allergy",
      minWidth: 100,
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "ID Proof",
      field: "id_proof",
      resizable: true,
      minWidth: 100,
      vertAlign: "middle",
    },
    {
      title: "ID Number",
      field: "id_number",
      resizable: true,
      minWidth: 150,
      vertAlign: "middle",
    },
    {
      title: "Id Card",
      hozAlign: "center",
      vertAlign: "middle",
      minWidth: 100,
      headerSort: false,
      field: "id_attachment",
      formatter: "image",
      formatterParams: {
        height: "50px",
        width: "80px",
        urlPrefix: `${getUploadsUrl()}/package/images/banner/original/`,
        urlSuffix: "",
      },
    },
    {
      title: "Pan Card",
      minWidth: 100,
      hozAlign: "center",
      vertAlign: "middle",
      headerSort: false,
      field: "pan_card",
      formatter: "image",
      formatterParams: {
        height: "50px",
        width: "80px",
        urlPrefix: `${getUploadsUrl()}/package/images/banner/original/`,
        urlSuffix: "",
      },
    },
    {
      title: "Medical Cerificate",
      hozAlign: "center",
      vertAlign: "middle",
      headerSort: false,
      field: "medical_certificate",
      formatter: "image",
      minWidth: 100,
      formatterParams: {
        height: "50px",
        width: "80px",
        urlPrefix: `${getUploadsUrl()}/package/images/banner/original/`,
        urlSuffix: "",
      },
    },
    {
      title: "Visa",
      hozAlign: "center",
      vertAlign: "middle",
      headerSort: false,
      field: "visa",
      formatter: "image",
      minWidth: 100,
      formatterParams: {
        height: "50px",
        width: "80px",
        urlPrefix: `${getUploadsUrl()}/package/images/banner/original/`,
        urlSuffix: "",
      },
    },
    {
      title: "Remaining Amount",
      field: "remaining_amount",
      minWidth: 150,
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      frozen: true,
    },
  ];

  const getTravelModes = () => {
    if (travelModes.length > 0) return;

    getAllTravelModes().then((res) => {
      setTravelModes(res.data.data);
    });
  };

  const getRiders = () => {
    if (riders.length > 0) return;
    getAllRiders().then((res) => {
      setRiders(res.data.data);
    });
  };

  const getOccupancies = () => {
    if (occupancies.length > 0) return;
    getAllOccupancies().then((res) => {
      setOccupancies(res.data.data);
      setCustomOccupancies(
        res.data.data.map((occ) => ({ label: occ.name, value: occ.id }))
      );
    });
  };

  useEffect(() => {
    getTravelModes();
    getRiders();
    getOccupancies();
  }, []);

  const getItineraryPrice = (itinerary, batch) => {
    if (travelModeOptions > 0) return;

    getPriceByItineraryandBatch(itinerary, batch).then((res) => {
      setItineraryPrices(res.data.data)
      if (res.data.data[0].batch_wise_price) {
        swalReact({
          text: "Alert!! This date has Special Prices..", showCloseButton: true, cancelButtonText: "Close", showCancelButton: true
        })
      }
      let tms = [];
      let rdrs = [];
      let ocpancies = [];

      res.data.data.forEach((data) => {
        let travelMode = travelModes.filter(
          (tm) => tm?.id === parseInt(data.travel_mode_id)
        );

        if (!tms.some((tm) => tm.value === travelMode[0]?.id)) {
          tms.push({
            value: travelMode[0]?.id,
            label: travelMode[0]?.name,
          });
        }

        if (data.rider_id != null) {
          let rider = riders.filter(
            (rdr) => rdr.id === parseInt(data?.rider_id)
          );
          if (!rdrs.some((rdr) => rdr.value === rider[0]?.id)) {
            rdrs.push({
              value: rider[0]?.id,
              label: rider[0]?.name,
            });
          }
        }

        let occupancy = occupancies.filter(
          (occ) => occ.id === parseInt(data.occupancy_id)
        );

        if (!ocpancies.some((oc) => oc.value === occupancy[0]?.id)) {
          ocpancies.push({
            value: occupancy[0]?.id,
            label: occupancy[0]?.name,
          });
        }
      });

      setTravelModeOptions(tms);
      setRiderOptions(rdrs);
      setOccupancyOptions(ocpancies);
    });
  };

  useEffect(() => {
    if (!values.itinerary_id || !values.event_date) return;
    getItineraryPrice(values.itinerary_id, values.event_date);
  }, [values.itinerary_id, values.event_date, travelModes, riders, occupancies]);

  const timer = useRef();

  useEffect(() => {
    if (bookingData?.event_date !== values?.event_date) {
      removeDiscount()
    }
  }, [values?.event_date])
  useEffect(() => {
    clearTimeout(timer.current);

    timer.current = setTimeout(() => {
      values.cost.forEach((cst, index) => {
        let cost = 0;
        let gst = 0;
        let withGst = 0;
        let amount = 0;

        if (isCustomized === 1) {
          cost =
            (parseFloat(cst?.package_price)
              ? parseFloat(cst?.package_price)
              : 0) -
            (parseFloat(cst?.package_discount)
              ? parseFloat(cst?.package_discount)
              : 0);
          gst = (cost * 5) / 100;
          withGst = cost + gst;

          amount = parseFloat(withGst) * parseFloat(cst?.pax);

          setFieldValue(
            `cost[${index}].package_price_after_discount`,
            parseFloat(cost)
          );
          setFieldValue(
            `cost[${index}].package_gst`,
            parseFloat(gst).toFixed(2)
          );
          setFieldValue(
            `cost[${index}].package_with_gst`,
            parseFloat(withGst).toFixed(2)
          );
          setFieldValue(
            `cost[${index}].amount`,
            amount ? amount.toFixed(2) : 0
          );
        } else {
          let selectedPrice = itineraryPrices?.filter((price) => {
            if (price.rider_id) {
              return (
                parseFloat(price.travel_mode_id) ===
                parseFloat(cst.travel_mode_id) &&
                parseFloat(price.rider_id) === parseFloat(cst.rider_id) &&
                parseFloat(price.occupancy_id) === parseFloat(cst?.occupancy_id)
              );
            } else {
              return (
                parseFloat(price?.travel_mode_id) ===
                parseFloat(cst.travel_mode_id) &&
                parseFloat(price.occupancy_id) === parseFloat(cst?.occupancy_id)
              );
            }
          });
          if (
            selectedPrice.length > 0 ||
            values.cost[index].custom_value === 1
          ) {
            let showingPrice = selectedPrice[0]?.batch_wise_price ? selectedPrice[0]?.batch_wise_price : selectedPrice[0]?.price
            cost =
              values.cost[index].custom_value === 1
                ? parseFloat(values.cost[index].package_price) -
                parseFloat(cst.package_discount ? cst.package_discount : 0)
                : parseFloat(showingPrice || 0) -
                parseFloat(cst.package_discount ? cst.package_discount : 0);
            gst = (cost * 5) / 100;
            withGst = cost + gst;
            amount = parseFloat(withGst) * parseFloat(cst.pax);

            setFieldValue(
              `cost[${index}].package_price`,
              parseFloat(
                (values.cost[index].custom_value === 0 ||
                  values.cost[index].custom_value === undefined) &&
                  showingPrice
                  ? showingPrice
                  : values.cost[index].package_price
              )
            );
            setFieldValue(
              `cost[${index}].package_price_after_discount`,
              parseFloat(cost)
            );
            setFieldValue(
              `cost[${index}].package_gst`,
              parseFloat(gst).toFixed(2)
            );
            setFieldValue(
              `cost[${index}].package_with_gst`,
              parseFloat(withGst).toFixed(2)
            );
            setFieldValue(
              `cost[${index}].amount`,
              amount ? amount.toFixed(2) : 0
            );
          }
        }
      });
    }, 500);
  }, [values.cost, itineraryPrices]);

  let timer2 = useRef();
  useEffect(() => {
    clearTimeout(timer2.current);
    timer2.current = setTimeout(() => {
      values.addons.forEach((addon, index) => {
        addon?.unit_price > 0 &&
          setFieldValue(`addons[${index}].unit_gst`, addon?.unit_price * 0.05);

        if (addon?.unit) {
          let amount =
            (parseFloat(addon.unit_price) + parseFloat(addon.unit_gst)) *
            addon.unit;
          setFieldValue(`addons[${index}].amount`, amount);
        }
      });
    }, 500);
  }, [values?.addons]);

  let timer3 = useRef();
  useEffect(() => {
    clearTimeout(timer3.current);
    timer3.current = setTimeout(() => {
      values.other_services.forEach((service, index) => {
        service?.margin > 0 &&
          setFieldValue(
            `other_services[${index}].unit_gst`,
            service?.margin * 0.05
          );

        if (service?.unit) {
          let amount =
            (parseFloat(service.margin) +
              parseFloat(service.price) +
              parseFloat(service.unit_gst)) *
            service.unit;
          setFieldValue(`other_services[${index}].amount`, amount);
        }
      });
    }, 500);
  }, [values?.other_services]);

  const calculateGstWaiveOff = () => {
    let discount = (
      values.cost[activeCostIndexForGstWaiveOff].package_price -
      finalPriceForGstWaiveOff / 1.05
    ).toFixed(2);

    setFieldValue(
      `cost[${activeCostIndexForGstWaiveOff}].package_discount`,
      parseFloat(discount)
    );

    setGstWaiveOffModal(false);
  };

  const applyMembershipDiscount = () => {
    setAvailableCoupon(null);
    setMembershipApplied(true);
    setFieldValue(`coupon_code`, "");
    setFieldValue(`is_coupon_applied`, false);
    setFieldValue(`is_membership_used`, true);
    setCouponApplied(false);
    values.cost.forEach((value, index) => {
      let discount =
        (value?.package_price * userMembership?.card?.member_only_discount) /
        100;

      setFieldValue(`cost[${index}].package_discount`, discount);
    });
  };

  useEffect(() => {
    applyCouponDiscount();
  }, [availableCoupon]);

  const applyCouponDiscount = () => {
    if (!availableCoupon) return;
    setMembershipApplied(false);
    setFieldValue(`is_coupon_applied`, true);
    setFieldValue(`is_membership_used`, false);
    values.cost.forEach((value, index) => {
      let discount =
        (parseInt(value?.package_price) *
          parseFloat(availableCoupon?.discount_percent)) /
        100;

      if (discount > parseInt(availableCoupon?.discount_maximum)) {
        discount = availableCoupon?.discount_maximum;
      }

      setFieldValue(`cost[${index}].package_discount`, discount);
    });
  };

  const applyCoupon = () => {
    setCouponApplied(true);
    validateCoupon({
      package_id: values?.package_id,
      event_date: values?.event_date,
      coupon_code: values?.coupon_code,
    })
      .then((res) => {
        if (res.data.data?.type == "global") {
          setAvailableCoupon(res.data.data)
        } else if (res.data.data?.type == "category") {
          let catcoupon = res.data.data.coupon_category_discounts.filter(
            (cat) => cat.category_id === categoryId
          );
          setAvailableCoupon(catcoupon[0], "filtered coupon for category");
        } else if (res.data.data.package_price_discount) {
          values.cost.forEach((cst, index) => {
            console.log(index, 'index of costs array');
            let priceData = res.data.data.package_price_discount.find(
              (pp) =>
                pp.travel_mode_id === cst.travel_mode_id &&
                pp.occupancy_id === cst.occupancy_id &&
                (pp.rider_id ? pp.rider_id === cst.rider_id : true)
            );
            if (priceData) {
              setFieldValue(`cost.${index}.package_discount`, priceData.price - priceData.discounted_price)
            }
          });
        }
      })
      .catch((err) => {
        setCouponApplied(false);
        toast.error(err.response.data.message);
      });
  };

  const removeDiscount = () => {
    setFieldValue(`coupon_code`, "");
    setFieldValue(`is_coupon_applied`, false);
    setFieldValue(`is_membership_used`, false);
    setAvailableCoupon(null);
    setMembershipApplied(false);
    setCouponApplied(false);
    values.cost.forEach((value, index) => {
      setFieldValue(`cost[${index}].package_discount`, 0);
      setFieldValue(
        `cost[${index}].package_price_after_discount`,
        parseFloat(value.package_price) - 0
      );
    });
  };

  useEffect(() => {
    if (values.package_id === bookingData?.package_id || isCustomized === 1)
      return;

    participantsToRemove.current = values.participants;
    const costs = [...values.cost];

    costs.forEach((cst, index) => {
      let pax = cst.pax;
      setUpdatedpax((updatedpax) => [...updatedpax, { i: index, pax: pax }]);
      cst.pax = 0;
    });
    setFieldValue(`cost`, costs);

    setFieldValue(`participants`, []);
  }, [values.package_id, values.package_name]);

  useEffect(() => {
    values.cost.forEach((cst, index) => {
      cst.custom_value
        ? setFieldValue(`cost[${index}].custom_value`, cst.custom_value)
        : setFieldValue(`cost[${index}].custom_value`, 0);
    });
  }, []);

  const getExistingPax = (index) => {
    console.log(updatedpax);

    if (updatedpax?.find((pax) => pax.i === index)?.pax) {
      return updatedpax?.find((pax) => pax.i === index)?.pax;
    } else {
      if (values.cost[index].rider_id) {
        return bookingData?.cost.find(
          (cst) =>
            cst.travel_mode_id === values.cost[index].travel_mode_id &&
            cst.rider_id === values.cost[index].rider_id &&
            cst.occupancy_id === values.cost[index].occupancy_id
        )?.pax;
      } else {
        return bookingData?.cost.find(
          (cst) =>
            cst.travel_mode_id === values.cost[index].travel_mode_id &&
            cst.occupancy_id === values.cost[index].occupancy_id
        )?.pax;
      }
    }
  };

  const handleMouseEnter = (index) => {
    const newHovered = [...isHovered];
    newHovered[index] = true;
    setIsHovered(newHovered);
  };
  const handleMouseLeave = (index) => {
    const newHovered = [...isHovered];
    newHovered[index] = false;
    setIsHovered(newHovered);
  };

  return (
    <div className="flex flex-col gap-4">
      <Collapsible heading='Cost Details' selectedIndex={selectedIndex} index={2} setSelectedIndex={setSelectedIndex}>
        <FieldArray name="cost">
          {({ insert, remove, push }) => (
            <div>

              {values.cost?.map((cost, index) => {

                return (
                  <div
                    key={index}
                    className="relative mb-6 rounded-lg border border-slate-200  bg-white  p-4 dark:border-slate-700 dark:bg-slate-800"
                  >
                    <div className="mb-4 flex items-center gap-4 ">
                      {!isCustomized && <div>
                        <input
                          className="mr-2"
                          type="checkbox"
                          name=""
                          id=""
                          checked={
                            values.cost[index].custom_value === 1 ? true : false
                          }
                          onChange={(e) =>
                            setFieldValue(
                              `cost[${index}].custom_value`,
                              e.target.checked ? 1 : 0
                            )
                          }
                        />
                        Custom Value
                      </div>}
                    </div>
                    <div className="absolute top-2 right-2 flex items-center justify-end gap-4">
                      
                      <button
                        type="button"
                        onClick={() => {
                          setIsHovered(prev => prev.filter((one, ind) => ind !== index))
                          remove(index)
                        }}
                        className="rounded-full border-2 p-1"
                      >
                        <MdClose />
                      </button>
                    </div>
                    <div className="col-span-12 col-start-1 row-span-1 row-start-1 my-4 grid grid-cols-12 items-end gap-4">
                      {/* //? Travel Mode Input */}

                      <div className="col-span-12 md:col-span-3">
                        <InputLabel label={`Select Travel Mode`} required={true} />
                        <div className="flex gap-[0.125rem]">
                          <div className="grow">
                            <SelectSearch
                              options={
                                isCustomized === 1 ||
                                  values.cost[index]?.custom_value === 1
                                  ? travelModes.map((tm) => ({
                                    label: tm.name,
                                    value: tm.id,
                                  }))
                                  : travelModeOptions
                              }
                              value={values.cost[index]?.travel_mode_id}
                              onChange={(value) =>
                                setFieldValue(`cost.${index}.travel_mode_id`, value)
                              }
                            />
                          </div>
                          {(isCustomized === 1 ||
                            values.cost[index]?.custom_value === 1) && (
                              <button
                                type="button"
                                className="rounded-md border p-2"
                                onClick={() => {
                                  setAddTmModal(true);
                                }}
                              >
                                <FaPlus />
                              </button>
                            )}
                        </div>

                        <ErrorMessage name={`cost.${index}.travel_mode_id`}>
                          {(msg) => <div className="error-msg">{msg}</div>}
                        </ErrorMessage>
                      </div>

                      {travelModes?.filter(
                        (tm) => tm?.id === values?.cost[index]?.travel_mode_id
                      )[0]?.has_riders === 1 && (
                          <div className="col-span-2">
                            <InputLabel label="Select Rider" />
                            <SelectSearch
                              options={
                                isCustomized === 1 ||
                                  values.cost[index]?.custom_value === 1
                                  ? riders.map((rider) => ({
                                    label: rider.name,
                                    value: rider.id,
                                  }))
                                  : riderOptions.map((rider) => ({
                                    label: rider.label,
                                    value: rider.value,
                                    isDisabled: values.cost.some(
                                      (cst) =>
                                        cst.travel_mode_id ===
                                        values.cost[index].travel_mode_id &&
                                        cst.rider_id === rider.value
                                    ),
                                  }))
                              }
                              value={parseInt(values.cost[index].rider_id)}
                              onChange={(value) =>
                                setFieldValue(`cost.${index}.rider_id`, value)
                              }
                            />
                            <ErrorMessage name={`cost.${index}.rider_id`}>
                              {(msg) => <div className="error-msg">{msg}</div>}
                            </ErrorMessage>
                          </div>
                        )}
                      <div className="col-span-12 md:col-span-3">
                        <InputLabel label="Select Occupancy" required={true} />
                        <div className="flex gap-[0.125rem]">
                          <div className="grow">
                            <SelectSearch
                              options={
                                isCustomized === 1 ||
                                  values.cost[index]?.custom_value === 1
                                  ? customOccupancies
                                  : occupancyOptions.map((occ) => ({
                                    label: occ.label,
                                    value: occ.value,
                                    isDisabled:
                                      travelModes?.filter(
                                        (tm) =>
                                          tm?.id ===
                                          values?.cost[index]?.travel_mode_id
                                      )[0]?.has_riders === 1
                                        ? values.cost.some(
                                          (cst) =>
                                            cst.travel_mode_id ===
                                            values.cost[index]
                                              .travel_mode_id &&
                                            cst.rider_id ===
                                            values.cost[index].rider_id &&
                                            cst.occupancy_id === occ.value
                                        )
                                        : values.cost.some(
                                          (cst) =>
                                            cst.travel_mode_id ===
                                            values.cost[index]
                                              .travel_mode_id &&
                                            cst.occupancy_id === occ.value
                                        ),
                                  }))
                              }
                              value={parseInt(values.cost[index]?.occupancy_id)}
                              onChange={(value) =>
                                setFieldValue(`cost.${index}.occupancy_id`, value)
                              }
                            />
                          </div>
                          {(isCustomized === 1 ||
                            values.cost[index]?.custom_value === 1) && (
                              <button
                                onClick={() => setAddOccModal(true)}
                                type="button"
                                className="rounded-md border p-2"
                              >
                                <FaPlus />
                              </button>
                            )}
                        </div>
                        <ErrorMessage name={`cost.${index}.occupancy_id`}>
                          {(msg) => <div className="error-msg">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="col-span-6 md:col-span-2">
                        <InputLabel label="Price" required={true} />
                        <Field
                          type="number"
                          step="0.01"
                          min="0"
                          className="field"
                          name={`cost.${index}.package_price`}
                          onChange={(e) =>
                            setFieldValue(
                              `cost.${index}.package_price`,
                              e.target.value
                            )
                          }
                        ></Field>
                        <ErrorMessage name={`cost.${index}.package_price`}>
                          {(msg) => <div className="error-msg">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="col-span-6 md:col-span-2">
                        <div className="flex justify-between items-end ">
                          <InputLabel label="Discount" />
                          <div
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={() => handleMouseLeave(index)}
                            onClick={() => {
                              setGstWaiveOffModal(true);
                              setActiveCostIndexForGstWaiveOff(index);
                            }}
                            className="hidden cursor-pointer text-sm lg:flex font-bold rounded-lg underline hover:text-blue-800 py-1 px-1 items-center text-blue-500"
                          >
                            <p> Calculate Discount</p>
                            <FaInfo />
                            {isHovered[index] && (
                              <div
                                className="absolute top-[-1rem] right-20 transform -translate-x-1/2 p-2 w-48 bg-white border border-gray-300 rounded shadow-lg text-sm text-gray-700"

                              >
                                Calculate discount by including GST per person cost
                              </div>
                            )}
                          </div>
                          <div
                            onClick={() => {
                              setGstWaiveOffModal(true);
                              setActiveCostIndexForGstWaiveOff(index);
                            }}
                            className=" cursor-pointer rounded-lg py-1 px-4 text-blue-500 lg:hidden"
                          >
                            <FaCalculator />
                          </div>
                        </div>
                        <Field
                          type="number"
                          step="0.01"
                          min="0"
                          className="field"
                          name={`cost.${index}.package_discount`}
                        >
                        </Field>
                        <ErrorMessage name={`cost.${index}.package_discount`}>
                          {(msg) => <div className="error-msg">{msg}</div>}
                        </ErrorMessage>
                      </div>

                      <div className="col-span-6 md:col-span-2">
                        <InputLabel label="Cost" />
                        <Field
                          className="field"
                          name={`cost.${index}.package_price_after_discount`}
                          disabled
                        ></Field>
                        <ErrorMessage
                          name={`cost.${index}.package_price_after_discount`}
                        >
                          {(msg) => <div className="error-msg">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="col-span-6 md:col-span-2">
                        <InputLabel label="GST (5%)" />
                        <Field
                          type="number"
                          step="0.01"
                          min="0"
                          name={`cost.${index}.package_gst`}
                          className="field"
                          disabled
                        />
                        <ErrorMessage name={`cost.${index}.gst`}>
                          {(msg) => <div className="error-msg">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="col-span-6 md:col-span-2">
                        <InputLabel label="With GST" />
                        <Field
                          type="number"
                          step="0.01"
                          min="0"
                          name={`cost.${index}.package_with_gst`}
                          className="field"
                          disabled
                        />

                        <ErrorMessage name={`cost.${index}.package_with_gst`}>
                          {(msg) => <div className="error-msg">{msg}</div>}
                        </ErrorMessage>
                      </div>

                      <div className="col-span-6 md:col-span-2">
                        <InputLabel label="Travelers" required={true} />
                        <Field
                          type="number"
                          min="0"
                          className="field"
                          name={`cost.${index}.pax`}
                          onChange={(e) => {
                            setFieldValue(`cost.${index}.pax`, e.target.value);
                            e.target.value === 0 && remove(index);
                            values.cost[index]?.id &&
                              participantsToRemove.current.length === 0 &&
                              e.target.value >
                              (values.cost[index].rider_id
                                ? bookingData?.cost.filter(
                                  (cst) =>
                                    cst.travel_mode_id ===
                                    values.cost[index].travel_mode_id &&
                                    cst.rider_id ===
                                    values.cost[index].rider_id &&
                                    cst.occupancy_id ===
                                    values.cost[index].occupancy_id
                                )?.[0]?.pax
                                : bookingData?.cost.filter(
                                  (cst) =>
                                    cst.travel_mode_id ===
                                    values.cost[index].travel_mode_id &&
                                    cst.occupancy_id ===
                                    values.cost[index].occupancy_id
                                )?.[0]?.pax) &&
                              setFieldValue("participants", [
                                ...values.participants,
                                {
                                  travel_mode_id: values.cost[index].travel_mode_id,
                                  rider_id: values.cost[index].rider_id,
                                  occupancy_id: values.cost[index].occupancy_id,
                                },
                              ]);

                            setCurrentIndexToUpdateParticipants(index);
                          }}
                          onBlur={(e) => {
                            if (values.cost[index]?.id) {
                              if (
                                e.target.value >= getExistingPax(index) &&
                                participantsToRemove?.current?.length > 0
                              ) {
                                setAddTravelerModal(true);
                              } else {
                                if (e.target.value < getExistingPax(index) && edit) {
                                  setRemoveTravelerModal(true);
                                } else {
                                  setFieldValue(
                                    `cost.${index}.pax`,
                                    e.target.value
                                  );
                                }
                              }
                            } else {
                              participantsToRemove?.current?.length > 0 &&
                                setAddTravelerModal(true);
                            }

                            setUpdatedpax((updatedpax) => [
                              ...updatedpax,
                              { i: index, pax: e.target.value },
                            ]);
                          }}
                        ></Field>

                        <ErrorMessage name={`cost.${index}.pax`}>
                          {(msg) => <div className="error-msg">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="col-span-12 md:col-span-2">
                        <InputLabel label="Amount" required={true} />
                        <Field
                          type="number"
                          step="0.01"
                          min="0"
                          name={`cost.${index}.amount`}
                          className="field"
                          disabled
                        />
                        <ErrorMessage name={`cost.${index}.amount`}>
                          {(msg) => <div className="error-msg">{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                )
              })}

              <button
                type="button"
                className="flex items-center gap-1 px-4 text-jw-green"
                onClick={() => {
                  setIsHovered(prev => [...prev, false])
                  push({
                    travel_mode_id: "",
                    rider_id: "",
                    occupancy_id: "",
                    package_price: 0,
                    package_discount: 0,
                    package_price_after_discount: 0,
                    package_gst: 0,
                    package_with_gst: 0,
                    pax: 0,
                    amount: 0,
                  })
                }
                }
              >
                <FaPlusSquare /> Travel Mode
              </button>
            </div>
          )}
        </FieldArray>
      </Collapsible>


      <Collapsible heading='addons' selectedIndex={selectedIndex} index={3} setSelectedIndex={setSelectedIndex}>
        <FieldArray name="addons">
          {({ insert, remove, push }) => (
            <div>
              {values.addons?.map((addon, index) => (
                <div
                  key={index}
                  className="relative mb-1 mt-12 rounded-lg border border-slate-200  bg-white  p-4 dark:border-slate-700 dark:bg-slate-800"
                >
                  <button
                    type="button"
                    onClick={() => remove(index)}
                    className="absolute top-2 right-2 rounded-full border-2 p-1"
                  >
                    <MdClose />
                  </button>
                  <div className="col-span-12 col-start-1 row-span-1 row-start-1 my-4 grid grid-cols-12 items-end gap-4">
                    {/* //? Travel Mode Input */}
                    <div className="col-span-12 md:col-span-3">
                      <InputLabel label="Title" />
                      <Field className="field" name={`addons.${index}.title`} />
                      <ErrorMessage name={`addons.${index}.title`}>
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-span-12 md:col-span-3">
                      <InputLabel label="Description" />
                      <Field
                        className="field"
                        name={`addons.${index}.description`}
                      />
                      <ErrorMessage name={`addons.${index}.description`}>
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-span-6 md:col-span-2">
                      <InputLabel label="Unit Type" />
                      <Field
                        className="field"
                        name={`addons.${index}.unit_type`}
                      />
                      <ErrorMessage name={`addons.${index}.unit_type`}>
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-span-6 md:col-span-2">
                      <InputLabel label="Unit Price" />
                      <Field
                        type="number"
                        step="0.01"
                        min="0"
                        className="field"
                        name={`addons.${index}.unit_price`}
                      // onChange={(e) => {
                      //   setFieldValue(
                      //     `addons.${index}.unit_price`,
                      //     e.target.value
                      //   );
                      //   setFieldValue(
                      //     `addons.${index}.unit_gst`,
                      //     parseFloat(e.target.value) * 0.05
                      //   );
                      // }}
                      />
                      <ErrorMessage name={`addons.${index}.unit_price`}>
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-span-6 md:col-span-2">
                      <InputLabel label="Unit GST" />
                      <Field
                        type="number"
                        step="0.01"
                        min="0"
                        className="field"
                        disabled
                        name={`addons.${index}.unit_gst`}
                      />
                      <ErrorMessage name={`addons.${index}.unit_gst`}>
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-span-6 md:col-span-3">
                      <InputLabel label="Unit" />
                      <Field
                        type="number"
                        step="0.01"
                        min="0"
                        className="field"
                        name={`addons.${index}.unit`}
                      // onChange={(e) => {
                      //   setFieldValue(`addons.${index}.unit`, e.target.value);
                      //   setFieldValue(
                      //     `addons.${index}.amount`,
                      //     (parseFloat(values.addons[index].unit_price) +
                      //       parseFloat(values.addons[index].unit_gst)) *
                      //       parseFloat(e.target.value)
                      //   );
                      // }}
                      />
                      <ErrorMessage name={`addons.${index}.unit`}>
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-span-12 md:col-span-3">
                      <InputLabel label="Amount" />
                      <Field
                        type="number"
                        step="0.01"
                        min="0"
                        className="field"
                        name={`addons.${index}.amount`}
                        disabled
                      />
                      <ErrorMessage name={`addons.${index}.amount`}>
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>
                  </div>
                </div>
              ))}
              <button
                type="button"
                className="mb-4 flex items-center gap-1 px-4 text-jw-green"
                onClick={() =>
                  push({
                    title: "",
                    description: "",
                    unit_type: "",
                    unit_price: "",
                    unit_gst: "",
                    unit: "",
                    amount: "",
                  })
                }
              >
                <FaPlusSquare /> Addon
              </button>
            </div>
          )}
        </FieldArray>
      </Collapsible>

      <Collapsible heading='Other Services' selectedIndex={selectedIndex} index={4} setSelectedIndex={setSelectedIndex}>
        <FieldArray name="other_services">
          {({ insert, remove, push }) => (
            <div>
              {values.other_services?.map((addon, index) => (
                <div
                  key={index}
                  className="relative mb-1 mt-2 rounded-lg border border-slate-200  bg-white  p-4 dark:border-slate-700 dark:bg-slate-800"
                >
                  <button
                    type="button"
                    onClick={() => remove(index)}
                    className="absolute top-2 right-2 rounded-full border-2 p-1"
                  >
                    <MdClose />
                  </button>
                  <div className="col-span-12 col-start-1 row-span-1 row-start-1 my-4 grid grid-cols-12 items-end gap-4">
                    <div className="col-span-12 md:col-span-3">
                      <InputLabel label="Title" />
                      <Field
                        className="field"
                        name={`other_services.${index}.title`}
                      />
                      <ErrorMessage name={`other_services.${index}.title`}>
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-span-12 md:col-span-3">
                      <InputLabel label="Description" />
                      <Field
                        className="field"
                        name={`other_services.${index}.description`}
                      />
                      <ErrorMessage name={`other_services.${index}.description`}>
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-span-6 md:col-span-2">
                      <InputLabel label="Price" />
                      <Field
                        type="number"
                        step="0.01"
                        min="0"
                        className="field"
                        name={`other_services.${index}.price`}
                      />
                      <ErrorMessage name={`other_services.${index}.price`}>
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-span-6 md:col-span-2">
                      <InputLabel label="Margin" />
                      <Field
                        type="number"
                        step="0.01"
                        min="0"
                        className="field"
                        name={`other_services.${index}.margin`}
                      />
                      <ErrorMessage name={`other_services.${index}.margin`}>
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-span-6 md:col-span-2">
                      <InputLabel label="GST" />
                      <Field
                        type="number"
                        step="0.01"
                        min="0"
                        className="field"
                        disabled
                        name={`other_services.${index}.unit_gst`}
                      />
                      <ErrorMessage name={`other_services.${index}.unit_gst`}>
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-span-6 md:col-span-3">
                      <InputLabel label="Unit" />
                      <Field
                        type="number"
                        step="0.01"
                        min="0"
                        className="field"
                        name={`other_services.${index}.unit`}
                      />
                      <ErrorMessage name={`other_services.${index}.unit`}>
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-span-12 md:col-span-3">
                      <InputLabel label="Amount" />
                      <Field
                        type="number"
                        step="0.01"
                        min="0"
                        className="field"
                        name={`other_services.${index}.amount`}
                        disabled
                      />
                      <ErrorMessage name={`other_services.${index}.amount`}>
                        {(msg) => <div className="error-msg">{msg}</div>}
                      </ErrorMessage>
                    </div>
                  </div>
                </div>
              ))}
              <button
                type="button"
                className="mb-4 flex items-center gap-1 px-4 text-jw-green"
                onClick={() =>
                  push({
                    title: "",
                    description: "",
                    price: "",
                    margin: "",
                    unit_gst: "",
                    unit: "",
                    amount: "",
                  })
                }
              >
                <FaPlusSquare /> Other Services
              </button>
            </div>
          )}
        </FieldArray>
      </Collapsible>

      <div className="my-2  bg-white  dark:bg-slate-800">
        <div className="flex flex-wrap items-center justify-between gap-4 lg:flex-nowrap lg:gap-12">
          <div className="shrink grow basis-1/2 lg:basis-auto border border-slate-200 dark:border-slate-700 p-4 rounded-lg">
            <h3 className="mb-2 font-bold">Coupon</h3>
            <div className="flex items-end gap-2">
              <div className="grow">{bookingData?.coupon_code ? <>Applied Coupon - <span className="bg-blue-300 p-1 rounded-xl">  {bookingData?.coupon_code}</span></> : ""}
                <InputLabel label="Apply Coupon" />
                <Field className="field" name={`coupon_code`} />
                <ErrorMessage name={`coupon_code`}>
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
              {!couponApplied && (
                <button
                  disabled={values.coupon_code === undefined}
                  type="button"
                  onClick={applyCoupon}
                  className="rounded-lg border border-jw-green py-1 px-4 text-jw-green"
                >
                  Apply
                </button>
              )}
              {couponApplied && (
                <button
                  type="button"
                  onClick={removeDiscount}
                  className="rounded-lg border border-red-500 py-1 px-4 text-red-500"
                >
                  Remove
                </button>
              )}
            </div>
          </div>
          <div className="shrink grow basis-1/2 lg:basis-auto border border-slate-200 dark:border-slate-700 p-4 rounded-lg">
            <h3 className="mb-2 font-bold">Membership</h3>
            <div className="flex items-end gap-2">
              <div className="grow">
                <InputLabel
                  label={
                    userMembership?.card?.card_name
                      ? `${userMembership?.card?.card_name}`
                      : bookingData?.is_membership_used ? <p className="flex items-center gap-4">Applied Membership <FaCheck color="green" /></p> : `Not Available`
                  }
                  instruction={
                    userMembership?.card?.member_only_discount
                      ? `(${userMembership?.card?.member_only_discount}% Discount)`
                      : ``
                  }
                />
                <Field
                  className="field"
                  name={`membership_card_number`}
                  disabled
                />
                <ErrorMessage name={`membership_card_number`}>
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
              {!membershipApplied && (
                <button
                  disabled={values.membership_card_number === ""}
                  type="button"
                  onClick={applyMembershipDiscount}
                  className="rounded-lg border border-jw-green py-1 px-4 text-jw-green"
                >
                  Apply
                </button>
              )}
              {membershipApplied && (
                <button
                  type="button"
                  onClick={removeDiscount}
                  className="rounded-lg border border-red-500 py-1 px-4 text-red-500"
                >
                  Remove
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={gstWaiveOffModal}
        onRequestClose={() => setGstWaiveOffModal(false)}
        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white"
      >
        <div className="p-4">
          <div className="flex items-center justify-between lg:gap-16">
            <h2 className="lg:text-lg text-sm">Enter per Person Cost including GST</h2>
            <button
              type="button"
              onClick={() => setGstWaiveOffModal(false)}
              className="cursor-pointer rounded-full lg:block hidden border-2 p-2"
            >
              <MdClose />
            </button>
            <button
              type="button"
              onClick={() => setGstWaiveOffModal(false)}
              className="cursor-pointer rounded-full lg:hidden block self-start"
            >
              <MdClose />
            </button>
          </div>
          <div className="mt-4">
            <input
              type="number"
              step="0.01"
              min="0"
              name=""
              id=""
              className="field"
              onChange={(e) => setFinalPriceForGstWaiveOff(e.target.value)}
            />
            <div className="mt-4 text-center">
              <button
                type="button"
                onClick={() => calculateGstWaiveOff()}
                className="btn-primary"
              >
                Calculte
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={addTmModal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setAddTmModal(false)}
        className="absolute top-1/2 left-1/2 w-1/2 -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white md:w-1/4"
      >
        <div className="p-4">
          <div className="flex items-center justify-between border-b pb-2">
            <h4 className="font-semibold">Add Travel Mode</h4>
            <button
              type="button"
              onClick={() => setAddTmModal(false)}
              className="rounded-full border p-2"
            >
              <MdClose />
            </button>
          </div>
          <div className="w-full py-2">
            <TravelModeForm setAddTmModal={setAddTmModal} />
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={addOccModal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setAddOccModal(false)}
        className="absolute top-1/2 left-1/2 w-1/2 -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white md:w-1/4"
      >
        <div className="p-4">
          <div className="flex items-center justify-between border-b pb-2">
            <h4 className="font-semibold">Add Occupancy</h4>
            <button
              type="button"
              onClick={() => setAddOccModal(false)}
              className="rounded-full border p-2"
            >
              <MdClose />
            </button>
          </div>
          <div className="w-full py-2">
            <OccupancyForm setAddOccModal={setAddOccModal} />
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={removeTravelerModal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setRemoveTravelerModal(false)}
        className="absolute top-1/2 left-1/2 w-4/5 -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white"
      >
        <div className="p-4">
          <div className="flex items-center justify-between gap-16 border-b pb-2">
            <h4 className="font-semibold">
              Participants from{" "}
              {
                travelModes.filter(
                  (tm) =>
                    tm.id ===
                    values.cost[currentIndexToUpdateParticipants]
                      ?.travel_mode_id
                )[0]?.name
              }{" "}
              (
              {
                occupancies.filter(
                  (tm) =>
                    tm.id ===
                    values.cost[currentIndexToUpdateParticipants]?.occupancy_id
                )[0]?.name
              }
              )
            </h4>
            <button
              type="button"
              onClick={() => setRemoveTravelerModal(false)}
              className="rounded-full border p-2"
            >
              <MdClose />
            </button>
          </div>
          <div className="w-full py-2">
            <DataTable
              ref={removeParticipantTable}
              tableData={values.participants?.filter(
                (participant) =>
                  participant.travel_mode_id ===
                  values.cost[currentIndexToUpdateParticipants]
                    ?.travel_mode_id &&
                  participant.rider_id ===
                  values.cost[currentIndexToUpdateParticipants]?.rider_id &&
                  participant.occupancy_id ===
                  values.cost[currentIndexToUpdateParticipants]
                    ?.occupancy_id &&
                  participant.status === 1
              )}
              columns={columns}
              search={false}
              // rowClick={rowClick}
              initialSort={[{ column: "id", dir: "desc" }]}
              responsiveLayout={false}
              onDataSelection={(data) => {
                console.log(data);
                participantsToRemove.current = data;
                // setParticipantsToRemove((participantsToRemove) => [
                //   ...participantsToRemove,
                //   ...data,
                // ]);
              }}
            />

            <div className="mt-4 flex items-center justify-center">
              <button
                onClick={removeParticipants}
                className="btn-primary mx-auto"
              >
                Remove Selected
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={addTravelerModal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setAddTravelerModal(false)}
        className="absolute top-1/2 left-1/2 w-4/5 -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white"
      >
        <div className="p-4">
          <div className="flex items-center justify-between gap-16 border-b pb-2">
            <h4 className="font-semibold">Participants</h4>
            <button
              type="button"
              onClick={() => setAddTravelerModal(false)}
              className="rounded-full border p-2"
            >
              <MdClose />
            </button>
          </div>
          <div className="w-full py-2">
            <DataTable
              ref={addParticipantTable}
              tableData={participantsToRemove.current}
              columns={columns}
              search={false}
              // rowClick={rowClick}
              initialSort={[{ column: "id", dir: "desc" }]}
              responsiveLayout={false}
              onDataSelection={(data) => {
                participantsToAdd.current = data;
              }}
            />

            <div className="mt-4 flex items-center justify-center">
              <button onClick={addParticipants} className="btn-primary mx-auto">
                Add Selected
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default memo(PackagePriceForm);
