import React from 'react'
import { Outlet } from 'react-router-dom'

const UsersWrapper = () => {
    return (
        <div className="wrapper">
            <div className="h-full max-h-[calc(100%-1rem)] overflow-auto">
                {<Outlet />}
            </div>
        </div>
    )
}

export default UsersWrapper