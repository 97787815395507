import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import ComponentHeader from "../components/common/ComponentHeader";
import H2 from "../../components/headings/H2";
import { FaListAlt } from "react-icons/fa";
import DepartmentForm from "./DepartmentForm";
import { getDepartmentById } from '../services/DepartmentServices';

const EditDepartment = () => {

  const depatId = useParams().id;
  const [deptData, setDeptData] = useState()
  useEffect(() => {
    getDepartmentById(depatId).then(res => {
      setDeptData(res.data.data)
    }).catch(er => {
      console.log(er)
    })
  }, [])
  return (
    <div className="dark:bg-slate-900 dark:text-white h-[100%]">
      {/* <MetaTags title="Edit State | Admin Panel" /> */}
      <ComponentHeader className="mb-4 border-b">
        <H2>Update Department</H2>
        <Link
          to="/employees/departments"
          className="btn-primary flex items-center gap-1 whitespace-nowrap py-1 px-4 dark:bg-green-700 lg:px-12 lg:py-2"
        >
          <FaListAlt /> View All
        </Link>
      </ComponentHeader>
      {deptData && (
        <>
          <DepartmentForm
            formData={deptData}
            edit={true}
          />
        </>
      )}
    </div>
  )
}

export default EditDepartment