import React from "react";
import Chart from "react-apexcharts";
import useTheme from "../hooks/useTheme";

function DoughnutChart({ label, chartData }) {
  const { theme } = useTheme();
  const data = {
    series: Object.values(chartData),
    chart: {
      type: "donut",
      width: "100%",
      background: "tranparent",
    },
    labels: Object.keys(chartData),
    colors: [
      "#FF5733", // Bright red
      "#33FF57", // Bright green
      "#3357FF", // Bright blue
      "#FF33A1", // Bright pink
      "#FFD433", // Bright yellow
      "#33FFF6", // Bright cyan
      "#FF8F33", // Bright orange
      "#8F33FF", // Bright purple
      "#33FF8F", // Bright mint
      "#FF3357", // Bright magenta
      "#FF6384", // Red
      "#36A2EB", // Blue
      "#FFCE56", // Yellow
      "#4BC0C0", // Cyan
      "#9966FF", // Purple
      "#FF9F40", // Orange
      "#FFCD56", // Light Yellow
      "#C9CBCF", // Gray
      "#36A2EB", // Light Blue
      "#FF6384", // Light Red
    ],
    // theme: {
    //   mode: theme, // Enable dark mode
    // },
    plotOptions: {
      pie: {
        expandOnClick: true,
      },
    },
    legend: {
      position: "right",
      offsetY: 0,
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
    },
    tooltip: {
      y: {
        formatter: (val) => `${val}`,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
            height: 480, // Set specific height for mobile
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 768, // Tablet breakpoint
        options: {
          chart: {
            width: "100%",
            height: 480, // Adjust height for tablet
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 1024, // Desktop breakpoint
        options: {
          chart: {
            width: "100%",
            height: 520, // Adjust height for desktop
          },
          legend: {
            position: "right",
          },
        },
      },
    ],
  };

  return (
    <div>
      <Chart options={data} series={data.series} type="donut" width="520" />
    </div>
  );
}

export default DoughnutChart;
