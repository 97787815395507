import React, { useEffect, useRef, useState } from 'react'
import DataTable from '../components/common/DataTable'
import ComponentHeader from '../components/common/ComponentHeader'
import H2 from '../../components/headings/H2'
import { getAdminUrl } from '../../ServiceConfig'
import moment from 'moment'
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import LeadAssignemntCardHistoryDetail from './LeadAssignemntCardHistoryDetail'
import InputLabel from '../components/inputs/InputLabel'
import SelectSearch from '../components/inputs/SelectSearch'
import { getAllEmployeesOption } from '../services/EmployeeServices'
import DateRangeInput from '../components/inputs/DateRangeInput'

Modal.setAppElement("#modal");

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0px'
    },
};

const LeadsAssignemntHistory = () => {
    const leadHistoryTable = useRef();
    const [openModal, setOpenModal] = useState(false)
    const [selectedId, setSelectedId] = useState()
    const [selectedFilter, setSelectedFilter] = useState([]);
    const [filterOpen, setFilterOpen] = useState(false);
    const [allEmployee, setAllEmployee] = useState([])

    const getAllEmployees = async () => {
        try {
            const res = await getAllEmployeesOption()
            setAllEmployee(res.data.data.data.map((oneemp) => ({
                label: oneemp.full_name,
                value: oneemp.id
            })))
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllEmployees()
    }, [])
    useEffect(() => {
        leadHistoryTable.current.setFilter(selectedFilter);
    }, [selectedFilter]);

    const columns = [
        {
            title: "Id",
            field: "id",
            responsive: 0,
            width: "50",
            resizable: true,
            vertAlign: "middle",
        },
        {
            title: "Lead Id",
            field: "lead_id",
            responsive: 0,
            minWidth: "100",
            resizable: true,
            vertAlign: "middle",
        },
        {
            title: "Previously Assigned To",
            field: "previous_assigned_to",
            responsive: 0,
            minWidth: "200",
            resizable: true,
            vertAlign: "middle",
        },
        {
            title: "New Assigned To",
            field: "new_assigned_to",
            responsive: 0,
            minWidth: "200",
            resizable: true,
            vertAlign: "middle",
        },
        {
            title: "Assigned By",
            field: "assigned_by",
            responsive: 0,
            minWidth: "200",
            resizable: true,
            vertAlign: "middle",
        },
        {
            title: "Updated Date & Time",
            field: "created_at",
            responsive: 0,
            minWidth: "300",
            resizable: true,
            vertAlign: "middle",
            formatter: function (cell, formatterParams, onRendered) {
                return moment(cell.getValue()).format('Do MMMM YYYY, h:mm:ss A');
            },
        },
        // {
        //     title: "Updated",
        //     field: "updated_at",
        //     responsive: 0,
        //     minWidth: "300",
        //     resizable: true,
        //     vertAlign: "middle",
        //     formatter: function (cell, formatterParams, onRendered) {
        //         return moment(cell.getValue()).format('Do MMMM YYYY, h:mm:ss A');
        //     },
        // },
    ]

    const rowClick = (data) => {
        setOpenModal(true)
        setSelectedId(data.lead_id)
    }

    const updateFilter = (filter) => {
        let index = selectedFilter.findIndex((sf) => sf.field === filter.field);

        if (index >= 0) {
            if (filter.value && !Array.isArray(filter.value)) {
                let appliedFilter = [...selectedFilter];
                appliedFilter[index].value = filter.value;
                setSelectedFilter(appliedFilter);
            } else if (Array.isArray(filter.value) && filter.value[0]) {
                let appliedFilter = [...selectedFilter];
                appliedFilter[index].value = filter.value;
                setSelectedFilter(appliedFilter);
            } else {
                setSelectedFilter(
                    selectedFilter.filter((af) => af.field !== filter.field)
                );
            }
        } else {
            setSelectedFilter([...selectedFilter, filter]);
        }
    };

    return (
        <div className="flex h-full flex-col">
            <ComponentHeader className="border-b">
                <H2>Lead Assignment History</H2>
            </ComponentHeader>
            <div className="h-full">
                <div className="my-4 rounded-md">
                    <button
                        onClick={() => setFilterOpen(!filterOpen)}
                        className={` inline-block whitespace-nowrap rounded-md border dark:bg-slate-700 bg-white p-8 py-2 text-lg font-semibold tracking-wide ${filterOpen
                            ? "border-b-1 -mb-[1px] rounded-b-none px-8 pb-1 pt-2"
                            : ""
                            }`}
                    >
                        Filters
                    </button>
                    <div
                        className={`flex grow flex-wrap dark:bg-slate-700 dark:text-white  items-center gap-4 rounded-md rounded-tl-none bg-white ${filterOpen ? "mb-4 border p-4" : "h-0 overflow-hidden p-0"
                            }`}
                    >
                        <div className="min-w-[250px]">
                            <InputLabel label="Previously Asssigned To" />
                            <SelectSearch
                                value={
                                    selectedFilter.find(
                                        (filter) => filter.field === "previous_assigned_to"
                                    )?.value
                                }
                                options={allEmployee}
                                onChange={(value) =>
                                    updateFilter({
                                        field: "previous_assigned_to",
                                        type: "=",
                                        value: value,
                                    })
                                }
                            />
                        </div>

                        <div className="min-w-[250px]">
                            <InputLabel label="New Asssigned To" />
                            <SelectSearch
                                value={
                                    selectedFilter.find(
                                        (filter) => filter.field === "new_assigned_to"
                                    )?.value
                                }
                                options={allEmployee}
                                onChange={(value) =>
                                    updateFilter({
                                        field: "new_assigned_to",
                                        type: "=",
                                        value: value,
                                    })
                                }
                            />
                        </div>

                        <div className="min-w-[250px]">
                            <InputLabel label="Assigned By" />
                            <SelectSearch
                                value={
                                    selectedFilter.find(
                                        (filter) => filter.field === "assigned_by"
                                    )?.value
                                }
                                options={allEmployee}
                                onChange={(value) =>
                                    updateFilter({
                                        field: "assigned_by",
                                        type: "=",
                                        value: value,
                                    })
                                }
                            />
                        </div>

                        <div className="min-w-[250px]">
                            <InputLabel label="lead Id" />
                            <input
                                type="text"
                                className="field"
                                onChange={(e) =>
                                    updateFilter({
                                        field: "lead_id",
                                        type: "like",
                                        value: e.target.value,
                                    })
                                }
                            />
                        </div>

                        <div className="min-w-[250px]">
                            <InputLabel label="Updated Date & Time" />
                            <DateRangeInput
                                controlBtns={true}
                                horizontalPosition="left"
                                onRangeChange={(startDate, endDate) =>
                                    updateFilter({
                                        field: "created_at",
                                        type: "in",
                                        value: [
                                            startDate
                                                ? `${new Date(startDate).getFullYear()}-${new Date(startDate).getMonth() + 1
                                                }-${new Date(startDate).getDate()}`
                                                : "",
                                            endDate
                                                ? `${new Date(endDate).getFullYear()}-${new Date(endDate).getMonth() + 1
                                                }-${new Date(endDate).getDate()}`
                                                : "",
                                        ],
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
                <DataTable
                    ref={leadHistoryTable}
                    ajaxUrl={`${getAdminUrl()}/lead-assignment-history`}
                    columns={columns}
                    // search={true}
                    rowClick={rowClick}
                    responsiveLayout="collapse"
                    perPage={25}
                    initialSort={[
                        {column: "id", dir: "desc"}
                    ]}
                />
            </div>
            <Modal
                isOpen={openModal}
                contentLabel="Lead Assignemnt History Detail"
                onAfterClose={() => { setOpenModal(false) }}
                style={customStyles}
            >
                <div className="relative p-12">
                    <div className='flex justify-center items-center'>
                        <h2 className="mb-4 text-xl font-bold">Lead Assignemnt History Detail</h2>
                        <button
                            onClick={() => setOpenModal(false)}
                            className="absolute right-4 top-4 cursor-pointer rounded-full border-2 p-2"
                        >
                            <MdClose />
                        </button>
                    </div>
                    <LeadAssignemntCardHistoryDetail id={selectedId} />
                </div>
            </Modal>
        </div>
    )
}

export default LeadsAssignemntHistory