import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getSingleCreditNote } from '../../services/CreditNotesServices'
import DataTable from '../common/DataTable'
import { getAdminUrl } from '../../../ServiceConfig'
import UpdateTransaction from './UpdateTransaction'
import { getReadableDateAndTime } from '../../../helpers/Helper'

const SingleCreditNote = () => {
    const creditNoteId = useParams().id
    const singleCreditNoteTable = useRef();
    const [creditNoteData, setCreditNoteData] = useState()
    const [balanceAmount, setBalanceAmount] = useState()
    const [currentUserData, setCurrentUserData] = useState()

    useEffect(() => {
        async function getSingleCreditNoteById(id) {
            const res = await getSingleCreditNote(id)
            setCreditNoteData(res.data.data)
        }
        getSingleCreditNoteById(creditNoteId)
    }, [])

    useEffect(() => {
        if (creditNoteData?.data) {
            setBalanceAmount(creditNoteData.data[creditNoteData.data.length - 1].current_balance)
            setCurrentUserData(creditNoteData.data[creditNoteData.data.length - 1])
        }

    }, [creditNoteData])
    const handleTableReload = () => {
        singleCreditNoteTable.current.reloadTable(`${getAdminUrl()}/credit-note/${creditNoteId}`)
    }

    const columns = [
        {
            formatter: "responsiveCollapse",
            width: 30,
            minWidth: 30,
            responsive: 0,
            hozAlign: "center",
            resizable: false,
            headerSort: false,
        },
        {
            title: "Id",
            field: "id",
            responsive: 0,
            width: "50",
            resizable: true,
            vertAlign: "middle",
        },
        {
            title: "Date",
            field: "created_at",
            minWidth: "100",
            formatter: function (cell, formatterParams, onRendered) {
                return getReadableDateAndTime(cell.getValue());
            },
        },
        {
            title: "Booking Id",
            field: "booking_id",
            minWidth: "100",
            formatter: function (cell, formatterParams, onRendered) {
              return  `<a style="color: blue;" target="_blank" href="/bookings/${cell.getData()?.booking_id ? cell.getData()?.booking_id : cell.getData()?.booking_cancellation_booking_id}">${cell.getData()?.booking_id ? cell.getData()?.booking_id : cell.getData()?.booking_cancellation_booking_id ? cell.getData()?.booking_cancellation_booking_id : ''}</a>`

            },
        },
        {
            title: "Description",
            field: "credit_type",
            minWidth: "100",
            formatter: function (cell, formatterParams, onRendered) {
                if (cell.getRow().getData().credit_type != '' && cell.getRow().getData().credit_type != null) {
                    return cell.getRow().getData().credit_type;
                } else {
                    return cell.getRow().getData().debit_type
                }
            },
        },
        {
            title: "Credit",
            field: "credit_amount",
            minWidth: "100",
            hozAlign: 'center',
            vertAlign: "middle",
            formatter: function (cell, formatterParams, onRendered) {
                return `<p class="text-green-800 font-bold">${cell.getValue() || ''}</p>`;
            },

        },
        {
            title: "Debit",
            field: "debit_amount",
            minWidth: "100",
            hozAlign: 'center',
            vertAlign: "middle",
            formatter: function (cell, formatterParams, onRendered) {
                return `<p class="text-red-800 font-bold">${cell.getValue() || ''}</p>`;
            },
        },
        {
            title: "Current Balance",
            field: "current_balance",
            minWidth: "100",
            hozAlign: 'center',
            vertAlign: "middle",
            formatter: function (cell, formatterParams, onRendered) {
                return `<p class="text-blue-800 font-bold">${cell.getValue() || ''}</p>`;
            },
        },
    ]

    return (
        <div className=''>
            <div className='w-full flex justify-between my-3'>
                <span className='lg:text-xl font-bold'>{`Credit Notes detail for - ${currentUserData?.user_name}`}</span>
                <span className='lg:text-xl font-bold text-green-600'>{`Current Balance : ${balanceAmount}`}</span>
            </div>
            <div className='lg:text-lg text-sm'>
                {currentUserData?.user_id && `User ID: ${currentUserData?.user_id} |  `}
                {currentUserData?.user_email && `Email: ${currentUserData?.user_email} |  `}
                {currentUserData?.user_phone && `Phone: ${currentUserData?.user_phone}`}
            </div>

            <div className="h-auto mb-8">
                <DataTable
                    ref={singleCreditNoteTable}
                    ajaxUrl={`${getAdminUrl()}/credit-note/${creditNoteId}`}
                    columns={columns}
                    layout="fitColumns"
                    responsiveLayout="collapse"
                    height="auto"
                />
            </div>
            <UpdateTransaction creditNoteData={creditNoteData} handleTableReload={handleTableReload} balanceAmount={balanceAmount} setBalanceAmount={setBalanceAmount} userId={currentUserData?.user_id} />
        </div>
    )
}

export default SingleCreditNote