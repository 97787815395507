import { admin } from "../../ServiceConfig";

export function getAllimportDocuments(id) {
    return admin.get(`/important-document`);
}

export function addImportantDocument(data) {
    return admin.post(`/important-document`, data);
}

export function getImportantDocumentById(id) {
    return admin.get(`/important-document/${id}`);
}

export function editImportantDocument(id, data) {
    return admin.put(`/important-document/${id}`, data);
}

export function deleteImportantDocument(id) {
    return admin.delete(`/important-document/${id}`);
}

export function uploadImportantDocumentFile(file) {
    return admin.post(`/uploads/pdf?upload_path=important_document`, file, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}
