import { Link } from "react-router-dom";
import ComponentHeader from "../components/common/ComponentHeader";
import H2 from "../../components/headings/H2";
import { FaListAlt } from "react-icons/fa";
import DesignationForm from "./DesignationForm";

function AddDesignation() {

  return <div className="">
    <ComponentHeader className="mb-4 border-b">
      <H2>Add Designation</H2>
      <Link
        to="/employees/designations"
        className="btn-primary flex dark:bg-green-800 items-center gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
      >
        <FaListAlt /> View All
      </Link>
    </ComponentHeader>
    <DesignationForm />
  </div>

}

export default AddDesignation;
