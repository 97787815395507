import React from "react";
import useTheme from "../hooks/useTheme";
import ReactApexChart from "react-apexcharts";
import moment from "moment";

function BarChart({ label, chartData }) {
  const { theme } = useTheme();

  const formatLabel = (label) => {
    const date = moment(label, "YYYY-MM-DD", true);
    return date.isValid() ? date.format("DD MMM") : label;
  };

  const options = {
    chart: {
      type: "bar",
      background: "transparent", // Background color based on theme
      toolbar: {
        show: false,
      },
      width: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: true,
    },
    xaxis: {
      categories: Object.keys(chartData).map((key) => formatLabel(key)),
      labels: {
        // style: {
        //   colors: theme === "dark" ? "#fff" : "#000",
        // },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: theme === "dark" ? "#fff" : "#000",
        },
      },
    },
    legend: {
      labels: {
        colors: theme === "dark" ? "#fff" : "#000",
      },
    },
    colors: [
      "#3699FF",
      "#1BC5BD",
      "#F64E60",
      "#FF5733",
      "#FF33A1",
      "#8950FC",
      "#FFA800", // Yellow
      "#33FFF6", // Cyan
      "#FF5733", // Orange
      "#33D4FF", // Light Blue
      "#FF33B1", // Magenta
    ],

    tooltip: {
      theme: theme, // Tooltip theme based on chart theme
    },
  };

  const series = [
    {
      name: label,
      data: Object.values(chartData),
    },
  ];

  return (
    <div className="w-full">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
}

export default BarChart;
