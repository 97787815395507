import React, { useEffect, useState } from 'react'
import InputLabel from '../inputs/InputLabel'
import moment from 'moment'
import { getAllCategories } from '../../services/CategoryServices'
import SelectSearch from '../inputs/SelectSearch'
import { getAllCountriesOption } from '../../services/CountryServices'
import { getAllStateByCountry } from '../../services/StateServices'
import { getAllPackages, getPackagesByCategoryId } from '../../services/PackageService'
import DateRangeInput from '../inputs/DateRangeInput'
import MultiSelect from '../inputs/MultiSelect'
import { getAllLocationByCountryId, getLocationsByStateId } from '../../services/LocationServices'

const BookingFiltersDataExt = ({ updateFilter, valuesinSendingEmail, setValuesInSendingEmail, arrayOfOptionsToSendingInEmail }) => {
    const [selectedPackageCategory, setSelectedPackageCategory] = useState()
    const [selectedCountry, setSelectedCountry] = useState()
    const [selectedState, setSelectedState] = useState()
    // const [selectedLocation, setSelectedLocation] = useState()
    const [selectedPackage, setSelectedPackage] = useState()
    const [packageCategories, setPackageCategories] = useState()
    const [allCountries, setAllCountries] = useState([])
    const [allStates, setAllStates] = useState([])
    // const [allLocations, setAllLocations] = useState([])
    const [packagesOption, setpackagesOption] = useState([])

    const allPackageCategories = async () => {
        const res = await getAllCategories()
        setPackageCategories(res.data.data.data.map((oneCat) => ({
            label: oneCat.name,
            value: oneCat.id
        })))
    }

    const allCountriesGetter = async () => {
        const res = await getAllCountriesOption()
        setAllCountries(res.data.data.map((oneCont) => ({
            label: oneCont.name,
            value: oneCont.id
        })))
    }

    const allStatesgetter = async (cont) => {
        const res = await getAllStateByCountry(cont)
        setAllStates(res.data.data.map((oneSt) => ({
            label: oneSt.name,
            value: oneSt.id
        })))
    }

    // const allLocationGetterByCountry = async (cont) => {
    //     const res = await getAllLocationByCountryId(cont)
    //     setAllLocations(res.data.data.map((loc) => ({
    //         value: loc.id,
    //         label: loc.name
    //     })))
    // }

    // const allLocationGetterByState = async (st) => {
    //     const res = await getLocationsByStateId(st)
    //     setAllLocations(res.data.data.map((loc) => ({
    //         value: loc.id,
    //         label: loc.name
    //     })))
    // }

    const getAllPackagesOption = async () => {
        const res = await getAllPackages()
        setpackagesOption(res.data.data.map((onePackage) => ({
            label: onePackage.title,
            value: onePackage.id
        })))
    }

    const getAllPackagesBycategory = async (catId) => {
        const res = await getPackagesByCategoryId(catId)
        setpackagesOption(res.data.data.map((onepkg) => ({
            label: onepkg.title,
            value: onepkg.id
        })))
    }


    useEffect(() => {
        allPackageCategories()
        allCountriesGetter()
        getAllPackagesOption()
    }, [])

    useEffect(() => {
        if (selectedCountry) { allStatesgetter(selectedCountry) }
    }, [selectedCountry])

    useEffect(() => {
        if (selectedPackageCategory) {
            getAllPackagesBycategory(selectedPackageCategory)
        }
    }, [selectedPackageCategory])

    // useEffect(() => {
    //     if (selectedCountry) {
    //         allLocationGetterByCountry(selectedCountry)
    //     }
    // }, [selectedCountry])

    // useEffect(() => {
    //     if (selectedState) {
    //         allLocationGetterByState(selectedState)
    //     }
    // }, [selectedState])


    return (
        <>
            {/* Event Date */}
            <div className="w-full">
                <InputLabel label="Select Event Date Range" />
                <DateRangeInput
                    controlBtns={true}
                    horizontalPosition="left"
                    onRangeChange={(startDate, endDate) => {
                        if (startDate && endDate) {
                            const formattedStartDate = moment(startDate, "DD MMM, YYYY").format("YYYY-MM-DD");
                            const formattedEndDate = moment(endDate, "DD MMM, YYYY").format("YYYY-MM-DD");
                            updateFilter({
                                field: "event_date",
                                type: "=",
                                value: [
                                    formattedStartDate, formattedEndDate
                                ],
                            })

                        }
                    }}
                />
            </div>

            {/* Booking Date */}
            <div className="w-full">
                <InputLabel label="Select Booking Date Range" />
                <DateRangeInput
                    controlBtns={true}
                    horizontalPosition="left"
                    onRangeChange={(startDate, endDate) => {
                        if (startDate && endDate) {
                            const formattedStartDate = moment(startDate, "DD MMM, YYYY").format("YYYY-MM-DD");
                            const formattedEndDate = moment(endDate, "DD MMM, YYYY").format("YYYY-MM-DD");
                            updateFilter({
                                field: "booking_date",
                                type: "=",
                                value: [
                                    formattedStartDate, formattedEndDate
                                ],
                            })

                        }
                    }}
                />
            </div>

            {/* Package Category */}
            <div className="w-full">
                <InputLabel label="Select Package Category" />
                <SelectSearch
                    value={selectedPackageCategory}
                    options={packageCategories}
                    onChange={(value) => {
                        updateFilter({
                            field: "category_id",
                            type: "=",
                            value: value,
                        })
                        setSelectedPackageCategory(value)
                    }}
                />
            </div>

            {/* Package id */}
            <div className="w-full">
                <InputLabel label="Select Package" />
                <SelectSearch
                    value={selectedPackage}
                    options={packagesOption}
                    onChange={(value) => {
                        updateFilter({
                            field: "package_id",
                            type: "=",
                            value: value,
                        })
                        setSelectedPackage(value)
                    }}
                />
            </div>

            {/* Country Id */}
            <div className="w-full">
                <InputLabel label="Select Country" />
                <SelectSearch
                    value={selectedCountry}
                    options={allCountries}
                    onChange={(value) => {
                        updateFilter({
                            field: "country_id",
                            type: "=",
                            value: value,
                        })
                        setSelectedCountry(value)
                    }}
                />
            </div>


            {/* State Id */}
            <div className="w-full">
                <InputLabel label="Select State" />
                <SelectSearch
                    value={selectedState}
                    options={allStates}
                    onChange={(value) => {
                        updateFilter({
                            field: "state_id",
                            type: "=",
                            value: value,
                        })
                        setSelectedState(value)
                    }}
                />
            </div>

            {/* Location Id */}
            {/* <div className="w-full">
                <InputLabel label="Select Location" />
                <SelectSearch
                    value={selectedLocation}
                    options={allLocations}
                    onChange={(value) => {
                        updateFilter({
                            field: "location_id",
                            type: "=",
                            value: value,
                        })
                        setSelectedLocation(value)
                    }}
                />
            </div> */}

            {/* Select Options For Sending Email */}
            <div className="w-full lg:col-span-2">
                <InputLabel label="Select Values to Send in Email" />
                <MultiSelect
                    onChange={(value) => setValuesInSendingEmail(value)}
                    value={valuesinSendingEmail}
                    options={arrayOfOptionsToSendingInEmail}
                />
            </div>
        </>

    )
}

export default BookingFiltersDataExt