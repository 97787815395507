import React, { useState } from "react";
import usePackageData from "../../../hooks/usePackageData";

import {
  addPackageImages,
  deletePackageImages,
  getPackageImages,
  reorderImagesSteptwo,
  updatePackage,
} from "../../../services/PackageService";
import ImageInput from "../../inputs/ImageInput";
import PdfInput from "../../inputs/PdfInput";
import DesktopBannerSample from "../../../../assets/image/common/desktop-banner-sample.png";
import { useEffect } from "react";
import { getUploadsUrl } from "../../../../ServiceConfig";

function Step2({ onSuccess }) {
  const { packageData, setPackageData } = usePackageData();

  const [desktopBannerImage, setDesktopBannerImage] = useState([]);
  const [mobileBannerImage, setMobileBannerImage] = useState([]);
  const [desktopListingImage, setDesktopListingImage] = useState([]);
  const [mobileListingImage, setMobileListingImage] = useState([]);
  const [desktopCategoryImage, setDesktopCategoryImage] = useState([]);
  const [mobileCategoryImage, setMobileCategoryImage] = useState([]);
  const [squareImage, setSquareImage] = useState([]);
  const [videoSupportImage, setVideoSupportImage] = useState([]);
  const [galleryImages, setGalleryImages] = useState();
  const [packagePdf, setPackagePdf] = useState(
    packageData.package?.pdf_itinerary
  );

  const [isImagesSaved, setIsImagesSaved] = useState(false);
  const [isPdfSaved, setIsPdfSaved] = useState(false);

  const [desktopImageCropped, setDesktopImageCropped] = useState(false);
  const [mobileListingCropped, setMobileListingCropped] = useState(false);
  const [desktopListingCropped, setdDesktopListingCropped] = useState(false);
  const [mobileCategoryCropped, setMobileCategoryCropped] = useState(false);
  const [desktopCategoryCropped, setDesktopCategoryCropped] = useState(false);
  const [mobileImageCropped, setMobileImageCropped] = useState(false);
  const [squareCropped, setSquareCropped] = useState(false);
  const [videoSupportCropped, setVideoSupportCropped] = useState(false);

  const savePackageImage = (data) => {
    addPackageImages(data).then((res) => {
      setIsImagesSaved(true);
    });
  };

  const savePackagePdf = (packageId, pdf) => {
    updatePackage(packageId, pdf).then((res) => {
      setIsPdfSaved(true);
    });
  };

  const saveFiles = () => {
    let pics = [];
    if (desktopBannerImage?.length > 0) {
      desktopBannerImage[0].is_main_banner = true;
      desktopBannerImage[0].main_banner_type = "detail-desktop";
      pics.push(desktopBannerImage[0]);
    }

    if (mobileBannerImage?.length > 0) {
      mobileBannerImage[0].is_main_banner = true;
      mobileBannerImage[0].main_banner_type = "detail-mobile";
      pics.push(mobileBannerImage[0]);
    }

    if (desktopListingImage?.length > 0) {
      desktopListingImage[0].is_main_banner = true;
      desktopListingImage[0].main_banner_type = "listing-desktop";
      pics.push(desktopListingImage[0]);
    }

    if (mobileListingImage?.length > 0) {
      mobileListingImage[0].is_main_banner = true;
      mobileListingImage[0].main_banner_type = "listing-mobile";
      pics.push(mobileListingImage[0]);
    }

    if (desktopCategoryImage?.length > 0) {
      desktopCategoryImage[0].is_main_banner = true;
      desktopCategoryImage[0].main_banner_type = "category-desktop";
      pics.push(desktopCategoryImage[0]);
    }

    if (mobileCategoryImage?.length > 0) {
      mobileCategoryImage[0].is_main_banner = true;
      mobileCategoryImage[0].main_banner_type = "category-mobile";
      pics.push(mobileCategoryImage[0]);
    }
    if (squareImage?.length > 0) {
      squareImage[0].is_main_banner = true;
      squareImage[0].main_banner_type = "square";
      pics.push(squareImage[0]);
    }
    if (videoSupportImage?.length > 0) {
      videoSupportImage[0].is_main_banner = true;
      videoSupportImage[0].main_banner_type = "video-support";
      pics.push(videoSupportImage[0]);
    }

    if (galleryImages?.length > 0) {
      const newGalleryimages = galleryImages.map((img, index) => {
        if (img.uploaded_image) {
          img.order = index;
        }
        return img;
      });
      pics = [...pics, ...newGalleryimages];
    }

    let data = {
      images: pics,
      package_id: packageData.package?.id,
    };

    savePackageImage(data);
    savePackagePdf(packageData.package?.id, { pdf_itinerary: packagePdf });
  };

  const deleteImage = (data) => {
    deletePackageImages(data.id).then((res) => {
      console.log(res);
    });
    getPackageImages(packageData.package?.id).then((res) => {
      let imagesToUse = res.data.data.filter(
        (file) => file.is_main_banner !== 1
      );
      const submitData = imagesToUse.map(({ id, order }, index) => ({
        image_id: `${id}`,
        order: `${index}`,
      }));
      reorder(submitData);
    });
  };

  const desktopBannerImgConfig = [
    {
      label: "Crop Image For Desktop Main Banner",
      x: 683,
      y: 342,
      sample: DesktopBannerSample,
      path: "package/images/banner/detail_desktop/cropped",
    },
  ];

  const mobileBannerImgConfig = [
    {
      label: "Crop Image For Mobile Main Banner",
      x: 75,
      y: 68,
      sample: DesktopBannerSample,
      path: "package/images/banner/detail_mobile/cropped",
    },
  ];

  const mobileListingImgConfig = [
    {
      label: "Crop Image For Mobile Main Banner",
      x: 76,
      y: 44,
      sample: DesktopBannerSample,
      path: "package/images/banner/listing_mobile/cropped",
    },
  ];

  const desktopListingImgConfig = [
    {
      label: "Crop Image For Mobile Main Banner",
      x: 53,
      y: 70,
      sample: DesktopBannerSample,
      path: "package/images/banner/listing_desktop/cropped",
    },
  ];

  const mobileCategoryImgConfig = [
    {
      label: "Crop Image For Mobile Main Banner",
      x: 16,
      y: 23,
      sample: DesktopBannerSample,
      path: "package/images/banner/category_mobile/cropped",
    },
  ];

  const desktopCategoryImgConfig = [
    {
      label: "Crop Image For Mobile Main Banner",
      x: 211,
      y: 91,
      sample: DesktopBannerSample,
      path: "package/images/banner/category_desktop/cropped",
    },
  ];
  const squareImgConfig = [
    {
      label: "Crop Image For Square Main Banner",
      x: 1,
      y: 1,
      sample: DesktopBannerSample,
      path: "package/images/banner/square/cropped",
    },
  ];
  const videoSupportImgConfig = [
    {
      label: "Crop Image For Video Support Main Banner",
      x: 263,
      y: 280,
      sample: DesktopBannerSample,
      path: "package/images/banner/video_support/cropped",
    },
  ];

  const getGalleryImages = (res) => {
    let galleryImages = res.filter((file) => file.is_main_banner !== 1);

    setGalleryImages(galleryImages);
  };
  useEffect(() => {
    if (packageData.package?.id) {
      getPackageImages(packageData.package?.id).then((res) => {
        getGalleryImages(res.data.data);

        setDesktopBannerImage(
          res.data.data.filter(
            (file) =>
              file.is_main_banner === 1 &&
              file.main_banner_type === "detail-desktop"
          )
        );

        setMobileBannerImage(
          res.data.data.filter(
            (file) =>
              file.is_main_banner === 1 &&
              file.main_banner_type === "detail-mobile"
          )
        );

        setDesktopListingImage(
          res.data.data.filter(
            (file) =>
              file.is_main_banner === 1 &&
              file.main_banner_type === "listing-desktop"
          )
        );

        setMobileListingImage(
          res.data.data.filter(
            (file) =>
              file.is_main_banner === 1 &&
              file.main_banner_type === "listing-mobile"
          )
        );

        setDesktopCategoryImage(
          res.data.data.filter(
            (file) =>
              file.is_main_banner === 1 &&
              file.main_banner_type === "category-desktop"
          )
        );

        setMobileCategoryImage(
          res.data.data.filter(
            (file) =>
              file.is_main_banner === 1 &&
              file.main_banner_type === "category-mobile"
          )
        );
        setSquareImage(
          res.data.data.filter(
            (file) =>
              file.is_main_banner === 1 && file.main_banner_type === "square"
          )
        );
        setVideoSupportImage(
          res.data.data.filter(
            (file) =>
              file.is_main_banner === 1 &&
              file.main_banner_type === "video-support"
          )
        );
      });
    }
  }, []);

  useEffect(() => {
    if (isImagesSaved || isPdfSaved) {
      onSuccess();
    }
  }, [isImagesSaved, isPdfSaved]);

  const reorder = (submitData) => {
    reorderImagesSteptwo({ order: submitData })
      .then((res) => {
        if (packageData.package?.id) {
          getPackageImages(packageData.package?.id).then((res) => {
            getGalleryImages(res.data.data);
          });
        }
      })
      .catch((er) => {
        console.log(er);
      });
  };

  return (
    <div className="my-8">
      <div className="my-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="grow">
          <h2 className="text-lg font-semibold">
            Desktop Detail Banner Image - Size (683x342)
          </h2>
          <ImageInput
            allowedFiles={1}
            path="package/images/banner/detail_desktop/original"
            images={desktopBannerImage}
            setImages={setDesktopBannerImage}
            cropRatios={desktopBannerImgConfig}
            onDelete={(image) => deleteImage(image)}
            thumbSize="!w-[328px] !h-[120px]"
            onImageCrop={() => setDesktopImageCropped(true)}
            package_slug={packageData.package?.slug}
          />
        </div>
        <div className="flex h-full flex-col">
          <h2 className="text-lg font-semibold">
            Desktop Detail Banner Image Preview
          </h2>
          {(desktopBannerImage?.[0]?.image || desktopImageCropped) && (
            <img
              src={`${getUploadsUrl()}/package/images/banner/detail_desktop/cropped/${
                desktopImageCropped
                  ? desktopBannerImage?.[0]?.uploaded_image
                  : desktopBannerImage?.[0]?.image
              }`}
            />
          )}
          {!desktopBannerImage?.[0]?.image && !desktopImageCropped && (
            <div className="flex grow items-center justify-center rounded-xl border">
              <p className="text-4xl font-bold text-gray-300">Preview</p>
            </div>
          )}
        </div>
      </div>
      <div className="my-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="grow">
          <h2 className="text-lg font-semibold">
            Mobile Detail Banner Image - Size (375x340)
          </h2>
          <ImageInput
            allowedFiles={1}
            thumbPath="package/images/banner/detail_mobile/cropped"
            path="package/images/banner/detail_mobile/original"
            images={mobileBannerImage}
            setImages={setMobileBannerImage}
            cropRatios={mobileBannerImgConfig}
            onDelete={(image) => deleteImage(image)}
            thumbSize="!w-[150px] !h-[195px]"
            onImageCrop={() => setMobileImageCropped(true)}
            package_slug={packageData.package?.slug}
          />
        </div>
        <div className="flex h-full flex-col items-start">
          <h2 className="text-lg font-semibold">
            Mobile Detail Banner Image Preview
          </h2>
          {(mobileBannerImage?.[0]?.image || mobileImageCropped) && (
            <div className="grow">
              <img
                src={`${getUploadsUrl()}/package/images/banner/detail_mobile/cropped/${
                  mobileImageCropped
                    ? mobileBannerImage?.[0]?.uploaded_image
                    : mobileBannerImage?.[0]?.image
                }`}
                className="h-full max-h-[300px]"
              />
            </div>
          )}
          {!mobileBannerImage?.[0]?.image && !mobileImageCropped && (
            <div className="flex w-full grow items-center justify-center rounded-xl border">
              <p className="text-4xl font-bold text-gray-300">Preview</p>
            </div>
          )}
        </div>
      </div>
      <div className="my-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="grow">
          <h2 className="text-lg font-semibold">
            Desktop Listing Image - Size (265x350)
          </h2>
          <ImageInput
            allowedFiles={1}
            thumbPath="package/images/banner/listing_desktop/cropped"
            path="package/images/banner/listing_desktop/original"
            images={desktopListingImage}
            setImages={setDesktopListingImage}
            cropRatios={desktopListingImgConfig}
            onDelete={(image) => deleteImage(image)}
            thumbSize="!w-[150px] !h-[195px]"
            onImageCrop={() => setdDesktopListingCropped(true)}
            package_slug={packageData.package?.slug}
          />
        </div>
        <div className="flex h-full flex-col items-start">
          <h2 className="text-lg font-semibold">
            Desktop Listing Image Preview
          </h2>
          {(desktopListingImage?.[0]?.image || desktopListingCropped) && (
            <div className="grow">
              <img
                src={`${getUploadsUrl()}/package/images/banner/listing_desktop/cropped/${
                  desktopListingCropped
                    ? desktopListingImage?.[0]?.uploaded_image
                    : desktopListingImage?.[0]?.image
                }`}
                className="h-full max-h-[300px]"
              />
            </div>
          )}
          {!desktopListingImage?.[0]?.image && !desktopListingCropped && (
            <div className="flex w-full grow items-center justify-center rounded-xl border">
              <p className="text-4xl font-bold text-gray-300">Preview</p>
            </div>
          )}
        </div>
      </div>
      <div className="my-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="grow">
          <h2 className="text-lg font-semibold">
            Mobile Listing Image - Size (335x220)
          </h2>
          <ImageInput
            allowedFiles={1}
            thumbPath="package/images/banner/listing_mobile/cropped"
            path="package/images/banner/listing_mobile/original"
            images={mobileListingImage}
            setImages={setMobileListingImage}
            cropRatios={mobileListingImgConfig}
            onDelete={(image) => deleteImage(image)}
            thumbSize="!w-[150px] !h-[195px]"
            onImageCrop={() => setMobileListingCropped(true)}
            package_slug={packageData.package?.slug}
          />
        </div>
        <div className="flex h-full flex-col items-start">
          <h2 className="text-lg font-semibold">
            Mobile Listing Image Preview
          </h2>
          {(mobileListingImage?.[0]?.image || mobileListingCropped) && (
            <div className="grow">
              <img
                src={`${getUploadsUrl()}/package/images/banner/listing_mobile/cropped/${
                  mobileListingCropped
                    ? mobileListingImage?.[0]?.uploaded_image
                    : mobileListingImage?.[0]?.image
                }`}
                className="h-full max-h-[300px]"
              />
            </div>
          )}
          {!mobileListingImage?.[0]?.image && !mobileListingCropped && (
            <div className="flex w-full grow items-center justify-center rounded-xl border">
              <p className="text-4xl font-bold text-gray-300">Preview</p>
            </div>
          )}
        </div>
      </div>
      <div className="my-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="grow">
          <h2 className="text-lg font-semibold">
            Desktop Category Image (422x182)
          </h2>
          <ImageInput
            allowedFiles={1}
            thumbPath="package/images/banner/category_desktop/cropped"
            path="package/images/banner/category_desktop/original"
            images={desktopCategoryImage}
            setImages={setDesktopCategoryImage}
            cropRatios={desktopCategoryImgConfig}
            onDelete={(image) => deleteImage(image)}
            thumbSize="!w-[150px] !h-[195px]"
            onImageCrop={() => setDesktopCategoryCropped(true)}
            package_slug={packageData.package?.slug}
          />
        </div>
        <div className="flex h-full flex-col items-start">
          <h2 className="text-lg font-semibold">
            Desktop Category Image Preview
          </h2>
          {(desktopCategoryImage?.[0]?.image || desktopCategoryCropped) && (
            <div className="grow">
              <img
                src={`${getUploadsUrl()}/package/images/banner/category_desktop/cropped/${
                  desktopCategoryCropped
                    ? desktopCategoryImage?.[0]?.uploaded_image
                    : desktopCategoryImage?.[0]?.image
                }`}
                className="h-full max-h-[300px]"
              />
            </div>
          )}
          {!desktopCategoryImage?.[0]?.image && !desktopCategoryCropped && (
            <div className="flex w-full grow items-center justify-center rounded-xl border">
              <p className="text-4xl font-bold text-gray-300">Preview</p>
            </div>
          )}
        </div>
      </div>
      <div className="my-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="grow">
          <h2 className="text-lg font-semibold">
            Mobile Category Image (160x230)
          </h2>
          <ImageInput
            allowedFiles={1}
            thumbPath="package/images/banner/category_mobile/cropped"
            path="package/images/banner/category_mobile/original"
            images={mobileCategoryImage}
            setImages={setMobileCategoryImage}
            cropRatios={mobileCategoryImgConfig}
            onDelete={(image) => deleteImage(image)}
            thumbSize="!w-[150px] !h-[195px]"
            onImageCrop={() => setMobileCategoryCropped(true)}
            package_slug={packageData.package?.slug}
          />
        </div>
        <div className="flex h-full flex-col items-start">
          <h2 className="text-lg font-semibold">
            Mobile Category Image Preview
          </h2>
          {(mobileCategoryImage?.[0]?.image || mobileCategoryCropped) && (
            <div className="grow">
              <img
                src={`${getUploadsUrl()}/package/images/banner/category_mobile/cropped/${
                  mobileCategoryCropped
                    ? mobileCategoryImage?.[0]?.uploaded_image
                    : mobileCategoryImage?.[0]?.image
                }`}
                className="h-full max-h-[300px]"
              />
            </div>
          )}
          {!mobileCategoryImage?.[0]?.image && !mobileCategoryCropped && (
            <div className="flex w-full grow items-center justify-center rounded-xl border">
              <p className="text-4xl font-bold text-gray-300">Preview</p>
            </div>
          )}
        </div>
      </div>
      {/* square */}
      <div className="my-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="grow">
          <h2 className="text-lg font-semibold">Square Banner Image (1:1)</h2>
          <ImageInput
            allowedFiles={1}
            thumbPath="package/images/banner/square/cropped"
            path="package/images/banner/square/original"
            images={squareImage}
            setImages={setSquareImage}
            cropRatios={squareImgConfig}
            onDelete={(image) => deleteImage(image)}
            thumbSize="!w-[150px] !h-[195px]"
            onImageCrop={() => setSquareCropped(true)}
            package_slug={packageData.package?.slug}
          />
        </div>
        <div className="flex h-full flex-col items-start">
          <h2 className="text-lg font-semibold">Square Image Preview</h2>
          {(squareImage?.[0]?.image || squareCropped) && (
            <div className="grow">
              <img
                src={`${getUploadsUrl()}/package/images/banner/square/cropped/${
                  squareCropped
                    ? squareImage?.[0]?.uploaded_image
                    : squareImage?.[0]?.image
                }`}
                className="h-full max-h-[300px]"
              />
            </div>
          )}
          {!squareImage?.[0]?.image && !squareCropped && (
            <div className="flex w-full grow items-center justify-center rounded-xl border">
              <p className="text-4xl font-bold text-gray-300">Preview</p>
            </div>
          )}
        </div>
      </div>
      {/* videosupport */}
      <div className="my-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="grow">
          <h2 className="text-lg font-semibold">
            Video Support Image (265x280)
          </h2>
          <ImageInput
            allowedFiles={1}
            thumbPath="package/images/banner/video_support/cropped"
            path="package/images/banner/video_support/original"
            images={videoSupportImage}
            setImages={setVideoSupportImage}
            cropRatios={videoSupportImgConfig}
            onDelete={(image) => deleteImage(image)}
            thumbSize="!w-[150px] !h-[195px]"
            onImageCrop={() => setVideoSupportCropped(true)}
            package_slug={packageData.package?.slug}
          />
        </div>
        <div className="flex h-full flex-col items-start">
          <h2 className="text-lg font-semibold">Video Support Image Preview</h2>
          {(videoSupportImage?.[0]?.image || videoSupportCropped) && (
            <div className="grow">
              <img
                src={`${getUploadsUrl()}/package/images/banner/video_support/cropped/${
                  videoSupportCropped
                    ? videoSupportImage?.[0]?.uploaded_image
                    : videoSupportImage?.[0]?.image
                }`}
                className="h-full max-h-[300px]"
              />
            </div>
          )}
          {!videoSupportImage?.[0]?.image && !videoSupportCropped && (
            <div className="flex w-full grow items-center justify-center rounded-xl border">
              <p className="text-4xl font-bold text-gray-300">Preview</p>
            </div>
          )}
        </div>
      </div>
      <div className="">
        <h2 className="text-lg font-semibold">Gallery Images</h2>
        <ImageInput
          allowedFiles={8}
          path="package/images/gallery/original"
          images={galleryImages}
          setImages={setGalleryImages}
          onDelete={(image) => deleteImage(image)}
          reorder={true}
          package_slug={packageData.package?.slug}
        />
      </div>
      <div className="my-4">
        <h2 className="text-lg font-semibold">Upload Pdf Itinerary</h2>
        <PdfInput
          allowedFiles={1}
          path="package/pdf"
          pdfFiles={packagePdf ? [packagePdf] : null}
          setPdfFiles={setPackagePdf}
        />
      </div>

      <div className="text-center">
        <button onClick={saveFiles} className="btn-primary mt-4">
          Submit
        </button>
      </div>
    </div>
  );
}

export default Step2;
