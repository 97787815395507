import { admin } from "../../ServiceConfig";

export function getSingleCreditNote(id) {
    return admin.get(`/credit-note/${id}`);
  }
  
export function updateCreditNotes(data) {
  return admin.post("/credit-note", data);
}

export function checkUserExistbyPhone(data){
  return admin.post(`/check-user`, data)
}

export function transferCreditNote(data){
  return admin.post(`/transfer-credit-note`, data)
}