import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";

import InputLabel from "../components/inputs/InputLabel";
import SelectSearch from "../components/inputs/SelectSearch";
import DateInput from "../components/inputs/DateInput";

import {
  getAllEmployee,
  getAllEmployeesOption,
} from "../services/EmployeeServices";
import {
  getAllLeadCategory,
  getLeadSource,
  getLeadStatus,
  storeLead,
  updatelead,
} from "../services/LeadService";

import { formatNumberToTwoDigit, getReadableDate } from "../../helpers/Helper";

import {
  FaEdit,
  FaEnvelope,
  FaPhone,
  FaPhoneAlt,
  FaUserAlt,
  FaWhatsapp,
} from "react-icons/fa";
import useRightSlidePanel from "../hooks/useRightSlidePanel";
import LeadHistoryContainer from "./LeadHistoryContainer";
import PhoneNumberInput from "../../components/inputFields/PhoneInput";
import { Link } from "react-router-dom";
import moment from "moment";
import { getAllPackages } from "../services/PackageService";
import LeadAssignemntCardHistoryDetail from "./LeadAssignemntCardHistoryDetail";
import Collapsible from "../../hooks/Collapsible";
import useAdminAuth from "../hooks/useAdminAuth";
import H2 from "../../components/headings/H2";
// { edit, view, props.formData, updateFormData, openSlidePanel, setOpenSlidePanel }
const LeadForm = forwardRef((props, ref) => {
  // console.log(props.formData, "fdfdfdfdfdfdfdfdffdfdf===============");
  const { auth } = useAdminAuth();
  const [categories, setCategories] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [allPackages, setAllPackages] = useState([]);
  const [leadStatus, setLeadStatus] = useState([]);
  const [leadSource, setLeadSource] = useState([]);

  const { slideOpen, setSlideOpen } = useRightSlidePanel();
  const [remarkViewAll, setRemarkViewAll] = useState(false);
  const [leadHistory, setLeadHistory] = useState([]);

  const [editUserDetail, setEditUserDetail] = useState(
    props.formData ? false : true
  );
  const [editLeadDetail, setEditLeadDetail] = useState(
    props.formData ? false : true
  );

  useEffect(() => {
    if (!props.formData) return;

    setEditUserDetail(false);
    setEditLeadDetail(false);

    // if (props.formData.id) {
    //   getLeadHistory(props.formData.id).then(res => {
    //     console.log(res.data.data.data);
    //     setLeadHistory(res.data.data.data)
    //   })
    // }
  }, [props.formData]);

  useEffect(() => {
    if (!slideOpen) {
      props.setFormData(null);
      setEditUserDetail(true);
      setEditLeadDetail(true);
      setLeadHistory([]);
    }
  }, [slideOpen]);

  useImperativeHandle(ref, () => ({
    toggleSlide(value) {
      if (value) {
        setSlideOpen(value);
      } else {
        setSlideOpen(!slideOpen);
      }
    },
  }));

  const createLeads = (values) => {
    storeLead(values).then((res) => {
      props.onSuccess(res.data.data);
    });
  };

  const editLead = (values) => {
    values.id = props.formData.id;
    updatelead(values).then((res) => {
      setEditUserDetail(false);
      setEditLeadDetail(false);
      props.onSuccess(res.data.data);
    });
  };

  useEffect(() => {
    getAllLeadCategory().then((res) => {
      let cats = [];
      res.data.data.data.forEach((data) => {
        cats.push({ id: data.id, value: data.id, label: data.category });
      });
      setCategories(cats);
    });

    getAllPackages().then((res) => {
      let pkgs = [];
      console.log(res.data.data, "package data");

      res.data.data.forEach((data) => {
        pkgs.push({
          id: data.id,
          value: data.id,
          label: `${data.id} :-  ${data.title} : ${data.no_of_days} days : ${data.starting_location_name} to ${data.ending_location_name}`,
          category: data.category_name,
          country: data.country_name,
          state: data.state_name,
          package_name: data.title,
        });
      });

      setAllPackages(pkgs);
    });

    getAllEmployeesOption().then((res) => {
      let emps = [];

      res.data.data.data.forEach((data) => {
        emps.push({
          id: data.id,
          value: data.id,
          label: `${data.first_name} ${data.last_name}`,
        });
      });

      setEmployees(emps);

      getLeadStatus().then((res) => {
        setLeadStatus(res.data.data.data);
      });

      getLeadSource().then((res) => {
        setLeadSource(res.data.data.data);
      });
    });
  }, []);

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          first_name: props.formData?.first_name
            ? props.formData?.first_name
            : "",
          last_name: props.formData?.last_name ? props.formData?.last_name : "",
          email: props.formData?.email ? props.formData?.email : "",
          country_code: props.formData?.country_code
            ? props.formData?.country_code
            : "91",
          phone: props.formData?.phone ? props.formData?.phone : "",
          assigned_to: props.formData?.assigned_to
            ? props.formData?.assigned_to
            : "",
          lead_category_id: props.formData?.queries[0].lead_category_id || "",
          pax: props.formData?.queries[0].pax || "",
          location: props.formData?.queries[0].location || "",
          package_id: props.formData?.queries[0].package_id || null,
          package_name: props.formData?.queries[0].package_name || null,
          prefered_date: props.formData?.queries[0].prefered_date || "",
          no_of_days: props.formData?.queries[0].no_of_days || "",
          lead_status_id: props.formData?.queries[0].lead_status_id || "",
          lead_source_id: props.formData?.queries[0].lead_source_id || "",
          remark: props.formData?.remark ? props.formData?.remark : "",
          followup: props.formData?.followup || {
            next_datetime: "",
            next_step: "",
          },
        }}
        // validationSchema={bookingFormSchema}
        onSubmit={(values, actions) => {
          props?.formData?.id && editLead(values);
          !props?.formData?.id && createLeads(values);
          setTimeout(() => {
            actions.resetForm({ values: { followup: {} } });
          }, 500);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            {/* //? User Detail Form */}

            <div className="flex justify-between">
              <h2 className="mb-4 text-lg font-bold">Lead</h2>
              {props.formData?.latest_followup && (
                <div className="mb-4 rounded-lg border border-green-200 bg-green-50 p-2 text-sm font-bold">
                  <table>
                    <tbody className="align-top">
                      <tr>
                        <td>Next Step : </td>
                        <td className="px-2 font-semibold capitalize leading-4">
                          {(props.formData?.latest_followup?.next_step).replace(
                            /_/g,
                            " "
                          )}{" "}
                          <br />
                          <span className="text-sm">
                            {`${getReadableDate(
                              props.formData?.latest_followup?.next_datetime
                            )} / ${new Date(
                              props.formData?.latest_followup?.next_datetime
                            ).getHours()}:${new Date(
                              props.formData?.latest_followup?.next_datetime
                            ).getMinutes()}`}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <H2 className="text-lg lg:text-2xl">#{props?.formData?.id}</H2>
            {!editUserDetail && (
              <table className="w-full">
                <tbody>
                  <tr className="group relative border">
                    <div className="absolute -top-2 -right-2">
                      <button
                        onClick={() => setEditUserDetail(true)}
                        type="button"
                        className=" rounded-full border-gray-400 p-2 text-sm lg:invisible lg:group-hover:visible"
                      >
                        <FaEdit />
                      </button>
                    </div>
                    <td className="mt-2 flex justify-center py-4 pl-2 align-top">
                      <div className="rounded-full border p-2 text-lg lg:p-3 lg:text-3xl">
                        <FaUserAlt />
                      </div>
                    </td>
                    <td className="py-4">
                      <div className="flex flex-col items-center justify-center border-x px-2">
                        <div className="font-bold">
                          {props?.formData?.first_name}{" "}
                          {props?.formData?.last_name}
                        </div>
                        <div className="text-sm font-semibold text-gray-500">
                          +{props?.formData?.country_code}-
                          {props?.formData?.phone}
                        </div>
                        <div className="text-sm font-semibold text-gray-500">
                          {props?.formData?.email}
                        </div>
                        <div className="text-sm font-semibold text-gray-400">
                          {props?.formData?.pax && (
                            <span>Members - {props?.formData?.pax},</span>
                          )}{" "}
                          {props?.formData?.no_of_days && (
                            <span>
                              {" "}
                              {props?.formData?.no_of_days - 1}N/
                              {props?.formData?.no_of_days}D
                            </span>
                          )}
                        </div>
                        <div className="text-sm font-semibold text-gray-400">
                          {props?.formData?.location && (
                            <span>{props?.formData?.location}</span>
                          )}{" "}
                          {props?.formData?.prefered_date && (
                            <span>
                              -{" "}
                              {getReadableDate(props?.formData?.prefered_date)}
                            </span>
                          )}
                        </div>
                      </div>
                    </td>
                    <td className="w-16">
                      <div className="ml-2 flex flex-col gap-3 lg:ml-0 lg:items-center">
                        <div>
                          <a href="mailto:" className="rounded-sm text-red-500">
                            {" "}
                            <FaEnvelope />
                          </a>
                        </div>
                        <div>
                          <a
                            href={`tel:+${props?.formData?.country_code}${props?.formData?.phone}`}
                            className="rounded-sm text-orange-500"
                          >
                            <FaPhone />
                          </a>
                        </div>
                        <div>
                          <a
                            href={`https://wa.me/${props?.formData?.country_code}${props?.formData?.phone}`}
                            className="rounded-sm text-green-500"
                          >
                            <FaWhatsapp />
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}

            {editUserDetail && (
              <div className="mb-8">
                <div className="flex gap-4">
                  <div className="grow">
                    <InputLabel label="First Name" />
                    <Field className="field" name={`first_name`} />
                    <ErrorMessage name={`first_name`}>
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="grow">
                    <InputLabel label="Last Name" />
                    <Field className="field" name={`last_name`} />
                    <ErrorMessage name={`last_name`}>
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="my-2 flex gap-4">
                  <div className="grow">
                    <InputLabel label="Email" />
                    <div className="flex items-center gap-1">
                      <Field className="field" type="email" name="email" />
                    </div>
                    <ErrorMessage name={`email`}>
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="flex items-end gap-1">
                    <PhoneNumberInput
                      phone={`${values.country_code}${values.phone}`}
                      onChange={(value, data) => {
                        setFieldValue(`country_code`, data.dialCode);
                        setFieldValue(
                          `phone`,
                          value.slice(data.dialCode.length)
                        );
                      }}
                    />

                    <></>
                  </div>
                </div>
              </div>
            )}

            {!editLeadDetail && (
              <div className="group relative my-4 border p-4">
                <div className="absolute -top-2 -right-2">
                  <button
                    onClick={() => setEditLeadDetail(true)}
                    type="button"
                    className="rounded-full border-gray-400 p-2 text-sm lg:invisible lg:group-hover:visible"
                  >
                    <FaEdit />
                  </button>
                </div>
                <div className="text-sm">
                  {props.formData.assigned_to_name && (
                    <span className="font-bold capitalize">
                      {props.formData.assigned_to_name}'
                      <span className="lowercase">s</span>
                    </span>
                  )}{" "}
                  Lead{" "}
                  {props.formData.queries[0].lead_source_name && (
                    <span className="font-bold capitalize">
                      - from {props.formData.queries[0].lead_source_name}
                    </span>
                  )}
                </div>
                <div className="text-sm">
                  {props.formData?.queries[0].lead_category_name && (
                    <span className="font-bold">
                      {props.formData.queries[0].lead_category_name}
                    </span>
                  )}{" "}
                  Lead -{" "}
                  {props.formData.queries[0].lead_status_name && (
                    <span className="font-bold capitalize">
                      {props.formData.queries[0].lead_status_name}
                    </span>
                  )}
                </div>
                {props.formData?.queries[0].page_url && (
                  <div className="text-sm">
                    <span className="font-semibold"> Query From -</span>{" "}
                    <a
                      href={props.formData?.queries[0].page_url}
                      target="_blank"
                      className="text-blue-500"
                    >
                      {props.formData?.queries[0].page_url}
                    </a>
                  </div>
                )}
              </div>
            )}

            {editLeadDetail && (
              <div className="mb-8">
                <div className="my-2 flex gap-4">
                  <div className="grow">
                    <InputLabel label="Select Package" />
                    <SelectSearch
                      options={allPackages}
                      value={values?.package_id}
                      onChange={(value) => {
                        setFieldValue("package_id", value);
                        let selected = allPackages.find((x) => x.id === value);

                        let category;
                        if (selected.country === "India") {
                          if (selected.category === "Packages") {
                            category = "Domestic Packages";
                          } else {
                            category = selected.category;
                          }
                        } else {
                          category = "International Tour";
                        }
                        setFieldValue(
                          "lead_category_id",
                          categories.find((y) => y.label === category).id
                        );
                        setFieldValue(
                          "location",
                          selected.country === "India"
                            ? selected.state
                            : selected.country
                        );
                        setFieldValue("package_name", selected.package_name);
                      }}
                    />
                    <ErrorMessage name={`package_id`}>
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="my-2 flex gap-4">
                  <div className="grow">
                    <InputLabel label="Category" />
                    <SelectSearch
                      options={categories}
                      value={values?.lead_category_id}
                      onChange={(value) =>
                        setFieldValue("lead_category_id", value)
                      }
                    />
                    <ErrorMessage name={`lead_category_id`}>
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="grow">
                    <InputLabel label="Prefered Location" />
                    <Field className="field" name={`location`} />
                    <ErrorMessage name={`location`}>
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="grow">
                    <InputLabel label="No. of Pax" />
                    <Field
                      className="field"
                      type="number"
                      min="0"
                      name={`pax`}
                    />
                    <ErrorMessage name={`pax`}>
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="grow">
                    <InputLabel label="No. of Days" />
                    <Field
                      className="field"
                      type="number"
                      min="0"
                      name={`no_of_days`}
                    />
                    <ErrorMessage name={`no_of_days`}>
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="grow">
                    <InputLabel label="Prefered Date" />
                    <DateInput
                      value={values?.prefered_date}
                      onDateChange={(value) =>
                        setFieldValue(
                          "prefered_date",
                          `${new Date(
                            value
                          ).getFullYear()}-${formatNumberToTwoDigit(
                            new Date(value).getMonth() + 1
                          )}-${new Date(value).getDate()}`
                        )
                      }
                    />
                    <ErrorMessage name={`prefered_date`}>
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="grow">
                    <InputLabel label="Lead Owner" />
                    <SelectSearch
                      options={employees}
                      value={values?.assigned_to}
                      onChange={(value) => setFieldValue("assigned_to", value)}
                    />
                    <ErrorMessage name={`assigned_to`}>
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="my-2 flex gap-4">
                  <div className="grow">
                    <InputLabel label="Lead Source" />
                    <SelectSearch
                      options={leadSource.map((source) => ({
                        label: source.source,
                        value: source.id,
                      }))}
                      value={values?.lead_source_id}
                      onChange={(value) =>
                        setFieldValue("lead_source_id", value)
                      }
                    />
                    <ErrorMessage name={`lead_source_id`}>
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="grow">
                    <InputLabel label="Lead Status" />
                    <SelectSearch
                      options={leadStatus.map((status) => ({
                        label: status.status,
                        value: status.id,
                      }))}
                      value={values?.lead_status_id}
                      onChange={(value) =>
                        setFieldValue("lead_status_id", value)
                      }
                    />
                    <ErrorMessage name={`lead_status_id`}>
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
              </div>
            )}

            {props.formData?.queries[0]?.remark?.length > 0 && (
              <div className="border p-4">
                <h2 className="font-bold">Remarks</h2>
                <div className="relative">
                  <button
                    type="button"
                    onClick={() => setRemarkViewAll(!remarkViewAll)}
                    className="absolute bottom-0 right-1 text-xs font-bold text-blue-500"
                  >
                    {remarkViewAll ? "View less" : "View All"}
                  </button>
                  <div className="max-h-[200px] overflow-auto">
                    <table className="w-full text-sm">
                      <tbody>
                        {props.formData?.queries[0]?.remark.map(
                          (remark, index) => {
                            if (remarkViewAll) {
                              return (
                                <tr>
                                  <td className="py-2 align-top font-bold">
                                    {props.formData?.queries[0]?.remark.length -
                                      index}
                                    .
                                  </td>
                                  <td className="p-2 text-gray-500">
                                    <div>{remark.remark}</div>
                                    <div className="text-xs text-gray-400">
                                      -By{" "}
                                      <span className="font-bold">
                                        {
                                          employees.filter(
                                            (emp) =>
                                              emp.id === remark.created_by
                                          )[0].label
                                        }
                                      </span>{" "}
                                      on{" "}
                                      {moment(remark.created_at).format(
                                        `DD MMM YYYY`
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            } else {
                              if (index === 0) {
                                return (
                                  <tr>
                                    <td className="py-2 align-top font-bold">
                                      {props.formData?.queries[0]?.remark
                                        .length - index}
                                      .
                                    </td>
                                    <td className="p-2 text-gray-500">
                                      <div className="font-semibold">
                                        {remark.remark}
                                      </div>
                                      <div className="text-xs text-gray-400">
                                        -By{" "}
                                        <span className="font-bold">
                                          {
                                            employees.filter(
                                              (emp) =>
                                                emp.id === remark.created_by
                                            )[0]?.label
                                          }
                                        </span>{" "}
                                        on{" "}
                                        {moment(remark.created_at).format(
                                          `DD MMM, YYYY - h:mm A`
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                );
                              }
                            }
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}

            <div className="mt-4">
              <div className="border p-4">
                <h5 className="font-bold">Next Follow Up</h5>
                <div className="flex flex-col gap-4">
                  <div className="grow">
                    <InputLabel label="Next Step" />
                    <SelectSearch
                      options={[
                        { value: "first_call", label: "First Call" },
                        { value: "first_follow_up", label: "First Follow Up" },
                        {
                          value: "second_follow_up",
                          label: "Second Follow Up",
                        },
                        { value: "third_follow_up", label: "Third Follow Up" },
                        {
                          value: "fourth_follow_up",
                          label: "Fourth Follow Up",
                        },
                        { value: "disqualified", label: "Disqualified" },
                      ]}
                      value={values?.followup?.next_step || ""}
                      onChange={(value) =>
                        setFieldValue("followup.next_step", value)
                      }
                    />
                    <ErrorMessage name={`followup.next_step`}>
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="grow">
                    <InputLabel label="Date and Time" />
                    <Field
                      type="datetime-local"
                      name="followup.next_datetime"
                      className="field"
                    />
                    <ErrorMessage name={`followup.next_datetime`}>
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
              </div>
              <div className="mt-4 grow border p-4">
                <h5 className="font-bold">Remark</h5>
                <Field className="field" as="textarea" name="remark" />
                <ErrorMessage name="remark">
                  {(msg) => <div className="error-msg">{msg}</div>}
                </ErrorMessage>
              </div>
            </div>

            <div className="my-4 text-center">
              <button type="submit" className="btn-primary">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>

      {(auth?.permissions?.includes("Lead.assignmentHistory") ||
        auth?.roles?.includes("Superadmin")) &&
        props?.formData?.assign_history && (
          <Collapsible heading="Show lead Assignment History">
            <LeadAssignemntCardHistoryDetail id={props?.formData?.id} />
          </Collapsible>
        )}

      {props?.formData?.queries?.length > 1 && (
        <div className="my-4">
          <p className="border-b font-bold">History</p>
          {props.formData.queries.map((query, index) => {
            if (index !== 0) {
              return (
                <LeadHistoryContainer
                  key={query.id}
                  lead={query}
                  employees={employees}
                />
              );
            }
          })}
        </div>
      )}
    </div>
  );
});

export default LeadForm;
