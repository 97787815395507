import { admin } from "../../ServiceConfig";

export function getBatchesByItinerary(itineraryId) {
  return admin.get(`itinerary/${itineraryId}/batches`);
}

export function createBatches(data) {
  return admin.post("/batches", data);
}

export function updateBatch(data) {
  return admin.put(`/batches/${data.id}`, data);
}

export function deleteBatch(id) {
  return admin.delete(`/batches/${id}`);
}

export function getMonthEventsBooking(month, year) {
  return admin.get(`/monthsevent/${month}/${year}`);
}


// Batch Prices API ----------------------------

export function getBatchPrices(itinerary_id, batch_id) {
  return admin.get(`/prices-by-itinerary/${itinerary_id}/${batch_id}`)
}

export function addBatchPrices(data) {
  return admin.post(`/store-batch-price`, data)
}

export function updateBatchPrices(data) {
  return admin.put(`/update-batch-price`, data)
}

export function deleteBatchPrices(batchId) {
  return admin.delete(`delete-batch-price/${batchId}`)
}