import React, { useRef } from 'react'
import ComponentHeader from '../common/ComponentHeader'
import H2 from '../../../components/headings/H2'
import DataTable from '../common/DataTable'
import { getAdminUrl } from '../../../ServiceConfig'
import useAdminAuth from '../../hooks/useAdminAuth'
import NotAuthorized403 from '../NotAuthorized403'
import { Link, useNavigate } from 'react-router-dom'
import { FaPlusSquare } from 'react-icons/fa'
import { deleteImportantDocument } from '../../services/ImportantDocumentService'
import { useEffect } from 'react'
import { toast } from 'react-toastify';
import $ from "jquery";

const ImportantDocumentsList = () => {
    const docTable = useRef();
    const { auth } = useAdminAuth();

    const navigate = useNavigate()

    const deleteSelectedDocument = (id) => {
        if (auth?.permissions?.includes("documents.delete") ||
            auth?.roles?.includes("Superadmin")) {
            if (!window.confirm("Do You really want to delete this Important Document?")) return;
            deleteImportantDocument(id)
                .then((res) => {
                    toast.success(res.data.data);
                    docTable.current.deleteRow(id);
                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                });
        }

    };


    const actionIcons = function (cell, formatterParams, onRendered) {
        return `
        <div class="flex items-center gap-3">
          <div class="doc-edit-btn cursor-pointer" data-id=${cell.getRow().getData().id
            }>
              <i class="fas fa-edit"></i>
          </div>
          <div class="doc-del-btn cursor-pointer text-red-500" data-id=${cell.getRow().getData().id
            }>
            <i class="fas fa-trash-alt"></i>
          </div>
      </div>`;
    };


    const columns = [
        {
            title: "Id",
            field: "id",
            responsive: 0,
            width: "60",
            resizable: true,
            vertAlign: "middle",
        },
        {
            title: "Title",
            field: "title",
            responsive: 0,
            minWidth: "160",
            resizable: true,
            vertAlign: "middle",
        },
        {
            title: "File",
            field: "file_name",
            responsive: 0,
            minWidth: "160",
            resizable: true,
            vertAlign: "middle",
        },
        {
            title: "Status",
            field: "status",
            responsive: 0,
            width: "100",
            resizable: true,
            vertAlign: "middle",
            formatter: "tickCross",
            hozAlign: 'center'
        },
        {
            title: "Uploaded By",
            field: "uploaded_by",
            responsive: 0,
            minWidth: "160",
            resizable: true,
            vertAlign: "middle",
        },
        {
            formatter: actionIcons,
            headerSort: false,
            width: 100,
            responsive: 0,
            hozAlign: "center",
            vertAlign: "middle",
        },
    ]

    useEffect(() => {
        $(document).on("click", ".doc-edit-btn", function (e) {
            e.stopPropagation();
            e.preventDefault();
            let cell = $(this).attr("data-id");
            navigate(`/important-documnets/${cell}`);
        });

        $(document).on("click", ".doc-del-btn", function (e) {
            e.stopPropagation();
            e.preventDefault();
            let dataId = $(this).attr("data-id");
            deleteSelectedDocument(dataId);
        });

        return () => {
            $(document).off("click", ".doc-edit-btn");
            $(document).off("click", ".doc-del-btn");
        };
    }, []);


    return (
        <>
            {(auth?.permissions?.includes("documents.read") ||
                auth?.roles?.includes("Superadmin")) ? <div className="flex h-full flex-col">
                <ComponentHeader className="border-b">
                    <H2>Important Documents</H2>
                    {(auth?.permissions?.includes("documents.add") ||
                        auth?.roles?.includes("Superadmin")) ? <Link
                            to="/important-documnets/add"
                            className="btn-primary flex items-center dark:bg-green-800 gap-1 whitespace-nowrap py-1 px-4 lg:px-12 lg:py-2"
                        >
                        <FaPlusSquare /> Add New
                    </Link> : <></>}
                </ComponentHeader>
                <div className="h-full">
                    <DataTable
                        ref={docTable}
                        ajaxUrl={`${getAdminUrl()}/important-document`}
                        columns={columns}
                        // search={true}
                        // rowClick={onRowClick}
                        responsiveLayout="collapse"
                    />
                </div>
            </div>
                : <NotAuthorized403 />}
        </>
    )
}

export default ImportantDocumentsList