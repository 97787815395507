import { admin } from "../../ServiceConfig";

export function getSettingsByKey(key, type) {
    return admin.get(`/website-settingsByKeyType/${key}/${type}`)
}
export function getAdBannersByKey(key) {
    return admin.get(`/ad-banners-by-key-type?key=${key}`)
}

export function updateWebsiteSettings(id, data) {
    return admin.put(`/website-settings/${id}`, data);
}
export function createWebsiteSettings(data) {
    return admin.post(`/website-settings`, data);
}
export function updateAdBanner(data) {
    return admin.post(`/ad-banners`, data);
}

export function uploadSettingsImage(file) {
    return admin.post(`/uploads/image?upload_path=settings`, file, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

export function getAllHomepageCategories() {
    return admin.get('/home-category')
}

export function getAllHomepageCategoriesforReorderPage() {
    return admin.get('/all-home-category')
}

export function getHomePackageCategoryById(id){
    return admin.get(`/home-category/${id}`)
}

export function addNewHomepageCategory(data){
    return admin.post('/home-category', data)
}

export function editHomepageCategory(id, data){
    return admin.put(`/home-category/${id}`, data)
}

export function assignPackagestoHomepageCategories(data) {
    return admin.post('/home-category-packages', data)
}

export function deleteHomePagecategory(id){
    return admin.delete(`/home-category/${id}`)
}

export function uploadCategoryImage(file) {
    return admin.post(
      `uploads/image?upload_path=homepage/home-categories`,
      file,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

export function reorderCategories(data){
    return admin.post(`/home-category-order`, data)
}
  