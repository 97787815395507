import React, { useRef } from 'react'
import ComponentHeader from '../common/ComponentHeader'
import H2 from '../../../components/headings/H2'
import DataTable from '../common/DataTable'
import { getAdminUrl } from '../../../ServiceConfig'
import moment from 'moment'
import useAdminAuth from '../../hooks/useAdminAuth'
import NotAuthorized403 from '../NotAuthorized403'

const UsersList = () => {
    const usersTable = useRef();
    const { auth } = useAdminAuth();
    const columns = [
        {
            title: "Id",
            field: "id",
            responsive: 0,
            width: "60",
            resizable: true,
            vertAlign: "middle",
        },
        {
            title: "First Name",
            field: "first_name",
            responsive: 0,
            minWidth: "130",
            resizable: true,
            vertAlign: "middle",
        },
        {
            title: "Last Name",
            field: "last_name",
            responsive: 0,
            minWidth: "130",
            resizable: true,
            vertAlign: "middle",
        },
        {
            title: "Email ID",
            field: "email",
            responsive: 0,
            minWidth: "230",
            resizable: true,
            vertAlign: "middle",
        },
        {
            title: "Country Code",
            field: "country_code",
            responsive: 0,
            minWidth: "130",
            resizable: true,
            vertAlign: "middle",
            hozAlign: 'center'
        },
        {
            title: "Phone Number",
            field: "phone",
            responsive: 0,
            minWidth: "140",
            resizable: true,
            vertAlign: "middle",
        },
        // {
        //     title: "Email Verified",
        //     field: "email_verified_at",
        //     responsive: 0,
        //     minWidth: "100",
        //     resizable: true,
        //     vertAlign: "middle",
        // },
        // {
        //     title: "Phone verified",
        //     field: "phone_verified_at",
        //     responsive: 0,
        //     minWidth: "100",
        //     resizable: true,
        //     vertAlign: "middle",
        // },
        {
            title: "Created At",
            field: "created_at",
            responsive: 0,
            minWidth: "300",
            resizable: true,
            vertAlign: "middle",
            formatter: function (cell, formatterParams, onRendered) {
                return cell.getValue() ? moment(cell.getValue()).format('Do MMMM YYYY, h:mm:ss A') : '';
            },
        },
        {
            title: "Updated At",
            field: "updated_at",
            responsive: 0,
            minWidth: "300",
            resizable: true,
            vertAlign: "middle",
            formatter: function (cell, formatterParams, onRendered) {
                return cell.getValue() ? moment(cell.getValue()).format('Do MMMM YYYY, h:mm:ss A') : '';
            },
        },
        {
            title: "Deleted At",
            field: "deleted_at",
            responsive: 0,
            minWidth: "300",
            resizable: true,
            vertAlign: "middle",
            formatter: function (cell, formatterParams, onRendered) {
                return cell.getValue() ? moment(cell.getValue()).format('Do MMMM YYYY, h:mm:ss A') : '';
            },
        }
    ]
    return (
        <>
            {(auth?.permissions?.includes("user.read") ||
                auth?.roles?.includes("Superadmin")) ? <div className="flex h-full flex-col">
                <ComponentHeader className="border-b">
                    <H2>Users</H2>
                </ComponentHeader>
                <div className="h-full">
                    <DataTable
                        ref={usersTable}
                        ajaxUrl={`${getAdminUrl()}/users`}
                        columns={columns}
                        search={true}
                        // rowClick={onRowClick}
                        responsiveLayout="collapse"
                    />
                </div>
            </div>
                : <NotAuthorized403 />}
        </>
    )
}

export default UsersList