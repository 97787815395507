import React, { useEffect, useState } from "react";
import axios from "axios";
import { Parser as DomParser } from "htmlparser2";
import { DomHandler } from "domhandler";
import { getFrontUrl, getUploadsUrl } from "../../../../ServiceConfig";
import { toast } from "react-toastify";

const fetch = async (url) => {
  try {
    // Fetch the HTML content from the URL
    const response = await axios.get(url); // Await the axios request
    return response.data;
  } catch (err) {
    console.error("Error fetching URL", err);
    throw err; // Re-throw the error to handle it in the calling function
  }
};

const WebsiteCacheRevalidator = () => {
  const [url, setUrl] = useState("");
  const [results1, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [error, setError] = useState("");

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  };

  const revalidateCacheBySlug = async () => {
    setLoading(true);

    setError("");

    try {
      const urlObj = new URL(url);
      const urltofetch = `${urlObj.origin}/api/revalidate?slug=${urlObj.pathname}`;
      // console.log(urltofetch);
      const fetchedResults = await fetch(urltofetch);
      if (fetchedResults.revalidated == true) {
        setUrl("");
        toast.success(`${url} is Revalidated.`);
      }
      //   return fetchedResults;
    } catch (err) {
      setError("Error fetching or parsing HTML.");
    } finally {
      setLoading(false);
    }
  };
  const revalidateAllCache = async () => {
    setLoading1(true);
    try {
      const apifetch = await fetch("https://api.justwravel.com/flush-cache");
      if (apifetch == true) {
        toast.success(`API cache flushed`);
      }

      const urlObj = new URL("https://www.justwravel.com");
      const urltofetch = `${urlObj.origin}/api/revalidate?all=1`;
      console.log(urltofetch);
      const fetchedResults = await fetch(urltofetch);
      console.log(fetchedResults);

      if (fetchedResults.revalidated == true) {
        toast.success(`All urls are Revalidated.`);
      }
    } catch (err) {
      setError("Error fetching or parsing HTML.");
    } finally {
      setLoading1(false);
    }
  };

  // useEffect(() => {
  //   // console.log(results);
  //   // Optional: You can put any effect here if needed
  // }, [url]); // Dependency array if you want to trigger side effects when `url` changes

  return (
    <div>
      <div className="my-5 mx-auto w-full border-2 bg-[#D7F2FD] p-10 text-center">
        <h1 className="py-3 text-3xl font-bold">
          Revalidate / Clean Cache of a Single URL
        </h1>
        <input
          type="text"
          placeholder="Enter URL"
          value={url}
          onChange={handleUrlChange}
          className="w-full rounded px-6 py-2"
        />

        <button
          onClick={revalidateCacheBySlug}
          disabled={loading}
          className="btn-primary m-2"
        >
          {loading ? "Revalidating..." : "Revalidate"}
        </button>
        {error && <p style={{ color: "red" }}>{error}</p>}
      </div>
      <div className="my-5 mx-auto w-full border-2 bg-[#D7F2FD] p-10 text-center">
        <h1 className="py-3 text-3xl font-bold">Revalidate All Cache</h1>

        <button
          onClick={revalidateAllCache}
          disabled={loading1}
          className="btn-primary m-2"
        >
          {loading1 ? "Revalidating..." : "Revalidate All"}
        </button>
      </div>
      {/* <div className=" mx-auto w-full border-2 bg-[#D7F2FD] p-10 text-center">
        <h1 className="py-3 text-3xl font-bold">Flush Api Cache only</h1>

        <button
          onClick={flushApicache}
          disabled={loading}
          className="btn-primary m-2"
        >
          {loading ? "Flushing..." : "Fush Cache"}
        </button>
        {error && <p style={{ color: "red" }}>{error}</p>}
      </div> */}
    </div>
  );
};

export default WebsiteCacheRevalidator;
