import { useState } from "react";
import { useEffect } from "react";
import { FaRupeeSign } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  approvePaymentScreenshot,
  getPaymentVerificationById,
  rejectPaymentScreenshot,
} from "../../services/BookingServices";
import { getAllRidersOption } from "../../services/RiderServices";
import { getAllTravelModesOption } from "../../services/TravelModeServices";
import { getAllOccupancies } from "../../services/PackageService";
import { getAllStateByCountry } from "../../services/StateServices";
import useAdminAuth from "../../hooks/useAdminAuth";
import {
  formatNumberToTwoDigit,
  getReadableDate,
} from "../../../helpers/Helper";
import { getUploadsUrl } from "../../../ServiceConfig";
import moment from "moment";

function PaymentVerificationDetails() {
  const remainingPaymentId = useParams().id;
  const { auth } = useAdminAuth();
  const navigate = useNavigate();

  const [bookingData, setBookingData] = useState();
  const [states, setStates] = useState();
  const [travelModes, setTravelModes] = useState();
  const [riders, setRiders] = useState();
  const [occupancies, setOccupancies] = useState();
  const [balancePaymentData, setBalancePaymentData] = useState();
  const [disableButtons, setDisableButtons] = useState(false);

  const getVerficationLinkData = () => {
    getPaymentVerificationById(remainingPaymentId).then((res) => {
      setBookingData(res.data.data.booking_data);
      setBalancePaymentData(res.data.data);
    });
  };

  useEffect(() => {
    getVerficationLinkData();
  }, [remainingPaymentId]);

  useEffect(() => {
    getAllTravelModesOption().then((res) => {
      setTravelModes(res.data.data);
    });

    getAllRidersOption().then((res) => {
      setRiders(res.data.data);
    });

    getAllOccupancies().then((res) => {
      setOccupancies(res.data.data);
    });
  }, []);

  useEffect(() => {
    if (!bookingData?.country_id) return;
    getAllStateByCountry(bookingData?.country_id).then((res) => {
      setStates(res.data.data);
    });
  }, [bookingData]);

  const getRiderName = (riderId) => {
    let riderName = riders?.filter((rider) => rider.id === riderId)[0]?.name;

    if (riderName) {
      return riderName;
    } else {
      return "N/A";
    }
  };

  const approvePayment = async () => {
    setDisableButtons(true);
    try {
      const res = await approvePaymentScreenshot(remainingPaymentId);
      if (res.data.status === "success") {
        navigate(`/bookings/${bookingData?.id}`);
        toast.success(res.data.message);
      }
      setDisableButtons(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setDisableButtons(false);
    }
  };

  const rejectPayment = async () => {
    try {
      const res = await rejectPaymentScreenshot(remainingPaymentId);
      if (res.data.status === "success") {
        getVerficationLinkData();
        toast.error(res.data.message);
      }
      setDisableButtons(false);
    } catch (error) {
      console.log(error);
      setDisableButtons(false);
    }
  };

  return (
    <div className="p-4">
      <div className="my-4 flex items-center justify-between">
        <h2 className="text-xl font-bold">
          Screenshot Verification Link : #{bookingData?.id}
        </h2>
      </div>
      <div className="rounded-lg border-2 p-4">
        <h2 className="border-b-2 pb-4 text-xl font-bold">Billing Info</h2>
        <div className="my-4 flex flex-wrap items-center">
          <div className="my-2 basis-1/3">
            <b>Name : </b> {bookingData?.first_name} {bookingData?.last_name}
          </div>
          <div className="my-2 basis-1/3">
            <b>Email : </b> {bookingData?.email}
          </div>
          <div className="my-2 basis-1/3">
            <b>Phone : </b> {bookingData?.phone}
          </div>
          {bookingData?.firm_name && (
            <div className="my-2 basis-1/3">
              <b>Company : </b> {bookingData?.firm_name}
            </div>
          )}
          {bookingData?.gst_number && (
            <div className="my-2 basis-1/3">
              <b>GST Number : </b> {bookingData?.gst_number}
            </div>
          )}
          {bookingData?.firm_address && (
            <div className="my-2 basis-1/3">
              <b>Address : </b> {bookingData?.firm_address}
            </div>
          )}
          <div className="my-2 basis-1/3">
            <b>State : </b>{" "}
            {
              states?.filter((state) => state.id === bookingData?.state_id)[0]
                ?.name
            }
          </div>
        </div>
      </div>
      <div className="my-4 rounded-lg border-2 bg-white p-4">
        <h2 className="border-b-2 pb-4 text-xl font-bold">Package Info</h2>
        <div className="my-4 flex flex-wrap items-center">
          <div
            className={
              bookingData?.is_customized
                ? "hidden"
                : "my-2 basis-full lg:basis-1/3"
            }
          >
            <b>Package Id: </b> {bookingData?.package_id}
          </div>
          <div className="my-2 grow basis-full lg:basis-2/3">
            <b>Package : </b> {bookingData?.package_name}
          </div>

          <div className="my-2 basis-full lg:basis-1/3">
            <b>Event Date : </b> {getReadableDate(bookingData?.event_date)}
          </div>
          <div className="my-2 basis-full lg:basis-1/3">
            <b>Pick Drop Location : </b> {bookingData?.starting_location}{" "}
            &#8594; {bookingData?.ending_location}
          </div>
          <div className="my-2 basis-full lg:basis-1/3">
            <b>Number of days : </b> {bookingData?.no_of_days}{" "}
          </div>
          {bookingData?.special_notes && (
            <div className="my-2 basis-full">
              <b>Special Notes : </b>
              <div
                dangerouslySetInnerHTML={{
                  __html: `${bookingData?.special_notes || ""}`,
                }}
              ></div>
            </div>
          )}
        </div>
      </div>

      <div className="my-4 rounded-lg border-2 bg-white p-4">
        <h2 className="text-xl font-bold">Cost Calculation</h2>
        <div className="mb-4 overflow-x-auto">
          <table className="w-full table-auto text-center	">
            <thead>
              <tr className="border-b bg-gray-200">
                <th className="py-2">Travel Mode</th>
                <th className="py-2">Rider</th>
                <th className="py-2">Occupancy</th>
                <th className="py-2">Price</th>
                <th className="py-2">Discount</th>
                <th className="py-2">Final</th>
                <th className="py-2">GST</th>
                <th className="py-2">With GST</th>
                <th className="py-2">Wraveler</th>
                <th className="py-2">Amount</th>
              </tr>
            </thead>
            <tbody>
              {bookingData?.cost?.map((booking, index) => {
                return (
                  <tr
                    key={booking.id}
                    className={`border-b ${index % 2 === 0 ? "" : "bg-gray-50"
                      }`}
                  >
                    <td className="py-2">
                      {
                        travelModes?.filter(
                          (tm) => tm.id === parseInt(booking?.travel_mode_id)
                        )[0]?.name
                      }
                    </td>
                    <td className="py-2">{getRiderName(booking?.rider_id)}</td>
                    <td className="py-2">
                      {
                        occupancies?.filter(
                          (occ) => occ.id === parseInt(booking?.occupancy_id)
                        )[0]?.name
                      }
                    </td>
                    <td className="py-2">{booking?.package_price}</td>
                    <td className="py-2">{booking?.package_discount}</td>
                    <td className="py-2">
                      {booking?.package_price_after_discount}
                    </td>
                    <td className="py-2">{booking?.package_gst}</td>
                    <td className="py-2">{booking?.package_with_gst}</td>
                    <td className="py-2">{booking?.pax}</td>
                    <td className="py-2">{booking?.amount}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {bookingData?.addons?.length > 0 && (
        <div className="my-4 rounded-lg border-2 bg-white p-4">
          <h2 className="text-xl font-bold">Addons</h2>
          <div className="mb-4">
            <table className="w-full text-center">
              <thead>
                <tr className="border-b bg-gray-200">
                  <th className="py-2">Title</th>
                  <th className="py-2">Description</th>
                  <th className="py-2">Unit Type</th>
                  <th className="py-2">Unit Price</th>
                  <th className="py-2">Unit GST</th>
                  <th className="py-2">Unit</th>
                  <th className="py-2">Amount</th>
                </tr>
              </thead>
              <tbody>
                {bookingData?.addons?.map((addon, index) => {
                  return (
                    <tr
                      key={addon.id}
                      className={`border-b ${index % 2 === 0 ? "" : "bg-gray-50"
                        }`}
                    >
                      <td className="py-2">{addon?.title}</td>
                      <td className="py-2">{addon?.description}</td>
                      <td className="py-2">{addon?.unit_type}</td>
                      <td className="py-2">{addon?.unit_price}</td>
                      <td className="py-2">{addon?.unit_gst}</td>
                      <td className="py-2">{addon?.unit}</td>
                      <td className="py-2">{addon?.amount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <div className="lg:flex-column flex flex-col items-end justify-between gap-4 pb-4">
        <div className="text-right">
          <table className="w-full">
            <tbody>
              <tr>
                <td className="font-semibold">Total Pax :</td>
                <td className="px-4 py-1 font-bold">
                  {formatNumberToTwoDigit(bookingData?.total_pax)}
                </td>
              </tr>
              <tr>
                <td className="font-semibold">Total Price :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {bookingData?.total_cost}
                </td>
              </tr>
              <tr>
                <td className="font-semibold">
                  Total Discount{" "}
                  {bookingData?.is_coupon_applied
                    ? `(Coupon Code - ${bookingData?.coupon_code})`
                    : ""}{" "}
                  {bookingData?.is_membership_used
                    ? `(Membership Applied)`
                    : ""}{" "}
                  :{" "}
                </td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {bookingData?.total_discount}
                </td>
              </tr>
              <tr className="border-t font-semibold text-sky-600">
                <td>Total Cost After Discount :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {bookingData?.total_cost_after_discount}
                </td>
              </tr>
              <tr>
                <td className="font-semibold">Total GST :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {bookingData?.total_gst}
                </td>
              </tr>
              <tr className="border-t font-semibold text-sky-600">
                <td className="font-semibold">Total Amount after GST :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign />{" "}
                  {(
                    parseFloat(bookingData?.total_gst) +
                    parseFloat(bookingData?.total_cost_after_discount)
                  ).toFixed(2)}
                </td>
              </tr>
              {bookingData?.total_tcs > 0 && (
                <tr>
                  <td className="font-semibold">Total TCS :</td>
                  <td className="flex items-center justify-end px-4 py-1 font-bold">
                    <FaRupeeSign /> {bookingData?.total_tcs}
                  </td>
                </tr>
              )}
              {bookingData?.amount_used_in_gift_card > 0 && (
                <tr>
                  <td className="font-semibold">Gift Card Redeemed :</td>
                  <td className="flex items-center justify-end px-4 py-1 font-bold">
                    <FaRupeeSign /> {bookingData?.amount_used_in_gift_card}
                  </td>
                </tr>
              )}
              {bookingData?.amount_used_in_credit_note > 0 && (
                <tr className="text-green-600">
                  <td className="font-semibold">Credit Note Used :</td>
                  <td className="flex items-center justify-end px-4 py-1 font-bold">
                    <FaRupeeSign /> {bookingData?.amount_used_in_credit_note}
                  </td>
                </tr>
              )}
              <tr className="border-t font-semibold text-sky-600">
                <td className="font-semibold">Amount to Pay :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {bookingData?.total_amount_to_pay}
                </td>
              </tr>
              <tr className="text-green-600">
                <td className="font-semibold">Amount Alreday Paid :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {bookingData?.total_amount_paid}
                </td>
              </tr>
              {bookingData?.amount_deducted > 0 && (
                <tr>
                  <td className="font-semibold">Amount Deducted :</td>
                  <td className="flex items-center justify-end px-4 py-1 font-bold">
                    <FaRupeeSign /> {bookingData?.amount_deducted}
                  </td>
                </tr>
              )}
              {bookingData?.credit_note > 0 && (
                <tr>
                  <td className="font-semibold">Refunded as Credit Note :</td>
                  <td className="flex items-center justify-end px-4 py-1 font-bold">
                    <FaRupeeSign /> {bookingData?.credit_note}
                  </td>
                </tr>
              )}
              {bookingData?.refund_amount > 0 && (
                <tr>
                  <td className="font-semibold">Amount Deducted :</td>
                  <td className="flex items-center justify-end px-4 py-1 font-bold">
                    <FaRupeeSign /> {bookingData?.refund_amount}
                  </td>
                </tr>
              )}
              <tr className="border-t font-semibold text-red-600">
                <td className="font-semibold">Remaining Amount :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {bookingData?.remaining_amount}
                </td>
              </tr>
              {balancePaymentData?.is_verified === 0 && (
                <tr className="border-t font-semibold text-green-600">
                  <td className="font-semibold">Paying Amount :</td>
                  <td className="flex items-center justify-end px-4 py-1 font-bold">
                    <FaRupeeSign /> {balancePaymentData?.amount}
                  </td>
                </tr>
              )}
              {balancePaymentData?.is_verified === 0 && (
                <tr className="border-t font-semibold text-red-600">
                  <td className="font-semibold">Net Remaining Amount :</td>
                  <td className="flex items-center justify-end px-4 py-1 font-bold">
                    <FaRupeeSign />{" "}
                    {bookingData?.remaining_amount - balancePaymentData?.amount}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="my-4 rounded-lg border-2 p-4">
        <div className="flex items-center justify-between border-b-2 pb-4">
          <h2 className="text-xl font-bold">Payments</h2>
        </div>
        <div className="my-4">
          <div className="flex justify-between">
            <div className="max-w-[500px] grow basis-1/2">
              <table className="w-full">
                <tbody>
                  <tr className="border-b text-sky-600">
                    <td className="py-2 font-bold">Paying Amount</td>
                    <td className="flex items-center gap-1 py-2">
                      <FaRupeeSign />
                      {balancePaymentData?.amount}
                    </td>
                  </tr>

                  <tr className="border-b text-green-600">
                    <td className="py-2 font-bold">Payment Method</td>
                    <td className="flex items-center gap-1 py-2">
                      {balancePaymentData?.payment_method}
                    </td>
                  </tr>
                  <tr className="text-red-600">
                    <td className="py-2 font-bold">Payment Source</td>
                    <td className="flex items-center gap-1 py-2">
                      {balancePaymentData?.payment_source}
                    </td>
                  </tr>

                  {balancePaymentData?.date_of_payment && <tr className="">
                    <td className="py-2 font-bold">Date of Payment</td>
                    <td className="flex items-center gap-1 py-2">
                      {moment(balancePaymentData?.date_of_payment).format("DD MMMM YYYY")}
                    </td>
                  </tr>}

                  {balancePaymentData?.bank_paid_to && <tr className="">
                    <td className="py-2 font-bold">Bank Paid To</td>
                    <td className="flex items-center gap-1 py-2">
                      {balancePaymentData?.bank_paid_to}
                    </td>
                  </tr>}

                  <tr>
                    <td className="py-2 font-bold">Transaction Id</td>
                    <td className="flex items-center gap-1 py-2">
                      {balancePaymentData?.transaction_id}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 font-bold">Created By</td>
                    <td className="flex items-center gap-1 py-2">
                      {balancePaymentData?.created_by}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 font-bold">Payment Screenshot</td>
                    <td className="flex items-center gap-1 py-2">
                      {balancePaymentData?.screenshot && (
                        <img
                          src={`${getUploadsUrl()}/paymentVerificationScreenshot/${balancePaymentData?.screenshot
                            }`}
                          alt="Balance Payment Screenshot"
                        />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="flex flex-col gap-1">
              {balancePaymentData?.is_verified === 2 && (
                <button
                  disabled
                  className={`rounded-lg border border-red-500 py-2  px-4 text-red-500`}
                >
                  Status : Rejected
                </button>
              )}
              {balancePaymentData?.is_verified === 1 && (
                <button
                  disabled
                  className={`rounded-lg border border-blue-500 py-2  px-4 text-blue-500`}
                >
                  Status : Approved
                </button>
              )}

              {!auth?.permissions?.includes(
                "PaymentVerification.remaingPayment.approve"
              ) && !auth?.roles?.includes("Superadmin") ? (
                <></>
              ) : (
                balancePaymentData?.is_verified === 0 && (
                  <>
                    <button
                      disabled={disableButtons}
                      onClick={approvePayment}
                      className="my-2 rounded-lg bg-blue-500 px-4 py-2 text-white disabled:bg-blue-300"
                    >
                      Verify and Approve payment Screenshot
                    </button>
                    <button
                      disabled={disableButtons}
                      onClick={rejectPayment}
                      className="rounded-lg bg-red-500 px-4 py-2 text-white"
                    >
                      Reject Payment Screenshot verification
                    </button>
                  </>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentVerificationDetails;
