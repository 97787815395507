import React, { useEffect, useRef, useState } from 'react'
import ComponentHeader from '../common/ComponentHeader'
import H2 from '../../../components/headings/H2'
import { getAdminUrl } from '../../../ServiceConfig'
import DataTable from '../common/DataTable'
import moment from 'moment'
import { FaFilter } from 'react-icons/fa'
import InputLabel from '../inputs/InputLabel'
import SelectSearch from '../inputs/SelectSearch'
import DateRangeInput from '../inputs/DateRangeInput'
import { useNavigate } from 'react-router-dom'
import useAdminAuth from '../../hooks/useAdminAuth'
import NotAuthorized403 from '../NotAuthorized403'

const ActivityLogList = () => {
  const activityLogTable = useRef();
  const { auth } = useAdminAuth();
  const navigate = useNavigate()
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const columns = [
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Log Name",
      field: "log_name",
      responsive: 0,
      minWidth: "120",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Event",
      field: "event",
      responsive: 0,
      minWidth: "140",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Subject Type",
      field: "subject_type",
      responsive: 0,
      minWidth: "150",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return cell.getValue().replace("App\\Models\\", "");
      },
    },
    {
      title: "Subject Id",
      field: "subject_id",
      responsive: 0,
      minWidth: "130",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Causer Type",
      field: "causer_type",
      responsive: 0,
      minWidth: "150",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return cell.getValue() ? cell.getValue()?.replace("App\\Models\\", "") : "System";
      },
    },
    {
      title: "Causer Id",
      field: "causer_id",
      responsive: 0,
      minWidth: "150",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return cell.getValue() ? cell.getValue() : "System";
      },
    },
    {
      title: "Causer Name",
      field: "causer_name",
      responsive: 0,
      minWidth: "150",
      resizable: true,
      vertAlign: "middle",
      headerSort: false,
    },
    {
      title: "Updated Date & Time",
      field: "created_at",
      responsive: 0,
      minWidth: "300",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return moment(cell.getValue()).format('Do MMMM YYYY, h:mm:ss A');
      },
    },
  ]

  const updateFilter = (filter) => {
    let index = selectedFilter.findIndex((sf) => sf.field === filter.field);

    if (index >= 0) {
      if (filter.value && !Array.isArray(filter.value)) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else if (Array.isArray(filter.value) && filter.value[0]) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else {
        setSelectedFilter(
          selectedFilter.filter((af) => af.field !== filter.field)
        );
      }
    } else {
      setSelectedFilter([...selectedFilter, filter]);
    }
  };

  useEffect(() => {
    activityLogTable?.current?.setFilter(selectedFilter);
  }, [selectedFilter])

  const onRowClick = (value) => {
    navigate(`/activity-log/${value.id}`)
  }
  return (<>
    {(auth?.permissions?.includes("Lead.assignmentHistory") ||
      auth?.roles?.includes("Superadmin")) ? <div className="flex h-full flex-col">
        <ComponentHeader className="border-b">
          <H2>Activity Log</H2>
        </ComponentHeader>
        <div className="h-full">
          <div
            className=" mr-1 inline-block pt-4 lg:mr-2 "
          >
            <button
              onClick={() => setFilterOpen(!filterOpen)}
              className={`flex items-center gap-1 whitespace-nowrap rounded-md border bg-white p-2 py-2  text-xs font-semibold tracking-wide dark:bg-slate-800 lg:p-2 lg:text-lg ${filterOpen
                ? "-mb-[1px] rounded-b-none border-b-0 px-2 pb-4 pt-2 lg:px-8"
                : ""
                }`}
            >
              <FaFilter />
              Filters
            </button>
          </div>
          <div
            className={`flex grow flex-wrap  items-center gap-4 rounded-md rounded-tl-none bg-white dark:bg-slate-800 ${filterOpen ? "mb-4 border p-4" : "h-0 overflow-hidden p-0"
              }`}
          >
            <div className="min-w-[250px]">
              <InputLabel label="Event" />
              <SelectSearch
                options={[
                  { value: "created", label: "Created" },
                  { value: "updated", label: "Updated" },
                ]}
                value={selectedFilter.find(
                  (filter) => filter.field === "event"
                )?.value}
                onChange={(value) => {
                  updateFilter({
                    field: "event",
                    type: "=",
                    value: value,
                  });
                }}
              />
            </div>

            <div className="min-w-[250px]">
              <InputLabel label="Subject Type" />
              <SelectSearch
                options={[
                  { value: "Payment", label: "Payment" },
                  { value: "Booking", label: "Booking" },
                  { value: "CreditNote", label: "CreditNote" },
                ]}
                value={selectedFilter.find(
                  (filter) => filter.field === "subject_type"
                )?.value ? selectedFilter.find(
                  (filter) => filter.field === "subject_type"
                )?.value.replace("App\\Models\\", "") : null}
                onChange={(value) => {
                  updateFilter({
                    field: "subject_type",
                    type: "=",
                    value: value ? `App\\Models\\${value}` : ""
                  });
                }}
              />
            </div>

            <div className="min-w-[250px]">
              <InputLabel label="Subject Id" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "subject_id",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>


            <div className="min-w-[250px]">
              <InputLabel label="Causer Type" />
              <SelectSearch
                options={[
                  { value: "App\\Models\\User", label: "User" },
                  { value: "App\\Models\\Employee", label: "Employee" },
                  { value: "System", label: "System" },
                ]}
                value={selectedFilter.find(
                  (filter) => filter.field === "causer_type"
                )?.value}
                onChange={(value) => {
                  updateFilter({
                    field: "causer_type",
                    type: "=",
                    value: value,
                  });
                }}
              />
            </div>

            <div className="min-w-[250px]">
              <InputLabel label="Causer Id" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "causer_id",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>


            <div className="min-w-[250px]">
              <InputLabel label="Updated Date & Time" />
              <DateRangeInput
                controlBtns={true}
                horizontalPosition="left"
                onRangeChange={(startDate, endDate) =>
                  updateFilter({
                    field: "created_at",
                    type: "in",
                    value: [
                      startDate
                        ? `${new Date(startDate).getFullYear()}-${new Date(startDate).getMonth() + 1
                        }-${new Date(startDate).getDate()}`
                        : "",
                      endDate
                        ? `${new Date(endDate).getFullYear()}-${new Date(endDate).getMonth() + 1
                        }-${new Date(endDate).getDate()}`
                        : "",
                    ],
                  })
                }
              />
            </div>


          </div>
          <DataTable
            ref={activityLogTable}
            ajaxUrl={`${getAdminUrl()}/all-activity-log`}
            columns={columns}
            // search={true}
            rowClick={onRowClick}
            responsiveLayout="collapse"
          />
        </div>
      </div> : <NotAuthorized403/>}
  </>
  )
}

export default ActivityLogList