import React from "react";
import TextComponent from "../components/settings/TextComponent";
import ImageComponent from "../components/settings/ImageComponent";

const address = {
  heading: "Address",
  key: "address",
  placeholder: "write here address....",
  type: "text",
  description: "Set the Address to show throught.",
  warning: "",
};

const reviews = {
  heading: "Reviews",
  key: "reviews",
  placeholder: "Number of Reviews",
  type: "number",
  description: "Mention the number of Reviews to show on the website",
  warning: "",
};

const wravelers = {
  heading: "Wravelers",
  key: "wravelers",
  placeholder: "Number of Wravelers",
  type: "number",
  description: "Mention the number of Wravelers to show on the website",
  warning: "",
};

const trips = {
  heading: "Trips",
  key: "trips",
  placeholder: "Number of Trips",
  type: "number",
  description: "Mention the number of Trips to show on the website",
  warning: "",
};

const instaFollowers = {
  heading: "Instagram Followers",
  key: "insta_Followers",
  placeholder: "Number of Instagram Followers",
  type: "number",
  description: "Mention the number of Instagram Followers to show on the website",
  warning: "",
};

const homePagePlacesArray = {
  heading: "HomePage Swiping Places",
  key: "homepage_Swiping_Destination",
  placeholder: "Comma separated list of Places",
  type: "text",
  description: "Comma separated list of places to show in Homepage",
  warning: "Do not write spaces between comma and values",
};

const popupDesktop = {
  heading: "Popup Image and Link - Desktop",
  key: "popup-desktop",
  placeholder: "Upload Image",
  type: "image",
  urlType: "text",
  description: "This will be shown in the popup on the desktop only",
  warning: "Upload here image only",
};
const popupMobile = {
  heading: "Popup Image and Link - Mobile",
  key: "popup-mobile",
  placeholder: "Upload Image",
  type: "image",
  urlType: "text",
  description: "This will be shown in the popup on the phone only",
  warning: "Upload here image only",
};
const adbanner = {
  heading: "Ad Banner For Dektop - First",
  key: "ad-banner-desktop-1",
  placeholder: "Upload Image",
  type: "image",
  urlType: "text",
  description: "This is the first banner of the desktop",
  warning: "Upload here image only",
};
const adbanner2 = {
  heading: "Ad Banner For Dektop - Second",
  key: "ad-banner-desktop-2",
  placeholder: "Upload Image",
  type: "image",
  urlType: "text",
  description: "This is the second banner of the desktop",
  warning: "Upload here image only",
};
const adbanner3 = {
  heading: "Ad Banner For Dektop - Third",
  key: "ad-banner-desktop-3",
  placeholder: "Upload Image",
  type: "image",
  urlType: "text",
  description: "This is the third banner of the desktop",
  warning: "Upload here image only",
};


const adbannerMobile = {
  heading: "Home Page Mobile Banner",
  key: "home-banner-mobile",
  placeholder: "Upload Image",
  type: "image",
  urlType: "text",
  description:
    "This will be used on the top of the home page in mobile device",
  warning: "Upload image only",
}
const adbannerMobile2 = {
  heading: "Ad Banner For Mobile - Second",
  key: "ad-banner-mobile-2",
  placeholder: "Upload Image",
  type: "image",
  urlType: "text",
  description: "This is the second banner of the mobile",
  warning: "Upload here image only",
};
const adbannerMobile3 = {
  heading: "Ad Banner For Mobile - Third",
  key: "ad-banner-mobile-3",
  placeholder: "Upload Image",
  type: "image",
  urlType: "text",
  description: "This is the third banner of the mobile",
  warning: "Upload here image only",
};
// const banner2 = {
//   id: 10,
//   urlId: 11,
//   urlKey: "desktop-banner-2-url",
//   key: "ad-banner-desktop-2",
//   placeholder: "image for desktop banner 1",
//   type: "image",
//   urlType: "text",
//   description: "This is the first banner of the desktop",
//   warning: "Upload here image only",
// };

const Settings = () => {
  return (
    <div className="my-4 h-[100%] overflow-auto">
      <div className="flex h-full flex-col items-center justify-start gap-3">
        <TextComponent data={address} />
        <TextComponent data={homePagePlacesArray} />
        <TextComponent data={reviews} />
        <TextComponent data={wravelers} />
        <TextComponent data={trips} />
        <TextComponent data={instaFollowers} />
        <ImageComponent data={popupDesktop} />
        <ImageComponent data={popupMobile} />
        <ImageComponent data={adbanner} />
        <ImageComponent data={adbanner2} />
        <ImageComponent data={adbanner3} />
        <ImageComponent data={adbannerMobile} />
        <ImageComponent data={adbannerMobile2} />
        <ImageComponent data={adbannerMobile3} />
        {/* <ImageComponent data={banner2} /> */}
      </div>
    </div>
  );
};

export default Settings;
