import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from "yup";
import TransferFormikForm from './TransferFormikForm';
import { transferCreditNote } from '../../services/CreditNotesServices';
import { toast } from 'react-toastify';

const validationSchema = Yup.object().shape({
    fName: Yup.string().required("Required"),
    email: Yup.string().email().required("Required"),
    phone: Yup.string().required("Required"),
    code: Yup.string().required("Required"),
});

const TransferTransactionInput = ({ balanceAmount, transactionType, setTransactionType, userId, handleTableReload, setBalanceAmount }) => {
    const [amount, setAmount] = useState()
    const [creditDebitType, setCreditDebitType] = useState('')
    const [checkingUserData, setCheckingUserData] = useState({
        country_code: '+91',
        phone: ''
    })
    const [userData, setUserData] = useState({})

    const updateTransaction = async () => {
        if (!amount || amount < 1) {
            toast.error("Please enter a valid amount!")
            return;
        }
        if (balanceAmount >= amount) {
            const submitData = {
                country_code: checkingUserData?.country_code,
                phone: checkingUserData?.phone,
                user_id: userId,
                amount: amount,
                first_name: userData?.fName,
                last_name: userData?.lName,
                email: userData?.email,
                description: creditDebitType,
            }
            try {
                const res = await transferCreditNote(submitData)
                toast.success(res.data.message)
                setAmount()
                setCreditDebitType()
                setTransactionType()
                handleTableReload()
                setBalanceAmount(res.data.data.balance_amount)
            } catch (error) {
                toast.error(error.response.data.message)
            }
        }
        else {
            toast.error("Amount Can not be more than Balance Amount.")
        }
    }

    return (
        <>
            <div className='flex lg:flex-row flex-col gap-3'>
                <div className='flex gap-4 w-full rounded-md  px-3 py-2 flex-col items-start'>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            fName: "",
                            lName: "",
                            email: "",
                            phone: "",
                            code: "+91",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            console.log(values, "real")
                        }}
                    >
                        {({ values, setFieldValue, errors, touched }) => (
                            <Form>
                                <div className="flex flex-wrap gap-4 lg:flex-nowrap">
                                    <TransferFormikForm setCheckingUserData={setCheckingUserData} checkingUserData={checkingUserData} setUserData={setUserData} />
                                </div>
                                {/* <div className="mt-4 text-center">
                                    <button type="submit" className="btn-primary dark:bg-green-700">
                                        Submit
                                    </button>
                                </div> */}
                            </Form>
                        )}
                    </Formik>
                </div>

                <div className="border-l-2 border-gray-400 h-auto mb-6"></div>
                <div className="flex gap-4 w-full rounded-md  px-3 py-2 flex-col items-start">
                    <div className='flex flex-col  w-full'>
                        <label className='capitalize'>{transactionType} Amount</label>
                        <input value={amount} onChange={(e) => { setAmount(e.target.value) }} type="number" className=" px-3 border border-gray-300 py-2 rounded-md focus:outline-none focus:ring-1 focus:ring-[#0183c0] focus:border-transparent capitalize" placeholder={`Enter ${transactionType} amount`} />
                    </div>
                    <div className='flex flex-col  w-full'>

                        <textarea
                            value={creditDebitType}
                            onChange={(e) => setCreditDebitType(e.target.value)}
                            className="resize-y block w-full px-3 border border-gray-300 py-2 rounded-md focus:outline-none focus:ring-1 focus:ring-[#0183c0] focus:border-transparent"
                            style={{ minHeight: '4rem' }}
                            rows={2}
                            placeholder="Enter description........"
                        />
                    </div>
                </div>
            </div>
            <button onClick={updateTransaction} type="button" className="ml-2 lg:w-1/6 self-center px-3 py-2 rounded-md bg-[#0183c0] text-white">
                Submit
            </button>
        </>
    )
}

export default TransferTransactionInput