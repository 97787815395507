import React from "react";
import { toast } from "react-toastify";
import InputLabel from "../components/inputs/InputLabel";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { addDesignation, updateDesignationById } from "../services/DesignationServices";

const designationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
});

function DesignationForm({ formData, edit }) {
  const naviagte = useNavigate()

  const addNewDesignation = (values) => {
    addDesignation(values)
      .then((res) => {
        toast.success(res.data.message);
        naviagte('/employees/designations')
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const updateDesignation = (values) => {
    console.log(values)
    let data = Object.assign({}, values);
    updateDesignationById(formData.id, data)
      .then((res) => {
        toast.success(res.data.message);
        naviagte('/employees/designations')
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          name: formData?.name ? formData.name : "",
        }}
        validationSchema={designationSchema}
        onSubmit={(values) => {
          edit ? updateDesignation(values) : addNewDesignation(values);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="flex flex-col">

              <div className="my-4 grow">
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-6 gap-y-4">


                  <div className="">
                    <InputLabel label="Name" labelFor="name" />
                    <Field
                      className="block w-full rounded-md border py-1 px-2 dark:bg-slate-600"
                      id="name"
                      name="name"
                    />
                    <ErrorMessage name="name">
                      {(msg) => <div className="error-msg">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 text-center">
              <button type="submit" className="btn-primary dark:bg-green-700">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default DesignationForm;
