import { admin } from "../../ServiceConfig";

export function getAllDesignations() {
  return admin.get(`/designations`);
}

export function addDesignation(data) {
  return admin.post(`/designations`, data);
}

export function deleteDesignationById(id){
  return admin.delete(`/designations/${id}`)
}

export function updateDesignationById(id, data){
  return admin.put(`/designations/${id}`, data)
}

export function getDesignationById(id){
  return admin.get(`/designations/${id}`)
}