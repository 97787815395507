import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  createWebsiteSettings,
  getSettingsByKey,
  updateWebsiteSettings,
} from "../../services/SettingsServices";

const TextComponent = ({ data }) => {
  const [id, setId] = useState(false);
  const [value, setValue] = useState("");
  const [initialValue, setInitialvalue] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = () => {
    setSubmitting(true);
    const submitData = {
      key: data.key,
      type: data.type,
      value,
    };
    console.log(submitData);
    if (id) {
      updateWebsiteSettings(id, submitData)
        .then((res) => {
          toast.success("updated settings");
          getInitialValues();
          setSubmitting(false);
        })
        .catch((error) => {
          console.log(error);
          toast.error("error in updating settings");
          setSubmitting(false);
        });
    } else {
      createWebsiteSettings(submitData)
        .then((res) => {
          toast.success("updated settings");
          getInitialValues();
          setSubmitting(false);
        })
        .catch((error) => {
          console.log(error);
          toast.error("error in updating settings");
          setSubmitting(false);
        });
    }
  };

  const getInitialValues = () => {
    getSettingsByKey(data.key, data.type)
      .then((res) => {
        setValue(res.data.data.value);
        setId(res.data.data.id);
        setInitialvalue(res.data.data.value);
      })
      .catch((er) => {
        console.log(er, "error");
      });
  };

  useEffect(() => {
    getInitialValues();
  }, []);

  return (
    <div className="m-3 w-full rounded-md border border-gray-300 bg-white dark:bg-slate-900  dark:text-white lg:max-w-[800px]">
      <div className="flex  w-full gap-3 px-5 py-8 ">
        <div className="w-full">
          <h2 className="mb-2 text-2xl font-bold capitalize">{data.heading}</h2>
          <div>{data.description}</div>
          <input
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            type={data.type}
            className="field mt-5 w-full rounded-md border border-gray-300 p-2"
            placeholder={data.placeholder}
          />
        </div>
      </div>
      <hr className="border-t-2 border-gray-300" />
      <div className="flex justify-between p-5">
        <div>{data.warning}</div>
        <button
          onClick={handleSubmit}
          disabled={value === initialValue}
          className={`rounded py-2 px-4 font-bold text-white shadow-2xl  focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-opacity-50 ${
            value === initialValue || submitting
              ? "cursor-not-allowed bg-gray-400 hover:bg-gray-400"
              : "bg-green-700 hover:bg-green-600"
          }`}
        >
          {submitting ? `Saving ...` : `Save`}
        </button>
      </div>
    </div>
  );
};

export default TextComponent;
