import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import ComponentHeader from "../../components/common/ComponentHeader";
import H2 from "../../../components/headings/H2";
import { FaListAlt } from "react-icons/fa";
import { getImportantDocumentById } from '../../services/ImportantDocumentService';
import ImportantDocumentForm from './ImportantDocumentForm';
import NotAuthorized403 from '../NotAuthorized403';
import useAdminAuth from '../../hooks/useAdminAuth';

const EditImportantDocument = () => {

    const docId = useParams().id;
    const [docData, setDocData] = useState()
    const { auth } = useAdminAuth();
    useEffect(() => {
        getImportantDocumentById(docId).then(res => {
            setDocData(res.data.data)
        }).catch(er => {
            console.log(er)
        })
    }, [])
    return (
        <>
            {(auth?.permissions?.includes("documents.update") ||
                auth?.roles?.includes("Superadmin")) ? <div className="dark:bg-slate-900 dark:text-white h-[100%]">
                <ComponentHeader className="mb-4 border-b">
                    <H2>Update Important Document</H2>
                    <Link
                        to="/important-documnets"
                        className="btn-primary flex items-center gap-1 whitespace-nowrap py-1 px-4 dark:bg-green-700 lg:px-12 lg:py-2"
                    >
                        <FaListAlt /> View All
                    </Link>
                </ComponentHeader>
                {docData && (
                    <>
                        <ImportantDocumentForm
                            formData={docData}
                            edit={true}
                        />
                    </>
                )}
            </div> : <NotAuthorized403 />}
        </>
    )
}

export default EditImportantDocument