import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getActivityLogsById } from '../../services/ActivityLogServices'
import moment from 'moment/moment'

const ActivityLogDetail = () => {
  const id = useParams().id
  const [data, setData] = useState()

  useEffect(() => {
    getActivityLogsById(id).then((res) => {
      setData(res.data.data)
    }).catch(er => {
      console.log(er)
    })
  }, [])



  return (
    <>
      {
        data && <div className="lg:max-w-4xl max-w-full mx-auto p-6 bg-white rounded-lg shadow-md space-y-4">
          <h2 className="text-xl font-bold text-white bg-[#0183c0] shadow-xl py-4 px-6 rounded-md">
            Event: {data.event}
          </h2>
          <p className="text-gray-600">Description: {data.description}</p>
          <div className="text-gray-600">
            <p>Subject Type: {data?.subject_type?.replace("App\\Models\\", "")}</p>
            <p>Subject ID: {data.subject_id}</p>
            <p>Causer Type: {data?.causer_type ? data?.causer_type?.replace("App\\Models\\", "") : "System"}</p>
            <p>Causer ID: {data?.causer_id ? data?.causer_id : "System" }</p>
            <p>Causer Name: {data.causer_name}</p>
            <p>Created At: {moment(data.created_at).format('Do MMMM YYYY, h:mm:ss A')}</p>
            <p>Updated At: {moment(data.updated_at).format('Do MMMM YYYY, h:mm:ss A')}</p>
          </div>

          <div className="border-t pt-4 overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr className="bg-[#0183c0] text-white">
                  <th className="px-6 py-3 text-left text-lg font-bold tracking-wider">Field</th>
                  <th className="px-6 py-3 text-left text-lg font-bold tracking-wider">Old</th>
                  <th className="px-6 py-3 text-left text-lg font-bold tracking-wider">New</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(data.differences).map(([key, value], index) => (
                  <tr key={key} className={index % 2 === 0 ? 'bg-white' : 'bg-[#d7f2fd]'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{key}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {value.old !== null ? value.old : 'N/A'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {value.new !== null ? value.new : 'N/A'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      }
    </>
  )
}

export default ActivityLogDetail