import React, { useEffect, useState } from "react";
import axios from "axios";
import { Parser as DomParser } from "htmlparser2";
import { DomHandler } from "domhandler";
import { getUploadsUrl } from "../../../../ServiceConfig";

const fetchAndCheckAltTags = async (url) => {
  try {
    // Fetch the HTML content from the URL
    const response = await axios.get(url); // Await the axios request
    const htmlContent = response.data;
    console.log("Fetched HTML content");

    const results = [];
    // Create a handler for processing the parsed HTML
    const handler = new DomHandler((error, dom) => {
      if (error) {
        console.error("Error parsing HTML from dom handler", error);
        return;
      }

      // Recursive function to check <img> tags
      const checkNode = (node) => {
        if (node.type === "tag" && node.name === "img") {
          // if (!node.attribs || !node.attribs.alt) {
          //   // results.push("Missing alt attribute in <img> tag ");
          //   console.log(node);
          // }
          results.push({
            node: node,
            // message: "Missing alt attribute in <img> tag ",
          });
        }

        if (node.children) {
          node.children.forEach(checkNode);
        }
      };

      dom.forEach(checkNode);
      // console.log(results);
    });

    // Parse the HTML content
    const parser = new DomParser(handler);
    parser.write(htmlContent);
    parser.end();
    return results;
  } catch (err) {
    console.error("Error fetching URL", err);
    throw err; // Re-throw the error to handle it in the calling function
  }
};

const AltTagChecker = () => {
  const [url, setUrl] = useState("");
  const [results1, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  };

  const checkAltTags = async () => {
    setLoading(true);
    setResults([]);
    setError("");

    try {
      const fetchedResults = await fetchAndCheckAltTags(url);
      setResults(fetchedResults);
    } catch (err) {
      setError("Error fetching or parsing HTML.");
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   // console.log(results);
  //   // Optional: You can put any effect here if needed
  // }, [url]); // Dependency array if you want to trigger side effects when `url` changes

  return (
    <div>
      <div className=" mx-auto w-full border-2 p-10 text-center">
        <h1 className="py-3 text-3xl font-bold">Alt Tag Checker</h1>
        <input
          type="text"
          placeholder="Enter URL"
          value={url}
          onChange={handleUrlChange}
          className="w-full px-6 py-2"
        />
        <button
          onClick={checkAltTags}
          disabled={loading}
          className="btn-primary m-2"
        >
          {loading ? "Checking..." : "Check Alt Tags"}
        </button>
        {error && <p style={{ color: "red" }}>{error}</p>}
      </div>
      <div className="px-2 py-6">
        {/* {console.log(results1.length, "i am results1")} */}
        {results1.length > 0 ? (
          <table border="1" className=" border bg-white">
            <tr className="bg-slate-50">
              <th className="border p-4 text-2xl" colSpan={4}>
                Results
              </th>
            </tr>
            <tr className="bg-slate-50">
              <th className="mx-auto border p-2">Image</th>
              <th className="border p-2">File Name</th>
              <th className="border p-2">Alt Tag</th>
              <th className="border p-2">Is Alt Tag missing?</th>
            </tr>
            {results1.map((result, index) => (
              <tr key={index}>
                <td className="mx-auto w-52 border p-2 text-center">
                  <img
                    src={
                      result.node.attribs.src.startsWith("/_next")
                        ? "https://www.justwravel.com" + result.node.attribs.src
                        : result.node.attribs.src
                    }
                    alt=""
                    className="h-11 w-auto"
                  />
                </td>

                <td className="border p-2">
                  {result.node.attribs.src.startsWith("/_next")
                    ? "https://www.justwravel.com" + result.node.attribs.src
                    : result.node.attribs.src}
                </td>

                <td className="border p-2">{result.node.attribs.alt}</td>

                <td
                  className={`border p-2 ${
                    result.node.attribs.alt ? "No" : "bg-red-800 text-white"
                  }`}
                >
                  {result.node.attribs.alt ? "No" : "yes"}
                </td>
              </tr>
            ))}
          </table>
        ) : (
          !loading && <p>No issues found.</p>
        )}
      </div>
    </div>
  );
};

export default AltTagChecker;
