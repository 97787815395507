import { Link, useNavigate } from "react-router-dom";
import H2 from "../../../components/headings/H2";
import ComponentHeader from "../common/ComponentHeader";
import { FaPlusSquare } from "react-icons/fa";
import DataTable from "../common/DataTable";
import { useRef, useState } from "react";
import { getAdminUrl, getUploadsUrl } from "../../../ServiceConfig";
import { useEffect } from "react";
import $ from "jquery";
import { deleteGiftCard } from "../../services/GiftCardServices";
import { toast } from "react-toastify";

function CreditNotesList() {
      const creditNoteTable = useRef();

      const columns = [
        {
          formatter: "responsiveCollapse",
          width: 30,
          minWidth: 30,
          responsive: 0,
          hozAlign: "center",
          resizable: false,
          headerSort: false,
        },
        {
          title: "Id",
          field: "id",
          responsive: 0,
          width: "50",
          resizable: true,
          vertAlign: "middle",
        },
        {
            title: "User ID",
            field: "user_id",
            minWidth: "100",
          },
        {
          title: "Name",
          field: "user_name",
          minWidth: "100",
          headerSort: false,
        },
        {
            title: "Email",
            field: "user_email",
            minWidth: "100",
            headerSort: false,
          },
          {
            title: "Phone",
            field: "user_phone",
            minWidth: "100",
            headerSort: false,
          },
          {
            title: "Current Balance",
            field: "current_balance",
            minWidth: "100",
            headerSort: false,
          },
      ];

      const onRowClick = (data) => {
        window.open(`/credit-notes/${data.user_id}`, "_blank");
      };

    return (
        <div>
            <ComponentHeader className="border-b">
        <H2>Credit Notes</H2>
      </ComponentHeader>
      <div className="">
        <DataTable
          ref={creditNoteTable}
          ajaxUrl={`${getAdminUrl()}/credit-note`}
          columns={columns}
          layout="fitColumns"
          search={true}
          responsiveLayout="collapse"
          rowClick={onRowClick}
        />
      </div>
        </div>
    );
}

export default CreditNotesList;
