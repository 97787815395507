import React, { useEffect, useState } from 'react'
import { getAllLeadCategory, getAllLeadSource, getAllLeadStatus } from '../../services/LeadService'
import InputLabel from '../inputs/InputLabel'
import SelectSearch from '../inputs/SelectSearch'
import MultiSelect from '../inputs/MultiSelect'
import { getAllEmployeesOption } from '../../services/EmployeeServices'
import DateRangeInput from '../inputs/DateRangeInput'
import moment from 'moment'
import { getAllPackages } from '../../services/PackageService'

const LeadFiltersDataExt = ({ updateFilter, valuesinSendingEmail, setValuesInSendingEmail, arrayOfOptionsToSendingInEmail }) => {
  const [allLeadSource, setAllLeadSource] = useState([])
  const [selectedLeadSource, setSelectedLeadSourec] = useState()

  const [allLeadCategory, setAllLeadCategory] = useState([])
  const [selectedLeadCategory, setSelectedLeadCategory] = useState()

  const [allEmployees, setAllEmployees] = useState([])
  const [selectedEmployee, setSelectedEmployee] = useState()

  const [allLeadStatus, setAllLeadStatus] = useState([])
  const [selectedLeadStatus, setSelectedLeadStatus] = useState()

  const [selectedPackage, setSelectedPackage] = useState()
  const [packagesOption, setpackagesOption] = useState([])

  const [packageName, setPackageName] = useState()
  const [locationName, setLocationName] = useState()
  const [pageURL, setPageURL] = useState()

  const allLeadSourceGetter = async () => {
    const res = await getAllLeadSource()
    setAllLeadSource(res.data.data.map((onesrc) => ({
      value: onesrc.id,
      label: onesrc.source
    })))
  }

  const allLeadCategoryGetter = async () => {
    const res = await getAllLeadCategory()
    setAllLeadCategory(res.data.data.data.map((onesrc) => ({
      value: onesrc.id,
      label: onesrc.category
    })))
  }

  const allEmployeesGetter = async () => {
    const res = await getAllEmployeesOption()
    setAllEmployees(res.data.data.data.map((oneemp) => ({
      value: oneemp.id,
      label: oneemp.full_name
    })))
  }

  const allLeadStatusGetter = async () => {
    const res = await getAllLeadStatus()
    setAllLeadStatus(res.data.data.map((onest) => ({
      value: onest.id,
      label: onest.status
    })))
  }

  const getAllPackagesOption = async () => {
    const res = await getAllPackages()
    setpackagesOption(res.data.data.map((onePackage) => ({
      label: onePackage.title,
      value: onePackage.id
    })))
  }

  useEffect(() => {
    allLeadSourceGetter()
    allLeadCategoryGetter()
    allEmployeesGetter()
    allLeadStatusGetter()
    getAllPackagesOption()
  }, [])
  return (
    <>

      {/* Lead Date Range */}
      <div className="w-full">
        <InputLabel label="Select Lead Date Range" />
        <DateRangeInput
          controlBtns={true}
          horizontalPosition="left"
          onRangeChange={(startDate, endDate) => {
            if (startDate && endDate) {
              const formattedStartDate = moment(startDate, "DD MMM, YYYY").format("YYYY-MM-DD");
              const formattedEndDate = moment(endDate, "DD MMM, YYYY").format("YYYY-MM-DD");
              updateFilter({
                field: "booking_date",
                type: "=",
                value: [
                  formattedStartDate, formattedEndDate
                ],
              })

            }
          }}
        />
      </div>


      {/* Lead Source */}
      <div className="w-full">
        <InputLabel label="Select Lead Source" />
        <SelectSearch
          value={selectedLeadSource}
          options={allLeadSource}
          onChange={(value) => {
            updateFilter({
              field: "lead_source_id",
              type: "=",
              value: value,
            })
            setSelectedLeadSourec(value)
          }}
        />
      </div>

      {/* Lead Category */}
      <div className="w-full">
        <InputLabel label="Select Lead Category" />
        <SelectSearch
          value={selectedLeadCategory}
          options={allLeadCategory}
          onChange={(value) => {
            updateFilter({
              field: "lead_category_id",
              type: "=",
              value: value,
            })
            setSelectedLeadCategory(value)
          }}
        />
      </div>

      {/* Assigned To */}
      <div className="w-full">
        <InputLabel label="Lead Owner" />
        <SelectSearch
          value={selectedEmployee}
          options={allEmployees}
          onChange={(value) => {
            updateFilter({
              field: "assigned_to",
              type: "=",
              value: value,
            })
            setSelectedEmployee(value)
          }}
        />
      </div>


      {/* Lead Status */}
      <div className="w-full">
        <InputLabel label="Lead Status" />
        <SelectSearch
          value={selectedLeadStatus}
          options={allLeadStatus}
          onChange={(value) => {
            updateFilter({
              field: "lead_status_id",
              type: "=",
              value: value,
            })
            setSelectedLeadStatus(value)
          }}
        />
      </div>

      {/* Package id */}
      <div className="w-full">
        <InputLabel label="Select Package" />
        <SelectSearch
          value={selectedPackage}
          options={packagesOption}
          onChange={(value) => {
            updateFilter({
              field: "package_id",
              type: "=",
              value: value,
            })
            setSelectedPackage(value)
          }}
        />
      </div>

      {/* Package Input Box */}
      <div className="w-full">
        <InputLabel label="Package Name" />
        <input
          className="w-full text-lg border-[1px] border-gray-300 rounded-md shadow-sm py-1 px-2 "
          value={packageName}
          onChange={(e) => {
            updateFilter({
              field: "package_name",
              type: "=",
              value: e.target.value,
            })
            setPackageName(e.target.value)
          }}
        />
      </div>

      {/* Location Input Box */}
      <div className="w-full">
        <InputLabel label="Location Name" />
        <input
          className="w-full text-lg border-[1px] border-gray-300 rounded-md shadow-sm py-1 px-2 "
          value={locationName}
          onChange={(e) => {
            updateFilter({
              field: "location",
              type: "=",
              value: e.target.value,
            })
            setLocationName(e.target.value)
          }}
        />
      </div>

      {/* Page URL Input Box */}
      <div className="w-full">
        <InputLabel label="Page URL" />
        <input
          className="w-full text-lg border-[1px] border-gray-300 rounded-md shadow-sm py-1 px-2 "
          value={pageURL}
          onChange={(e) => {
            updateFilter({
              field: "page_url",
              type: "=",
              value: e.target.value,
            })
            setPageURL(e.target.value)
          }}
        />
      </div>

      {/* Select Options For Sending Email */}
      <div className="w-full lg:col-span-2">
        <InputLabel label="Select Values to Send in Email" />
        <MultiSelect
          onChange={(value) => setValuesInSendingEmail(value)}
          value={valuesinSendingEmail}
          options={arrayOfOptionsToSendingInEmail}
        />
      </div>
    </>
  )
}

export default LeadFiltersDataExt