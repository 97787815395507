import React, { useEffect, useState } from 'react';
import { addBatchPrices, deleteBatchPrices, getBatchPrices, updateBatchPrices } from '../../services/EventDatesServices';
import { MdDelete } from "react-icons/md";
import { FaEdit, FaEye, FaSave } from "react-icons/fa";
import { areArraysEqual } from '../../../helpers/Helper';
import { toast } from 'react-toastify';

const BatchPriceEdit = ({ selectedItinerary, setIsPriceEditorOpen, batchId, onUpdate, priceExist }) => {
    const [tableData, setTableData] = useState([]);
    const [edit, setEdit] = useState(!priceExist)
    const [pricesArray, setPricesArray] = useState([])
    const [oldPricesArray, setOldPricesArray] = useState([])

    const getInitialData = async (itiId, btchId) => {
        try {
            const res = await getBatchPrices(itiId, btchId);
            setTableData(res.data.data);
            if (res.data.data[0] && res.data.data[0].batch_wise_price) {
                res.data.data.map((onePrice) => {
                    setPricesArray(prevPrices => [...prevPrices, onePrice.batch_wise_price]);
                    setOldPricesArray(prevPrices => [...prevPrices, onePrice.batch_wise_price]);
                })
            }
        } catch (error) {
            console.log(error)
        }

    };

    useEffect(() => {
        if (selectedItinerary && batchId) {
            getInitialData(selectedItinerary, batchId);
        }
    }, [selectedItinerary]);

    const handleAddPrices = async () => {

        // if no prices are added
        if (pricesArray.length === 0 || pricesArray.length < tableData.length) {
            toast.error("Please add prices first")
            return;
        }

        // if there is no change in prices
        if (areArraysEqual(oldPricesArray, pricesArray)) {
            toast.error("please change prices to save or update")
            return;
        }

        // if adding new prices
        if (oldPricesArray.length === 0) {
            const submitData = {
                prices: []
            }
            tableData.map((onePrice, index) => {
                submitData.prices.push({
                    batch_id: batchId,
                    package_price_id: onePrice.id,
                    price: pricesArray[index]
                })
            })
            try {
                const res = await addBatchPrices(submitData)
                toast.success("Added new batch prices succesfully")
                onUpdate()
            } catch (error) {
                console.log(error)
                toast.error("error in adding prices")
            }
            return
        }

        // if updating prices
        if (oldPricesArray.length > 0) {
            const submitData = {
                prices: []
            }
            tableData.map((onePrice, index) => {
                submitData.prices.push({
                    batch_id: batchId,
                    package_price_id: onePrice.id,
                    price: pricesArray[index],
                    id: onePrice.batch_wise_price_id
                })
            })
            try {
                const res = await updateBatchPrices(submitData)
                toast.success("Updated new batch prices succesfully")
                onUpdate()
            } catch (error) {
                console.log(error)
                toast.error("error in adding prices")
            }
            return
        }
    }

    const handleDeletePrices = () => {
        if (!window.confirm("Do You really want to delete these Batch Prices?")) return;
        deleteBatchPrices(batchId)
            .then((res) => {
                toast.success("batch Price deleted Successfully");
                onUpdate()
            })
            .catch((err) => {
                toast.error("error in deleting batch price");
            });
    }

    return (
        <div> <h4 className=" text-lg font-semibold">{edit ? "Update Batch Price" : "View Batch Price"}</h4>
            <div className="container mx-auto my-2 overflow-x-auto">
                <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                    <thead className="bg-[#0183c0] text-white">
                        <tr>
                            <th className="py-3 px-4 text-left text-sm font-semibold min-w-[150px]">Travel Mode</th>
                            <th className="py-3 px-4 text-left text-sm font-semibold">Rider</th>
                            <th className="py-3 px-4 text-left text-sm font-semibold">Occupancy</th>
                            <th className="py-3 px-4 text-left text-sm font-semibold">Price</th>
                            <th className="py-3 px-4 text-left text-sm font-semibold min-w-[200px]">Batch Price</th>
                        </tr>
                    </thead>
                    <tbody className="bg-[#d7f2fd]">
                        {tableData.map((item, index) => (
                            <tr key={index} className="hover:bg-blue-200 transition duration-200">
                                <td className="py-3 px-4 border-b border-blue-300 min-w-[150px]">{item.travel_mode_name}</td>
                                <td className="py-3 px-4 border-b border-blue-300">{item.rider_name ? item.rider_name : ''}</td>
                                <td className="py-3 px-4 border-b border-blue-300">{item.occupancy_name}</td>
                                <td className="py-3 px-4 border-b border-blue-300">{item.price}</td>
                                <td className="py-3 px-4 border-b border-blue-300 min-w-[200px]">
                                    {edit ? <input
                                        value={pricesArray[index]}
                                        onChange={(e) => {
                                            const newPrices = [...pricesArray];
                                            newPrices[index] = e.target.value;
                                            setPricesArray(newPrices);
                                        }}
                                        type="number"
                                        placeholder="Batch Price ..."
                                        className="w-full px-4 py-2 border border-blue-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                                    /> : item?.batch_wise_price}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="justify-end gap-2 flex ">
                <button
                    onClick={() => {
                        setIsPriceEditorOpen(false)
                    }}
                    className="border-[#0183c0] border px-6 rounded-xl hover:bg-[#0183c0] hover:text-white text-[#0183c0] font-bold inline-flex items-center"
                >
                    Close
                </button>
                {oldPricesArray.length !== 0 && <button
                    onClick={handleDeletePrices}
                    className="border-red-600 border px-4 rounded-md inline-flex items-center font-bold text-red-600 hover:bg-red-500 hover:text-white"
                >
                    <MdDelete size={20} className='mr-2' /> Delete
                </button>}
                {priceExist && <button
                    onClick={() => {
                        setEdit(!edit)
                    }}
                    className="btn-primary inline-flex items-center"
                >
                    {edit ? <FaEye className='mr-2' /> : <FaEdit className='mr-2' />}  {edit ? "View" : "Edit"}
                </button>}
                {edit && <button
                    onClick={handleAddPrices}
                    className="btn-primary inline-flex items-center"
                >
                    <FaSave className='mr-2' />  Save
                </button>
                }

            </div>
        </div>
    );
};

export default BatchPriceEdit;
